import "./AdminNavBare.css";
import logo from "../../../assets/Images/Logo.png";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink, Link } from "react-router-dom";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PersonIcon from "@mui/icons-material/Person";
import TimerIcon from "@mui/icons-material/Timer";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RoomIcon from "@mui/icons-material/Room";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";

const slider = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <SpaceDashboardIcon />,
  },
  {
    path: "/empmanagement",
    name: "Employee ",
    icon: <PersonIcon />,
  },
  {
    path: "/live-attendance",
    name: "Attendance ",
    icon: <TimerIcon />,
    isDropdown: true,
    chi: [
      { path: "live-attendance", name: "Live Attendance" },
      { path: "daily-attendance", name: "Daily Attendance" },
    ],
  },
  { path: "/payroll", name: "Payroll", icon: <ExitToAppIcon /> },
  { path: "/tracking", name: "Tracking", icon: <RoomIcon /> },
  {
    path: "/performance-management",
    name: "Performance Management",
    icon: <MonitorHeartIcon />,
  },

  {
    path: "/raining-list",
    name: "Training & Development",
    icon: <CastForEducationIcon />,
  },
  {
    path: "/reports",
    name: "Reports",
    icon: <BarChartIcon />,
  },
  {
    path: "/setting",
    name: "Setting",
    icon: <SettingsIcon />,
  },
];

const AdminNavBare = () => {
  const navigateDashboard = () => {
    document.getElementById("dash").click();
  };
  return (
    <>
      <Link to="/dashboard" id="dash" style={{ display: "none" }}></Link>
      <nav className="AdminNavBare" style={{ zIndex: 2 }}>
        <div className="AdminNavBare-left">
          <img src={logo} alt="logo" style={{cursor: "pointer"}} onClick={navigateDashboard} />
        </div>

        <div className="AdminNavBare-Right">
          <ul>
            {slider.map((item, i) => (
              <NavLink
                to={item.path}
                className="nav-bar"
                style={{ textDecoration: "none" }}
                key={i}
              >
                {({ isActive }) => (
                  <>
                    <label
                      className="nav_link"
                      key={i}
                      style={{
                        backgroundColor: isActive ? "#fff" : "",
                        color: isActive ? "black" : "",
                      }}
                    >
                      {item.icon}
                      {item.name}
                    </label>

                    {item.isDropdown && (
                      <ul className="SubMenu" id={"dp" + i}>
                        {item.chi.map((r, id) => {
                          return (
                            <li key={id}>
                              <NavLink to={r.path}> {r.name}</NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </>
                )}
              </NavLink>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default AdminNavBare;
