import { useCallback, useEffect, useState } from "react";
import ButtonCom from "../../components/button/button";
import { Form, Col, Row } from "react-bootstrap";
// icons
import { IoIosAdd } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { FaIndianRupeeSign } from "react-icons/fa6";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// -------------------------------------------------------
import Select from "react-select";
import makeAnimated from "react-select/animated";
import FormControl from "@mui/material/FormControl";

import { useDispatch, useSelector } from "react-redux";
import {
  commonGetData,
  commonGetDataOne,
  commonGetDataTwo,
  initState,
} from "../../redux/slices/commonSlice";
import { Link } from "react-router-dom";

const animatedComponents = makeAnimated();
const SalaryTemplate = () => {
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  const [validated, setValidated] = useState(false);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const [allowance, setAllowance] = useState([]);
  const [deduction, setDeduction] = useState([]);

  const getAllowance = useCallback(() => {
    dispatch(commonGetDataOne("admin/getAllowanceList"));
    dispatch(commonGetDataTwo("admin/getDeductionList"));
  }, []);

  useEffect(() => {
    getAllowance();
  }, []);

  useEffect(() => {
    if (commonState.commonGetOneSuccess) {
      const newArr = commonState.commonGetOneData?.map((row) => {
        return { value: row.allowanceId, label: row.allowance_name };
      });
      setAllowance(newArr);
    }
  }, [commonState.commonGetOneSuccess]);

  useEffect(() => {
    if (commonState.commonGetTwoSuccess) {
      const newArr = commonState.commonGetTwoData?.map((row) => {
        return { value: row.deductionId, label: row.deduction_name };
      });
      setDeduction(newArr);
    }
  }, [commonState.commonGetTwoSuccess]);

  const [pfVariableList, setPfVariableList] = useState({});

  const getPfVariable = useCallback(() => {
    dispatch(
      commonGetData(`admin/getEmpPfVariableList?userId=${user?.user_id}`)
    );
  }, []);

  useEffect(() => {
    getPfVariable();
  }, []);
  useEffect(() => {
    if (commonState.commonGetSuccess) {
      setPfVariableList(commonState.commonGetData[0]);
    }
  }, [commonState.commonGetSuccess]);

  const [saveData, setSAveData] = useState({
    designationName: "",
    contributes: [],
  });

  const [earnings, setEarnings] = useState([
    { id:"basic", head: "Basic", calculation: "basic" },
  ]);
  const addEarningsField = (e) => {
    const { value, label } = e;
    setEarnings([
      ...earnings,
      {
        id: value,
        head: label,
        calculation: "",
      },
    ]);

    setTimeout(() => {
      console.log(earnings);
    }, 800);
  };

  const removeEarningsFields = (e, index) => {
    e.preventDefault();
    const rows = [...earnings];
    rows.splice(index, 1);
    setEarnings(rows);
  };

  const handleEarningsChanges = (index, e) => {
    const { name, value } = e.target;
    const list = [...earnings];
    list[index][name] = value;
    setEarnings(list);
  };

  //-------------------------------------------------------------
  const [saveCompliances, setSaveCompliances] = useState({
    designationName: "",
    contributes: [],
  });

  const handleChange = (e) => {
    console.log((e.target.value))
    setSaveCompliances({
      ...saveCompliances,
      compliances: e,
    });
  };
  const [saveDeductionData, setSaveDeductionData] = useState({
    designationName: "",
    deductions: [],
  });

  const [deductions, setDeductions] = useState([]);

  const addDeductionsField = (e) => {
    const { value, label } = e;
    setDeductions([
      ...deductions,
      {
        id: value,
        head: label,
        calculation: "",
      },
    ]);
  };
  const removeDeductionsFields = (e, index) => {
    e.preventDefault();
    const rows = [...deductions];
    rows.splice(index, 1);
    setDeductions(rows);
  };
  const handleDeductionsChanges = (index, e) => {
    const { name, value } = e.target;
    const list = [...deductions];
    list[index][name] = value;
    setDeductions(list);
  };

  const preSubmit = () => {
    document.getElementById("submitForm").click();
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let d = {
        path: "",
        data: {
          earnings,
          saveCompliances,
          saveDeductionData,
          deductions,
        },
      };
      console.log(d)
    }
  };

  return (
    <div className="body-container">
      <div className="page-top-bar">
        
        <div style={{display: "flex", alignItem: "center", justifyContent: "start", gap: "10px"}}>
          <Link to={"/setting/"}>
            <ArrowBackIcon sx={{ scale: 1 }} style={{color: "gray"}} />
          </Link>
          <h1 style={{fontSize: "20px", fontWeight: "400"}}>Salary Template</h1>
        </div>
        <ButtonCom
          type={"button"}
          class_name={"p_btn"}
          text="Save Details"
          url_or_onclick={preSubmit}
          icon={null}
          width={""}
        />
      </div>
      <div className="page-body p-5" style={{ marginBottom: "50px" }}>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={{ width: "60%", margin: "0 auto" }}
        >
          <div
            style={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // gap: "80px",
              paddingBottom: "10px",
            }}
          >
            <Row>
              <Col sm="6">
                <Form.Label style={{ whiteSpace: "nowrap" }}>
                  Template Name
                </Form.Label>
              </Col>
              <Col sm="6">
                <Form.Control
                  name=""
                  value=""
                  onChange={() => {}}
                  placeholder="Template name"
                />
              </Col>
            </Row>
          </div>

          {earnings.map((data, index) => {
            const { head, calculation } = data;
            return (
              <Row className="mt-2" key={index}>
                <Col className="color-gray" sm="6">
                  {head}
                </Col>
                <Col
                  sm="6"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Form.Group style={{ width: "100%" }}>
                    <Form.Select
                      name="calculation"
                      value={calculation}
                      onChange={(e) => handleEarningsChanges(index, e)}
                      style={{ color: "gray" }}
                    >
                      <option value=""> Select a value</option>
                      <option
                        value="basic"
                        selected={calculation == "basic" ? "selected" : null}
                      >
                        Basic Calculation
                      </option>
                      <option
                        value="hra"
                        selected={calculation == "hra" ? "selected" : null}
                      >
                        HRA Calculation
                      </option>
                      <option
                        value="dearness"
                        selected={calculation == "dearness" ? "selected" : null}
                      >
                        Dearness Calculation
                      </option>
                    </Form.Select>
                  </Form.Group>

                  {index !== 0 ? (
                    <RiDeleteBin5Line
                      className="remove-icon"
                      onClick={(e) => removeEarningsFields(e, index)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            );
          })}

          <Row className="mt-2">
            <Col className="remove title-flex" sm="4">
              <FormControl style={{ width: "100%" }} required>
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={saveData.contributes}
                  options={allowance}
                  onChange={addEarningsField}
                  placeholder={
                    <div
                      className="add-text"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <IoIosAdd className="add-icon" /> Add Allowance
                    </div>
                  }
                  style={{ border: "none" }}
                />
              </FormControl>
            </Col>
          </Row>
          {/* ========================================= */}
          <Row className="mt-4">
            <Col sm="6" className="text-bold">
              Employer Contributions
            </Col>
            <Col sm="6" className="text-bold">
              Select Components
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="color-gray" sm="6">
              Employer PF
            </Col>
            <Col sm="6">
              <Form.Group>
                <Form.Select
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select PF</option>
                  <option value={pfVariableList.pf_limit}>
                    <span>{pfVariableList.pf_limit} &#8377;</span>
                  </option>
                  <option value={pfVariableList.pf_variable}>
                    {pfVariableList.pf_variable} %
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="color-gray" sm="6">
              Employer ESI
            </Col>
            <Col sm="6">
              <Form.Group>
                <Form.Select
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select ESI</option>
                  <option value={pfVariableList.esi_variable}>
                    {pfVariableList.esi_variable} %
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="color-gray" sm="6">
              PF EDLI & Admin Charges
            </Col>
            <Col sm="6">
              <Form.Group className="color-gray">
                <Form.Check disabled />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col sm="12" className="text-bold">
              Deduction
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="color-gray" sm="6">
              Employer PF
            </Col>
            <Col sm="6">
              <Form.Group>
                <Form.Select
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select PF</option>
                  <option value={pfVariableList.pf_limit}>
                    {pfVariableList.pf_limit} &#8377;
                  </option>
                  <option value={pfVariableList.pf_variable}>
                    {pfVariableList.pf_variable} %
                  </option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="color-gray" sm="6">
              Employer ESI
            </Col>
            <Col sm="6">
              <Form.Group>
                <FormControl style={{ width: "100%" }} required>
                  <Form.Select
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select ESI</option>
                    <option value={pfVariableList.employee_esi_variable}>
                      {pfVariableList.employee_esi_variable} %
                    </option>
                  </Form.Select>
                </FormControl>
              </Form.Group>
            </Col>
          </Row>

          {deductions?.map((data, index) => {
            const { head, calculation } = data;
            return (
              <Row className="mt-2" key={index}>
                <Col className="color-gray" sm="6">
                  {head}
                </Col>
                <Col
                  sm="6"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Form.Group style={{ width: "100%" }}>
                    <Form.Select
                      name="calculation"
                      value={calculation}
                      onChange={(e) => handleDeductionsChanges(index, e)}
                      style={{ color: "gray" }}
                    >
                      <option value=""> Select a value</option>
                      <option
                        value="basic"
                        selected={calculation == "basic" ? "selected" : null}
                      >
                        Basic Calculation
                      </option>
                      <option
                        value="hra"
                        selected={calculation == "hra" ? "selected" : null}
                      >
                        HRA Calculation
                      </option>
                      <option
                        value="dearness"
                        selected={calculation == "dearness" ? "selected" : null}
                      >
                        Dearness Calculation
                      </option>
                    </Form.Select>
                  </Form.Group>

                  {/* {index && ( */}
                  <RiDeleteBin5Line
                    className="remove-icon"
                    onClick={(e) => removeDeductionsFields(e, index)}
                    style={{ cursor: "pointer" }}
                  />
                  {/* ) } */}
                </Col>
              </Row>
            );
          })}

          <Row className="mt-4">
            <Col className="color-gray" sm="6">
              TDS
            </Col>
            <Col sm="6">
              <Form.Group className="color-gray">
                System Calculated <FiInfo className="info-icon" />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="remove title-flex" sm="6">
              <FormControl style={{ width: "100%" }} required>
                <Select
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={saveDeductionData.deductions}
                  options={deduction}
                  onChange={addDeductionsField}
                  placeholder={
                    <div
                      className="add-text"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <IoIosAdd className="add-icon" /> Add Deduction
                    </div>
                  }
                  style={{ border: "none" }}
                />
              </FormControl>
            </Col>
          </Row>
          <button type="submit" id="submitForm"  style={{display: "none"}}></button>
        </Form>
      </div>
    </div>
  );
};

export default SalaryTemplate;
