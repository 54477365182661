import "./style.css";
import { useEffect, useState, useCallback } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

// Redux
import { commonGetData, initState } from "../../../redux/slices/commonSlice";

const LeaveBalancePolicyList = ({ dispatch, user, state }) => {
  const path = `admin/getEmployeeLeaveBalList?userId=${user.user_id}`;

  const [usersList, setUsersList] = useState([]);

  const getEmployeeList = useCallback(() => {
    dispatch(commonGetData(path));
  }, []);

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    if (state.commonGetSuccess) {
      setUsersList(state.commonGetData);
      dispatch(initState());
    }
  }, [state.commonGetSuccess]);

  const columns = [
    {
      headerName: "Name",
      field: "name",
      render: (rowData) => {
        return (
          <>
            <Link
              style={{ textDecoration: "none", color: "blue" }}
              to={`user-info/${rowData.staffId}`}
            >
              {rowData.name} <ArrowForwardIcon style={{ fontSize: "16px" }} />
            </Link>
          </>
        );
      },
      flex: 1,
    },
    {
      headerName: "Job Title",
      field: "job_title",
      flex: 1,
    },

    {
      headerName: "Status",
      field: "status",
      cellRenderer: (rowData) => {
        return rowData.data.status == 1 ? "Active" : "Inactive";
      },
      flex: 1,
    },
  ];

  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: 400, width: "100%" }}
    >
      <AgGridReact
        rowData={usersList}
        columnDefs={columns}
        omLayout="autoHeight"
        pagination={true}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
      />
    </div>
  );
};

export default LeaveBalancePolicyList;
