import "./LandingPage.css";
import { useRef } from "react";

import LadingPageCardOne from "../../components/Landing-page-components/Lading-page-card-one/LadingPageCardOne";
import LadingPageCardTwo from "../../components/Landing-page-components/Lading-page-card-two/LadingPageCardTwo";
import LadingPageCardThree from "../../components/Landing-page-components/Lading-page-card-three/LadingPageCardThree";
import LadingPageCardFour from "../../components/Landing-page-components/Lading-page-card-four/LadingPageCardFour";
import LadingPageCardFive from "../../components/Landing-page-components/Lading-page-card-five/LadingPageCardFive";
import Footer from "../../components/Landing-page-components/footer/Footer";

const LandingPage = () => {
  const cardThreeRef = useRef(null);

  const handleScrollToCardThree = () => {
    cardThreeRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    
      <div className="LandingPage-body">
        <LadingPageCardOne scrollToCardThree={handleScrollToCardThree} />
        <LadingPageCardTwo />
        <div ref={cardThreeRef}>
          <LadingPageCardThree />
        </div>
        <LadingPageCardFour/>
        <LadingPageCardFive/>
        <Footer/>
      </div>
   
  );
};

export default LandingPage;
