import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { TbCurrentLocation } from "react-icons/tb";
import { useCallback, useRef, useState, useEffect } from "react";
// icons
import MarkerIcon from "../../assets/icons/location-position.png";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Index = ({
  center,
  routeCoordinates = [],
  polylineOptions,
  markers = [],
  zoom,
  width = "100%",
  height = "100%",
  radius = 500,
  handleMarkerDrag = null,
  handleGetCurrentLocation = null,
  draggable = false,
}) => {
 
  const containerStyle = {
    width: width,
    height: height,
  };

  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
  };

  const [isBtnView, setIsBtnView] = useState(false);

  return (
    <LoadScript googleMapsApiKey={API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={() => {
          onLoad;
          setIsBtnView(true);
        }}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        <Circle
          center={center}
          radius={radius}
          options={{
            strokeColor: "#4B0082",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#E6E6FA",
            fillOpacity: 0.35,
          }}
        />

        {routeCoordinates.length && (
          <Polyline path={routeCoordinates} options={polylineOptions} />
        )}

        {markers.map((marker) => (
          <Marker
            key={marker.id}
            label={marker.label}
            position={marker.position}
            icon={{
              url: MarkerIcon,
              width: "24px",
              height: "24px",
            }}
            draggable={draggable}
            onDragEnd={(event) => {
              handleMarkerDrag !== null
                ? handleMarkerDrag(event)
                : console.log();
            }}
          />
        ))}
      </GoogleMap>
      {isBtnView && (
        <span
          onClick={handleGetCurrentLocation}
          style={{
            position: "absolute",
            bottom: "165px",
            right: "10px",
            padding: "3px",
            cursor: "pointer",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <TbCurrentLocation style={{ fontSize: "20px" }} />
        </span>
      )}
    </LoadScript>
  );
};

export default Index;
