import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerRegistration: { page: 0, pageSize: 10 },
  appointmentList: { page: 0, pageSize: 10 },
  SpiritualTourList: { page: 0, pageSize: 10 },
  MedicalCampList: { page: 0, pageSize: 10 },
  RestaurantList: { page: 0, pageSize: 10 },
  appointmentReportList: { page: 0, pageSize: 10 },
  restaurantReportList: { page: 0, pageSize: 10 },
  medicalCampReportList: { page: 0, pageSize: 10 },
  spiritualTourReportList: { page: 0, pageSize: 10 },
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state[payload.key].page = payload.value;
    },
    setRowsPerPage: (state, { payload }) => {
      state[payload.key].pageSize = payload.value;
    },
    setSortBy: (state, { payload }) => {
      state[payload.key].sortBy = payload.value;
    },
    setSortOrder: (state, { payload }) => {
      state[payload.key].sortOrder = payload.value;
    },
  },
});

export const { setCurrentPage, setRowsPerPage, setSortBy, setSortOrder } =
  paginationSlice.actions;

export default paginationSlice.reducer;
