import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mobileOpen: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setMobileOpen: (state, action) => {
      state.mobileOpen = action.payload;
    },
  },
});

export const { setMobileOpen } = layoutSlice.actions;

export default layoutSlice.reducer;
