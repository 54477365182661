/* eslint-disable no-unused-expressions */
import "./style.css";
import { Col, Form, Row } from "react-bootstrap";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchBranchData } from "../../redux/slices/branchSlice";
import { fetchDepartmentData } from "../../redux/slices/departmentSlice";
import {
  commonGetData,
  commonGetDataOne,
  initState,
} from "../../redux/slices/commonSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import Map from "../../components/Map/Index";

const Index = () => {
  const [center, setCenter] = useState({
    lat: 40.7128,
    lng: -74.006,
  });

  // Route coordinates: from Bangalore to Chennai via an intermediate point
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const [markers, setMarkers] = useState([]);
  // Polyline options for route appearance
  const polylineOptions = {
    strokeColor: "#3498db",
    strokeOpacity: 0.8,
    strokeWeight: 4,
    clickable: true,
    draggable: true,
    editable: true,
    visible: true,
  };

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const dispatch = useDispatch();
  const branchState = useSelector((state) => state.branch);
  const departState = useSelector((state) => state.department);
  const commonState = useSelector((state) => state.common);

  const [branches, setBranches] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const staffPath = `admin/getEmployeeStaffList?userId=${user?.user_id}`;

  const fetchData = useCallback(() => {
    dispatch(fetchBranchData(user.user_id));
    dispatch(fetchDepartmentData(user.user_id));
    dispatch(commonGetData(staffPath));
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (branchState.brnGetSuccess) {
      setBranches(branchState.brnGetData);
    }
    if (departState.depGetSuccess) {
      setDepartments(departState.depGetData);
    }
    if (commonState.commonGetSuccess) {
      setStaffList(commonState.commonGetData);
      dispatch(initState());
    }
  }, [
    branchState.brnGetSuccess,
    departState.depGetSuccess,
    commonState.commonGetSuccess,
  ]);

  const [state, setState] = useState({
    branchId: "",
    departmentId: 0,
    staffId: "",
    date: "",
  });

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    setRouteCoordinates([]);
    setMarkers([]);
    setCenter({
      ...center,
      lat: "",
      lng: "",
    });
    const {
      target: { name, value },
    } = event;
    setState({
      ...state,
      [name]: value,
    });
    // const URL = `admin/getEmployeeLatandLong?staffId=2&branchId=6&departmentId=0&selectedDate=2024`
    const url = `admin/getEmployeeLatandLong?staffId=${state.staffId}&branchId=${state.branchId}&departmentId=${state.departmentId}&selectedDate=${value}`;

    dispatch(commonGetDataOne(url));
  };

  useEffect(() => {
    if (commonState.commonGetOneSuccess) {
      if (commonState.commonGetOneData.length < 1) {
        toast.error("Data not found with given details");
      }
      if (
        Array.isArray(commonState.commonGetOneData) &&
        commonState.commonGetOneData.length > 0
      ) {
        const routeCoord = commonState.commonGetOneData.map((row) => {
          return {
            lat: parseFloat(row.latitude),
            lng: parseFloat(row.longitude),
          };
        });
        setRouteCoordinates(routeCoord);
        let mkr = [
          
        ];
        if(routeCoord.length > 1){
          mkr.push({
            id: 1,
            position: routeCoord[0],
            label: "start",
          })
          mkr.push(
            {
              id: 2,
              position: routeCoord[routeCoord.length - 1],
              label: "end",
            }
          )
        }else{}
        setMarkers(mkr);
        setCenter({
          ...center,
          lat: routeCoord[0].lat,
          lng: routeCoord[0].lng,
        });
      }
      dispatch(initState());
    }
  }, [commonState.commonGetOneSuccess]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="tracking-map-container">
        <div className="page-top-bar">Live Location </div>
        <div
          className="page-body"
          style={{ position: "relative", height: "82vh", padding: 0 }}
        >
          <Map
            center={center}
            routeCoordinates={routeCoordinates}
            polylineOptions={polylineOptions}
            markers={markers}
            zoom={10}
            width={"100%"}
            height={"100%"}
            draggable={false}
          />

          <div className="center-menu">
            <Form>
              <Row>
                <Col sm={state.branchId && state.staffId ? 3 : 4}>
                  <Form.Label>Select Branch</Form.Label>
                  <Form.Select name="branchId" onChange={handleChange}>
                    <option value="">Select option</option>
                    {branches?.map((v, i) => {
                      return (
                        <option value={v.branchId} key={i}>
                          {v.branch_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>

                <Col sm={state.branchId && state.staffId ? 3 : 4}>
                  <Form.Label>Select Department</Form.Label>
                  <Form.Select name="departmentId" onChange={handleChange}>
                    <option value="0">Select Department</option>
                    {departments?.map((v, i) => {
                      return (
                        <option value={v.departmentId} key={i}>
                          {v.department_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>

                <Col sm={state.branchId && state.staffId ? 3 : 4}>
                  <Form.Label>Select Employees</Form.Label>
                  <Form.Select name="staffId" onChange={handleChange}>
                    <option value="">Select option</option>
                    {Array.isArray(staffList) &&
                      staffList.length > 0 &&
                      staffList.map((v, i) => {
                        return (
                          <option value={v.staffId} key={i}>
                            {v.full_name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
                {state.branchId && state.staffId && (
                  <Col sm="3">
                    <Form.Label>Select Date</Form.Label>
                    <Form.Control
                      name="date"
                      type="date"
                      onChange={(e) => {
                        handleChange(e), handleSubmit(e);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
