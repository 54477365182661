import "./style.css";
import { useCallback, useEffect, useState } from "react";
import bannerImg from "../../assets/Images/login.png";

import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  urlExpiredData,
  initUrlExpired,
} from "../../redux/slices/expiredUrlSlice";
import {
  commonSaveData,
  initCommonState,
} from "../../redux/slices/commonSlice";

import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useParams } from "react-router-dom";

const Register = () => {
  const dispatch = useDispatch();
  const urlExpiredState = useSelector((state) => state.expiredUrl);
  const { id } = useParams();
  const [isUrlExpired, setIsUrlExpired] = useState(false);
  const getExpiredUrl = useCallback(() => {
    dispatch(urlExpiredData(`getAdminRecords?userId=${id}`));
  });

  useEffect(() => {
    getExpiredUrl();
  }, []);

  useEffect(() => {
    if (urlExpiredState.urlExpGetSuccess) {
      setIsUrlExpired(urlExpiredState.urlExpGetData);
      dispatch(initUrlExpired());
    }
  }, [urlExpiredState.urlExpGetSuccess]);

  return (
    <div className="Register">
      {isUrlExpired ? (
        <center>
          <h1 style={{ textAlign: "center" }}>
            Sorry! This URL has been expired.
          </h1>
        </center>
      ) : (
        <div className="register-container">
          <div className="register_left">
            <img src={bannerImg} />
          </div>
          <div className="register_right">
            <Login />
          </div>
        </div>
      )}
    </div>
  );
};

const Login = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const commonState = useSelector((state) => state.common);
  const [loginInfo, setLoginInfo] = useState({
    userId: id,
    emailId: "",
    password: "",
    rePassword: "",
  });

  const [passwordError, setRePasswordError] = useState(false);

  const loginHandelChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo((pr) => ({ ...pr, [name]: value }));
  };

  const loginHandelChangeOne = (e) => {
    const { name, value } = e.target;
    setLoginInfo((pr) => ({ ...pr, [name]: value }));
    if (loginInfo.password == value) {
      setRePasswordError(false);
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (loginInfo.password !== loginInfo.rePassword) {
        setRePasswordError(true);
        return;
      } else {
        let d = {
          path: "passwordReset",
          data: loginInfo,
        };
        dispatch(commonSaveData(d));
      }
    }
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setTimeout(() => {
        navigate("/");
      }, 3000);
      dispatch(initCommonState());
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      dispatch(initCommonState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <h3
          style={{ marginTop: "-10px", textAlign: "center" }}
          className="mb-3"
        >
          Create New Password
        </h3>
        <Form.Group className="mb-3">
          <Form.Label>
            Email ID <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            name="emailId"
            type="email"
            value={loginInfo.emailId}
            placeholder="Enter Email Id"
            onChange={loginHandelChange}
            autoComplete="new-password"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email id.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label sm="4">Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            value={loginInfo.password}
            placeholder="**********"
            onChange={loginHandelChange}
            autoComplete="new-password"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid password.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label sm="4">Confirm Password</Form.Label>
          <Form.Control
            name="rePassword"
            type="password"
            value={loginInfo.rePassword}
            placeholder="**********"
            onChange={loginHandelChangeOne}
            autoComplete="new-password"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid password.
          </Form.Control.Feedback>
          {passwordError && (
            <span style={{ color: "red" }}>Password not matched</span>
          )}
        </Form.Group>

        <div className="register-input-dev">
          <button type="submit">
            Create &nbsp;
            {commonState.commonSuccess && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </Form>
    </>
  );
};

export default Register;
