import { Link } from "react-router-dom";
import "./CompanyDetails.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import { useCallback, useEffect, useState } from "react";
import { emailValidator, formValidation } from "../../../hooks/formValidation";
import { ToastContainer, toast } from "react-toastify";
import ButtonCom from "../../../components/button/button";
import { CiEdit } from "react-icons/ci";
import Spinner from 'react-bootstrap/Spinner';

import { useDispatch, useSelector } from "react-redux";
import {
  commonGetData,
  commonSaveData,
  initState,
} from "../../../redux/slices/commonSlice";
import { Form } from "react-bootstrap";

const CompanyDetails = () => {
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  const [user, setUser] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : []
  );

  const [business, setBusiness] = useState([]);

  const getBusiness = useCallback(() => {
    dispatch(commonGetData(`admin/getBusinessTypeList?userId=${user.user_id}`));
  }, []);

  useEffect(() => {
    getBusiness();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      setBusiness(commonState.commonGetData);
    }
  }, [commonState.commonGetSuccess]);

  const inputsTwo = [
    {
      name: "cmp_name",
      label: "Company Name",
      placeholder: "Enter Company Name",
    },
    {
      name: "business_type",
      label: "Business Type",
      chi: business,
    },
    {
      name: "cmp_address",
      label: "Company Address",
      placeholder: "Enter Company Address",
    },

    {
      name: "gst_number",
      label: "GST Number",
      placeholder: "Enter GST Number",
      value: user.company_gst,
      isReadOnly: true,
    },
    {
      name: "urn_number",
      label: "Udyam Registration Number ",
      placeholder: "Udyam Registration Number",
    },
  ];

  const [errorItems, setErrorItems] = useState({});
  const [errorMsg, setErrorMag] = useState({});

  const [addStaff, setAddStaff] = useState({
    cmp_name: user.company_name,
    business_type: 5,
    cmp_address: "WElcome",
    gst_number: user.company_gst,
    urn_number: "",
  });

  const handelChange = (e) => {
    const { name, value } = e.target;
    setAddStaff((pv) => ({ ...pv, [name]: value }));
  };

  const handelSubmit = (e) => {
    e.preventDefault();

    let d = {
      path: "admin/UpdateCompanyDetails",
      data: {
        userId: user.user_id,
        cmp_name: addStaff.cmp_name,
        business_type: addStaff.business_type,
        cmp_address: addStaff.cmp_address,
        gst_number: addStaff.gst_number,
        urn_number: addStaff.urn_number,
      },
    };

    dispatch(commonSaveData(d));
  };
  const [isFile, setIsFile] = useState(false)
  const [cmp_logo, setCmpLogo] = useState("");

  const fileHandle = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function (event) {
      // const img = new Image();
      const base64 = event.target.result;

      setCmpLogo(base64);
      setIsFile(true)
    };
  };

  const updateLogo = () => {
    if(cmp_logo){
      let d = {
        path: "admin/UpdateCompanyLogo",
        data: {
          userId: user.user_id,
          cmp_logo,
        },
      };
      dispatch(commonSaveData(d));
    }
  }

 

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initState());
      setIsFile(false)
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      dispatch(initState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  

  const takeFile = () => {
    document.getElementById("fileId").click()
  };


  return (
    <div className="SettingBody">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1.5 }} />
        </Link>
        <p>Company Details</p>
      </div>

      <div className="SettingBody-card">
        <div className="CompanyDetails">
          <div className="CompanyDetails-head">
            <p>{user.company_name}</p>
            <ButtonCom
              type="button"
              class_name={"p_btn"}
              text={ commonState.commonLoading ? "Updating..." : isFile ? "Update Logo" : "Update" }
              url_or_onclick={isFile ? updateLogo : handelSubmit}
              icon={null}
              width={""}
            />
          </div>

          <div className="CompanyDetails-body">
            <div className="CompanyDetails-logo">
            <Form enctype="multipart/form-data">
              <Form.Control type="file" onChange={fileHandle} style={{display: "none"}} id="fileId"/>
            </Form>
              <Avatar
                alt="Remy Sharp"
                src={ cmp_logo ? cmp_logo : "/static/images/avatar/1.jpg"}
                sx={{ width: 100, height: 100 }}
                onClick={takeFile}
                style={{cursor: "pointer"}}
              />
              <p>
                Company Code :<span>IMKQM</span>{" "}
              </p>
            </div>

            <form>
              {inputsTwo.map((item, i) => (
                <div key={i} className="BasicDetails-input">
                  <label>
                    {item.label}{" "}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </label>

                  {/* <span>:</span> */}
                  {item.chi ? (
                    <select
                      id="dropdown"
                      name={item.name}
                      onChange={handelChange}
                    >
                      <option value="">Select Business Type</option>
                      {item.chi.map((dv, i) => (
                        <option key={i} value={dv.businessTypeId}>
                          {dv.type_name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <>
                      <input
                        style={{
                          border:
                            errorItems[item.name] || errorMsg[item.name]
                              ? "1px solid red"
                              : "",
                        }}
                        name={item.name}
                        type={item.type || ""}
                        value={addStaff[item.name]}
                        placeholder={item.placeholder}
                        onChange={handelChange}
                        readOnly={item.isReadOnly ? true : false}
                      />
                      <label id="add_staff-erMsg">{errorMsg[item.name]}</label>
                    </>
                  )}
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
