import { PropaneSharp } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URI;

let initialState = {
  urlExpLoading: false,
  urlExpSuccess: false,
  urlExpError: false,
  urlExpMessage: null,

  urlExpGetLoading: false,
  urlExpGetSuccess: false,
  urlExpGetError: false,
  urlExpGetMessage: null,
  urlExpGetData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
const userId = user?.user_id;
const accountType = user?.account_type;

export const urlExpiredData = createAsyncThunk(
  "urlExpiredData",
  async (path) => {
    console.log(path);
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
          let data = {
            response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
          };
          return data;
        } else {
          return error;
        }
      });
  }
);

const urlExpiredSlice = createSlice({
  name: "branch",
  initialState,

  reducers: {
    initUrlExpired: (state) => {
      state.urlExpLoading = false;
      state.urlExpSuccess = false;
      state.urlExpError = false;
      state.urlExpMessage = null;

      state.urlExpGetLoading = false;
      state.urlExpGetSuccess = false;
      state.urlExpGetError = false;
      state.urlExpGetMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(urlExpiredData.pending, (state, action) => {
      state.urlExpGetLoading = true;
      state.urlExpGetSuccess = false;
      state.urlExpGetError = false;
      state.urlExpGetMessage = null;
      state.urlExpGetData = null;
    });
    builder.addCase(urlExpiredData.fulfilled, (state, action) => {
      state.urlExpGetLoading = false;
      state.urlExpGetSuccess = true;
      state.urlExpGetError = false;
      state.urlExpGetData = action.payload.data.isExpired;
    });
    builder.addCase(urlExpiredData.rejected, (state, action) => {
      state.urlExpGetLoading = false;
      state.urlExpGetSuccess = false;
      state.urlExpGetError = true;
      state.urlExpGetData = null;
    });
  },
});

export const { initUrlExpired } = urlExpiredSlice.actions;

export default urlExpiredSlice.reducer;
