import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
// const userId = user?.user_id;
const accountType = user?.account_type;
const baseUrl = process.env.REACT_APP_BASE_URI;

const initialState = {
  empLoading: false,
  empSuccess: false,
  empMessage: null,
  empData: null,

  empGetLoading: false,
  empGetSuccess: false,
  empGetMessage: null,
  empGetData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

export const createEmployee = createAsyncThunk(
  "createEmployee",
  async (data) => {
    const result = {
      data: {
        message: "Sorry, you can only view the data, not create it.",
        data: [],
      },
    };
    return parseInt(accountType) === 2
      ? result
      : await axios
          .post(`${baseUrl}admin/saveClientNewEmployee`, data, config)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (!navigator.onLine) {
              let data = {
                response: {
                  data: [],
                  message: "No Internet Connection Found",
                  success: false,
                },
                data: {
                  data: [],
                  message: "No Internet Connection Found",
                  success: false,
                },
              };
              return data;
            } else {
              return error;
            }
          });
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createEmployee.pending, (state) => {
      state.empLoading = true;
      state.empSuccess = false;
      state.empError = false;
      state.empMessage = null;
      state.empData = null;
    });

    builder.addCase(createEmployee.fulfilled, (state, action) => {
      if (action.payload.status == 200) {
        state.empLoading = false;
        state.empSuccess = accountType == 2 ? false : true;
        state.empError = accountType == 2 ? true : false;
        state.empMessage = action.payload.data.message;
        state.empData = null;
      } else {
        state.empLoading = false;
        state.empSuccess = false;
        state.empError = true;
        state.empMessage = action.payload.response.data.message;
        state.empData = null;
      }
    });

    builder.addCase(createEmployee.rejected, (state, action) => {
      state.empLoading = false;
      state.empSuccess = true;
      state.empError = false;
      state.empMessage = action.payload.data;
      state.empData = null;
    });
    // Get employee data
  },
});

export default employeeSlice.reducer;
