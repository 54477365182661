import './EmployeesManagers.css'
import { useState } from 'react'
import { Link } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from '../../../components/table/TableMui'
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";

const EmployeesManagers = () => {
     const [currentPage, setCurrentPage] = useState(1);

     const [usersList, setUsersList] = useState([
       {
         id: "1",
         name: "sakthi",
         jobTitle: "sathi@1",
         employeeId: "001",
         employeeType: "manager",
         dateOfJoin: "1/1/2009",
         dateOfBirth: "1/1/2010",
         mobileNumber: "123456789",
       },
     ]);

     const heading = [
       {
         name: "",
         label: "name",
         render: (rowData) => {
           return (
             <>
               <div className="Branches-table-Avatar">
                 <Avatar
                   alt="Remy Sharp"
                   src="/static/images/avatar/1.jpg"
                   sx={{ width: 40, height: 40 }}
                 />

                 <div className="Branches-table-info">
                    <p>Aishwarya B t</p>
                    <label>Employees</label>
                 </div>
               </div>
             </>
           );
         },
       },
       
       {
         name: "",
         label: "id",
         width: 200,
         render: (rowData) => {
           return (
             <>
               <div className="EmployeesManagers-select">
                 <select className="EmployeesManagers-table-select">
                   <option value="">--Select--</option>
                   <option value="option1">Option 1</option>
                   <option value="option2">Option 2</option>
                   <option value="option3">Option 3</option>
                 </select>
               </div>
             </>
           );
         },
       },
     ];


  return (
    <div className="SettingBody">
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Employees & Managers</p>
      </div>

      <div className="SettingBody-card">
        <div className="Branches-body">
          <div className="Branches-table">
            <div className="Employees-Managers-search-input">
              <SearchIcon sx={{ scale: 1.5 }} />
              <input placeholder="search Name of Employees" />
              <button>Search</button>
            </div>
            <TableMui
              tableHead={heading}
              data={usersList}
              rowPagesPlay={true}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              Pages={10}
              editId={false}
            />
          </div>

          {/* <div className="Branches-btn">
            <button>Add New Branches</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default EmployeesManagers
