import { Link, useLocation } from "react-router-dom";
import "./UserInfo.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState, useEffect, useCallback } from "react";
import EmploymentDetails from "../EmploymentDetails/EmploymentDetails";
import UserPermission from "../userPermission/UserPermission";
import LeaveBalanceDetails from "../LeaveBalanceDetails/LeaveBalanceDetails";
import PenaltyOvertimeDetails from "../PenaltyOvertimeDetails/PenaltyOvertimeDetails";
import DocumentType from "../DocumentType/DocumentType";
import AttendanceDetails from "../AttendanceDetails/AttendanceDetails";
import CustomDetails from "../CustomDetails/customDetails";
import SalaryDetails from "../SalaryDetails/SalaryDetails";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import ButtonCom from "../../../components/button/button";
import Table from "../../../components/table/TableMui";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import bankImg from "../../../assets/Images/balnk-page/bank-account.png";
import BackgroundVerification from "../BackgroundVerification/Index";

// Redux
import {
  commonGetData,
  commonGetDataOne,
  commonGetDataTwo,
  commonSaveData,
  initState,
} from "../../../redux/slices/commonSlice";
import { useDispatch, useSelector } from "react-redux";

const userInfoList = [
  { name: "Personal Details" },
  { name: "Employment Details" },
  { name: "Custom Details" },
  { name: "Background Verification" },
  { name: "Bank Account" },
  { name: "User Permission" },
  { name: "Attendance Details" },
  { name: "Salary Details" },
  { name: "Leave & Balance Details" },
  { name: "Penalty & Overtime Details" },
  { name: "Tax Declarations" },
  { name: "Document" },
];

const tabStyle = {
  borderLeft: "4px solid #309BFF",
  color: "#309BFF",
  backgroundColor: "rgba(0, 166, 255, 0.086) ",
};

const UserInfo = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const location = useLocation();
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  // variable declarations
  // const search = useLocation().search;
  // const staffId = new URLSearchParams(search).get("id");
  const [userDetails, setUserDetails] = useState([]);
  const getUserDetails = useCallback(() => {
    const url = `admin/getEmployeeInidvidualStaffList?staffId=${location.pathname
      .split("/")
      .pop()}`;
    dispatch(commonGetDataOne(url));
  }, []);

  useEffect(() => {
    if (commonState.commonGetOneSuccess) {
      setUserDetails(commonState.commonGetOneData);
      dispatch(initState());
    }
  }, [commonState.commonGetOneSuccess]);

  useEffect(() => {
    getUserDetails();
  }, []);

  const [highlightTap, setHighlightTap] = useState(0);
  const tapitem = [
    <PersonalDetails
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <EmploymentDetails
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <CustomDetails
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <BackgroundVerification
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <BankAccount
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <UserPermission
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <AttendanceDetails
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <SalaryDetails
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <LeaveBalanceDetails
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    <PenaltyOvertimeDetails
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
    11,
    <DocumentType
      user={user}
      userDetails={userDetails}
      commonState={commonState}
    />,
  ];
  return (
    <div className="userInfo">
      <div className="userInfo-heading" style={{ zIndex: 0 }}>
        <Link to={"/empmanagement"}>
          <ArrowBackIcon
            sx={{ scale: 1 }}
            style={{ color: "gray", marginLeft: "10px" }}
          />
        </Link>
        <p>{userDetails.length ? userDetails[0].full_name : null}</p>
      </div>

      <div className="userInfo-Body">
        <div className="userInfo-Body-left">
          <div className="userInfo-left-item">
            {userInfoList.length > 0
              ? userInfoList.map((item, i) => (
                  <div
                    key={i}
                    className="user-info-list"
                    style={highlightTap === i ? tabStyle : null}
                    onClick={() => setHighlightTap(i)}
                  >
                    <p>
                      {item.name} <ArrowForwardIosIcon />
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
        {/* <PersonalDetails /> */}
        <div className="userInfo-Body-right">
          {tapitem.length > 0
            ? tapitem.map((item, i) => (
                <div key={i}>
                  {highlightTap === i && userDetails.length ? (
                    <div key={i}>{item}</div>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

const PersonalDetails = ({ user, userDetails, commonState }) => {
  return (
    <div className="body-container">
      <div className="page-top-bar">
        <h1>Personal Details</h1>
      </div>
      <div className="page-body">
        {userDetails.length && (
          <BasicDetails userDetails={userDetails} user={user} />
        )}
      </div>
    </div>
  );
};

const BasicDetails = ({ userDetails, user }) => {
  const addressDetails = userDetails[0].addressDetails;
  const governmentIds = userDetails[0].governmentIds;
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);

  const [bloodGroup, setBloodGroup] = useState([]);

  const getBloodGroup = useCallback(() => {
    dispatch(commonGetData("admin/getBloodGroup"));
  }, []);

  useEffect(() => {
    getBloodGroup();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      console.log(commonState.commonGetData);
      if (commonState.commonGetData !== undefined) {
        const newArr = commonState.commonGetData.map((v) => {
          return { label: v.blood_group_name, value: v.bloodGroupId };
        });
        setBloodGroup(newArr);
      }
    }
  }, [commonState.commonGetSuccess]);


  const [updateStaff, setUpdateStaff] = useState({
    userId: user.user_id,
    staffId: userDetails[0]?.staffId,
    staff_name: userDetails[0]?.full_name,
    staff_mobile: userDetails[0]?.mobile_number,
    staff_personal_email: userDetails[0]?.personalDetails.personalEmail,
    dob: userDetails[0]?.personalDetails.dob,
    // date_of_join: "",
    gender: userDetails[0]?.gender,
    // blood_group: userDetails[0].personalDetails.blood_group,
    marital_status: userDetails[0]?.personalDetails.maritalStatus,

    // marriage_date: userDetails[0]?.personalDetails.marriageDate,

    marriageDate: userDetails[0]?.personalDetails.marriageDate ? userDetails[0]?.personalDetails.marriageDate.substring(
      0,
      10
    ) : null,

    spouseName: userDetails[0]?.personalDetails.spouseName,

    // str.substring(0, 5)
    // blood_group: "",
    // guardian_name: userDetails[0].fatherName,

    // address: userDetails[0].addressDetails.addressText,
    // city: userDetails[0].addressDetails.city,
    // state: userDetails[0].addressDetails.state,
    // pincode: userDetails[0].addressDetails.pincode,

    emerg_cont_name: userDetails[0].addressDetails.emergencyContactName,
    emerg_cont_mobile: userDetails[0].addressDetails.emergencyContactNumber,
    emerg_cont_address: userDetails[0].addressDetails.emergencyContactAddress,

    aadharName: userDetails[0]?.personalDetails.aadharName,
    aadharNumber: userDetails[0]?.personalDetails.aadharNumber,

    panName: userDetails[0]?.personalDetails.panName,
    panNumber: userDetails[0]?.personalDetails.panNumber,
    // gov_voterId:userDetails[0]?.personalDetails.maritalStatus,
    uanNumber: userDetails[0]?.personalDetails.uanNumber,
    pfAccountNumber : userDetails[0]?.personalDetails.pfNumber,

    educationNum: userDetails[0].qualificationDetails.educationNum,
    qualification: userDetails[0].qualificationDetails.qualification,
    fromYear: userDetails[0].qualificationDetails.fromYear,
    toYear: userDetails[0].qualificationDetails.toYear,
    institute: userDetails[0].qualificationDetails.institute,

  });

  const inputsOne = [
    {
      name: "staff_name",
      label: "Name",
      placeholder: "Enter Name",
      required: true,
    },
  ];

  const inputsTwo = [
    {
      name: "staff_personal_email",
      label: "Personal Email Id",
      placeholder: "Enter Office Email Id",
    },
    {
      name: "dob",
      label: "Date Of Birth",
      placeholder: "Enter Date Of birth",
      type: "date",
    },

    {
      name: "gender",
      label: "Gender",
      chi: [
        { label: "Select gender", value: "" },
        { label: "Male", value: "2" },
        { label: "Female", value: "1" },
        { label: "Other", value: "3" },
      ],
    },
    {
      name: "marital_status",
      label: "Marital Status",
      chi: [
        { label: "Select marital status", value: "" },
        { label: "Married", value: "1" },
        { label: "Unmarried", value: "2" },
        { label: "Widow", value: "3" },
      ],
    },

    {
      name: "marriageDate",
      label: "Marriage Date",
      placeholder: "Select Date Of Marriage",
      type: "date",
      isVisible: false,
    },

    {
      name: "spouseName",
      label: "Spouse Name",
      placeholder: "Enter Spouse Name",
      type: "text",
      isVisible: false,
    },
    // {
    //   name: "blood_group",
    //   label: "Blood Group",
    //   chi: bloodGroup,
    // },
    // {
    //   name: "guardian_name",
    //   label: "Guardian's Name",
    //   placeholder: "Guardian's Name",
    // },
    {
      name: "emerg_cont_name",
      label: "Emergency Contact Name",
      placeholder: "Emergency Contact Name",
    },
  ];

  const GovernmentIdsInputsOnes = [
    {
      name: "aadharName",
      label: "Aadhaar Name",
      placeholder: "Enter Aadhaar Name",
    },
    {
      name: "aadharNumber",
      label: "Aadhaar Number",
      placeholder: "Enter Aadhaar Number",
    },

    { name: "panName", label: "PAN Name", placeholder: "Enter PAN Name" },
    { name: "panNumber", label: "PAN Number", placeholder: "Enter PAN Number" },

    { name: "pfAccountNumber", label: "PF Number", placeholder: "Enter PF Number" },
    // {
    //   name: "gov_drivinglicense",
    //   label: "Driving License",
    //   placeholder: "Enter Driving License Number",
    // },
    // {
    //   name: "gov_voterId",
    //   label: "Voter Id",
    //   placeholder: "Enter Voter Id Number",
    // },
    // {
    //   name: "gov_passportnumber",
    //   label: "Passport Number",
    //   placeholder: "Enter Passport Number",
    // },
    { name: "uanNumber", label: "UAN Number", placeholder: "Enter UNA" },
  ];

  const AddressInfos = [
    {
      name: "address",
      label: "Address",
      placeholder: "Enter Address",
    },
    {
      name: "city",
      label: "City",
      placeholder: "Enter City",
    },
    {
      name: "state",
      label: "State",
      placeholder: "Select State",
      chi: [
        { label: "Select marital status", value: "" },
        { label: "Married", value: "1" },
        { label: "Unmarried", value: "2" },
        { label: "Widow", value: "3" },
      ],
    },
    {
      name: "pincode",
      label: "Pincode",
      placeholder: "Enter City",
    },

    // {
    //   name: "currentAddress",
    //   label: "Current Address",
    //   placeholder: "Enter Current Address",
    // },
    // {
    //   name: "permanentAddress",
    //   label: "Permanent Address",
    //   placeholder: "Enter permanent Address",
    // },
  ];

  const qualification = [
    {
      name: "educationNum",
      label: "Education Number",
      placeholder: "Enter Education Number",
    },
    {
      name: "qualification",
      label: "Qualification",
      placeholder: "Enter Qualification",
    },
    {
      name: "fromYear",
      label: "From Year",
      placeholder: "Enter From Year",
    },
    {
      name: "toYear",
      label: "To Year",
      placeholder: "Enter To Year",
    },
    {
      name: "institute",
      label: "Institute",
      placeholder: "Enter Institute",
    },
  ];

  const handelChange = (e) => {
    const { name, value } = e.target;
    setUpdateStaff((pv) => ({ ...pv, [name]: value }));
  };

  const preSubmit = () => {
    document.getElementById("submit-btn").click();
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = {
      path: "admin/updatePersonalDetails",
      data: updateStaff,
    };
    dispatch(commonSaveData(data));
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initState());
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const checkLength = (e) => {
    if (e.target.value.length === e.target.maxLength) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <div className="BasicDetails">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div
        style={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Update Details"
          url_or_onclick={preSubmit}
          icon={null}
          width={""}
        />
      </div>

      <div
        className="BasicDetails_heading"
        style={{
          textAlign: "left",
          marginLeft: "-15px",
          padding: 0,
          fontWeight: "600",
        }}
      >
        <p className="border-bottom">Basic Details</p>
      </div>
      <div className="BasicDetails-form">
        <form onSubmit={handelSubmit}>
          {inputsOne.map((item, i) => (
            <div key={i} className="BasicDetails-input">
              <label>
                {item.label}
                <span style={{ color: "red" }}>{item.required ? "*" : ""}</span>
              </label>
              {/* <span>:</span> */}
              <input
                name={item.name}
                value={updateStaff.staff_name}
                placeholder={item.placeholder}
                onChange={handelChange}
              />
            </div>
          ))}

          <div className="BasicDetails-input">
            <label>
              Mobile Number <span style={{ color: "red" }}>*</span>
            </label>
            {/* <span>:</span> */}
            <select
              id="dropdownNumber"
              name="code"
              onChange={handelChange}
              aria-readonly
              readOnly
            >
              <option value="91">+91</option>
            </select>
            <input
              id="inputNumber"
              name="staff_mobile"
              value={updateStaff.staff_mobile}
              placeholder={"Enter Mobile Number"}
              onChange={handelChange}
              readOnly
            />
          </div>

          {inputsTwo.map((item, i) => (
            <div key={i} className="BasicDetails-input">
              <label>
                {item.label}
                <span style={{ color: "red" }}>{item.required ? "*" : ""}</span>
              </label>

              {/* <span>:</span> */}
              {item.chi ? (
                <select id="dropdown" name={item.name} onChange={handelChange}>
                  {item.chi.length > 0
                    ? item.chi.map((dv, i) => (
                        <option
                          key={i}
                          value={dv.value}
                          selected={
                            dv.value == updateStaff[item.name] ? "selected" : ""
                          }
                        >
                          {dv.label}
                        </option>
                      ))
                    : null}
                </select>
              ) : (
                <>
                  <input
                    name={item.name}
                    type={item.type || ""}
                    value={updateStaff[item.name]}
                    placeholder={item.placeholder}
                    onChange={handelChange}
                  />
                </>
              )}
            </div>
          ))}

          <div className="BasicDetails-input">
            <label>Emergency Contact MobileNumber</label>
            {/* <span>:</span> */}
            <select
              id="dropdownNumber"
              name="code"
              onChange={handelChange}
              readOnly
            >
              <option value="91">+91</option>
            </select>
            <input
              id="inputNumber"
              name="emerg_cont_mobile"
              value={updateStaff.emerg_cont_mobile}
              placeholder={"Enter Mobile Number"}
              onChange={handelChange}
            />
          </div>

          {/* <div className="BasicDetails-input">
            <label>Emergency Contact RelationShip</label>
           
            <input
              name={"emerg_cont_relation"}
              value={updateStaff.emerg_cont_relation}
              placeholder={"Emergency Contact RelationShip"}
              onChange={handelChange}
            />
          </div> */}

          <div className="BasicDetails-input">
            <label>Emergency Contact Address</label>
            {/* <span>:</span> */}
            <textarea
              name={"emerg_cont_address"}
              value={updateStaff.emerg_cont_address}
              onChange={handelChange}
              placeholder={"Enter Address"}
            />
          </div>

          <div className="BasicDetails mt-5">
            <div
              className="BasicDetails_heading"
              style={{
                textAlign: "left",
                marginLeft: "-15px",
                padding: 0,
                fontWeight: "600",
              }}
            >
              <p className="border-bottom">Government Ids</p>
            </div>
            <div className="BasicDetails-form">
              {GovernmentIdsInputsOnes.map((item, i) => (
                <div key={i} className="BasicDetails-input">
                  <label>
                    {item.label}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </label>
                  {/* <span>:</span> */}
                  <input
                    name={item.name}
                    value={updateStaff[item.name]}
                    placeholder={item.placeholder}
                    onChange={handelChange}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="BasicDetails">
            <div
              className="BasicDetails_heading"
              style={{
                textAlign: "left",
                marginLeft: "-15px",
                padding: 0,
                fontWeight: "600",
              }}
            >
              <p className="border-bottom">Address Details</p>
            </div>
            <div className="BasicDetails-form">
              {AddressInfos.map((item, i) => (
                <div key={i} className="BasicDetails-input">
                  <label>
                    {item.label}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </label>
                  {/* <span>:</span> */}
                  <input
                    name={item.name}
                    value={updateStaff[item.name]}
                    placeholder={item.placeholder}
                    onChange={handelChange}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="BasicDetails">
            <div
              className="BasicDetails_heading"
              style={{
                textAlign: "left",
                marginLeft: "-15px",
                padding: 0,
                fontWeight: "600",
              }}
            >
              <p className="border-bottom">Qualification Details</p>
            </div>
            <div className="BasicDetails-form">
              {qualification.map((item, i) => (
                <div key={i} className="BasicDetails-input">
                  <label>
                    {item.label}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </label>
                  <input
                    name={item.name}
                    value={updateStaff[item.name]}
                    placeholder={item.placeholder}
                    onChange={handelChange}
                  />
                </div>
              ))}
            </div>
          </div>

          <button type="submit" id="submit-btn"></button>
        </form>
      </div>
    </div>
  );
};

// ====================================================================
const BankAccount = ({ user, userDetails, commonState }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const empBankDetails = userDetails.length
    ? userDetails[0].empBankDetails
    : null;

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isBankAccount, setIsBankAccount] = useState(true);
  const [accountList, setAccountList] = useState([]);
  const [accountData, setAccountData] = useState({
    userId: user.user_id,
    staffId: userDetails[0].staffId,
    bank_or_upi: 1,
    accountType: empBankDetails?.accountType,
    acc_holder_name: empBankDetails?.accountHolderName,
    acc_number: empBankDetails?.accountNumber,
    bank_name: empBankDetails?.bankName,
    ifsc_code: empBankDetails?.ifscCode,
    upi_id: empBankDetails?.upiId,
  });

  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    let data = [
      {
        accountType: userDetails[0].bankDetails.accountType,
        bankAccountNumber: userDetails[0].bankDetails.bankAccountNumber,
        bankBranch: userDetails[0].bankDetails.bankBranch,
        bankIfsc: userDetails[0].bankDetails.bankIfsc,
        bankName: userDetails[0].bankDetails.bankName,
      },
    ];
    setAccounts(data)
    // 
  }, []);

  const getAccountList = useCallback(() => {
    // let url = `admin/getBankAccountList?userId=${user.user_id}`;
    let url = `admin/getEmployeeBankList?userId=${user.user_id}&staffId=${userDetails[0].staffId}`;
    dispatch(commonGetDataTwo(url));
  }, []);

  useEffect(() => {
    getAccountList();
  }, []);

  // userDetails?[0].empBankDetails ? userDetails?[0].empBankDetails :

  useEffect(() => {
    if (commonState.commonGetTwoSuccess) {
      setAccountList(commonState.commonGetTwoData);
    }
  }, [commonState.commonGetTwoSuccess]);

  const handleClose = () => {
    setValidated(false);
    setOpen(false);
    setIsBankAccount(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleOption = (e) => {
    const { name, value } = e.target;
    if (value == "upi") {
      setIsBankAccount(false);
      setAccountData({
        ...accountData,
        bank_or_upi: 2,
      });
    } else {
      setIsBankAccount(true);
      setAccountData({
        ...accountData,
        bank_or_upi: 1,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountData({
      ...accountData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const data = {
        path: "admin/updateBankAccountDetails",
        data: accountData,
      };
      dispatch(commonSaveData(data));
    }
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      getAccountList();
      toast.success(commonState.commonMessage);
      dispatch(initState());
      handleClose();
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      dispatch(initState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);
// accounts
  const heading = [
    {
      name: "Account Type",
      label: "accountType",
    },
    {
      name: "Bank Account Number",
      label: "bankAccountNumber",
    },
    {
      name: "Bank Branch",
      label: "bankBranch",
    },
    {
      name: "Bank IFSC",
      label: "bankIfsc",
    },
    {
      name: "Bank Name",
      label: "bankName",
    }
  ];

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="PersonalDetails" style={{ padding: "0px 8px" }}>
        <div className="PersonalDetails-heading">
          <p>Bank Account</p>

          <button onClick={handleClickOpen}>Add Details</button>
        </div>
        <div className="PersonalDetails-body">
          <div className="page-body">
            <Table
              tableHead={heading}
              data={accounts}
              rowPagesPlay={true}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              Pages={10}
              editId={false}
            />
          </div>

          {/* Pop Up dialogue */}

          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle
              style={{
                borderBottom: "1px solid lightgray",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Add New Bank Account
            </DialogTitle>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <DialogContent>
                <Form.Group style={{ display: "flex", gap: "10px" }}>
                  <Form.Check
                    required
                    type="radio"
                    name="payment_option"
                    value="bank_account"
                    label="Bank Account"
                    onChange={handleOption}
                    checked={isBankAccount ? true : false}
                  ></Form.Check>

                  <Form.Check
                    required
                    type="radio"
                    name="payment_option"
                    value="upi"
                    label="UPI"
                    checked={!isBankAccount ? true : false}
                    onChange={handleOption}
                  ></Form.Check>
                </Form.Group>

                {isBankAccount && (
                  <>
                    <Form.Group>
                      <Row className="mt-5">
                        <Col
                          sm="4"
                          style={{ textAlign: "right", whiteSpace: "nowrap" }}
                        >
                          <Form.Label>
                            Account Holder's Name
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Control
                            required={isBankAccount ? true : false}
                            name="acc_holder_name"
                            value={accountData.acc_holder_name}
                            placeholder="Enter account holder name"
                            onChange={handleChange}
                            width="200px"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter valid bank account holder name
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="mt-3">
                        <Col
                          sm="4"
                          style={{ textAlign: "right", whiteSpace: "nowrap" }}
                        >
                          <Form.Label>
                            Account Number
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Control
                            required={isBankAccount ? true : false}
                            name="acc_number"
                            value={accountData.acc_number}
                            placeholder="Enter account number"
                            onChange={handleChange}
                            width="200px"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter valid account number
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="mt-3">
                        <Col
                          sm="4"
                          style={{ textAlign: "right", whiteSpace: "nowrap" }}
                        >
                          <Form.Label style={{ whiteSpace: "nowrap" }}>
                            Bank Name <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Control
                            required={isBankAccount ? true : false}
                            name="bank_name"
                            value={accountData.bank_name}
                            placeholder="Enter bank name"
                            onChange={handleChange}
                            width="200px"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter valid bank name
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row className="mt-3">
                        <Col
                          sm="4"
                          style={{ textAlign: "right", whiteSpace: "nowrap" }}
                        >
                          <Form.Label style={{ twhiteSpace: "nowrap" }}>
                            IFSC Code <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Control
                            required={isBankAccount ? true : false}
                            name="ifsc_code"
                            value={accountData.ifsc_code}
                            placeholder="Enter IFSC code"
                            onChange={handleChange}
                            width="200px"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter valid IFSC code
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                )}
                {!isBankAccount && (
                  <Row className="mt-4">
                    <Col
                      sm="3"
                      className="mt-2"
                      style={{ textAlign: "right", whiteSpace: "nowrap" }}
                    >
                      <Form.Label>
                        UPI ID <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Col>
                    <Col sm="9">
                      <Form.Group>
                        <Form.Control
                          required={!isBankAccount ? true : false}
                          name="upi_id"
                          value={accountData.upi_id}
                          placeholder="Enter UPI ID"
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide valid UPI number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </DialogContent>
              <DialogActions style={{ borderTop: "1px solid lightgray" }}>
                <Button
                  variant="contained"
                  color="gray"
                  onClick={handleClose}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    height: "38px",
                    backgroundColor: "gray",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    height: "38px",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
