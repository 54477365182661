import "./Footer.css";
import dropdown from "../../../assets/icons/dropdown-1.png";
import cta from "../../../assets/Images/cta-bg.png";
import Untitled from "../../../assets/icons/Untitled-1.png";

const Footer = () => {
  return (
    <div className="footer-tag">
      <div className="gradient-container">
        <svg id="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 210">
          <path
            fill="#081B2E"
            fill-opacity="1"
            d="M0,128L120,144C240,160,480,192,720,202.7C960,213,1200,203,1320,197.3L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
        <div className="newsimage">
          <img src={cta} alt="cat" />
        </div>

        <div className="emailadd">
          <h4>
            <b>Subscribe to our newsletter and stay updated</b>
          </h4>

          <div className="lastletter">
            <form>
              <input
                className="lastmail"
                type="text"
                name="email"
                placeholder="Email Address"
              />
              <button className="lastbutton">
                <img src={dropdown} alt="" />
              </button>
            </form>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer">
          <div className="aboutus">
            <h2>About us</h2>
            <br />
            <p>
              The best platform to hire the right candidates Lorem Ipsum is
              <br /> simply dummy text of the printing and typesetting industry.
              <br /> Lorem Ipsum has been the industry's standard dummy <br />
              text ever since the 1500s, when an unknown printer took a galley
              <br /> of type and scrambled it to make a type specimen book.
              <br /> It has survived not only five centuries, but also the leap
              <br /> into electronic typesetting, remaining essentially
              unchanged.
            </p>

            <div className="logo-Copyright">
              <h6>Copyright @ Seekk</h6>
            </div>
          </div>

          <div className="links">
            <h2>Links</h2>
            <br />
            <div className="link">
              <a href="">Terms & Conditions</a>
              <a href="">Privacy Policy</a>
              <a href="">Contact Us</a>
            </div>
          </div>
          <div className="social">
            <h2>Social</h2>
            <br />
            <img src={Untitled} alt="logo" />
            <p>We would love to hear from you info@seekk.in</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
