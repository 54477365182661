import "./style.css";
import React, { useCallback, useEffect, useState } from "react";
import Table from "../../components/table/TableMui";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { commonSaveData, commonGetData } from "../../redux/slices/commonSlice";
import ButtonCom from "../../components/button/button";
import Switch from "@mui/material/Switch";

const Index = () => {
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  const [open, setOpen] = useState(false);
  const [modules, setModules] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [addStaff, setAddStaff] = useState({
    user_name: "",
    mobile: "",
    email: "",
    dob: "",
    cmp_gst: "",
    cmp_name: "",
    cmp_size: "",
    cmp_website: "",
    cmp_logo: "",
    cmp_pan: "",
    cmp_code: "",
    account_type: "",
  });
  const [checked, setChecked] = useState(false);

  const getModules = useCallback(() => {
    dispatch(commonGetData("admin/getAppModulesList"));
  }, []);

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      setModules(commonState.commonGetData);
    }
  }, [commonState.commonGetSuccess]);

  const [validated, setValidated] = useState(false);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setAddStaff({ ...addStaff, [name]: value });
  };

  const handleChange = (event) => {
    let elm = document.querySelectorAll(".modules-check");
    if (event.target.checked) {
      elm.forEach((r) => {
        r.childNodes[0].checked = event.target.checked;
      });
    } else {
      elm.forEach((r) => {
        r.childNodes[0].checked = event.target.checked;
      });
    }
    setChecked(event.target.checked);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let d = {
        path: "",
        data: {},
      };
      dispatch(commonSaveData(d));
    }
  };

  const heading = [
    {
      name: "Title",
      label: "branch_name",
    },
    {
      name: "Storage",
      label: "radius",
    },
    {
      name: "Plan Type",
      label: "branch_address",
    },
    {
      name: "Modules",
      label: "branch_address",
    },
    {
      name: "Status",
      label: "branch_address",
    },
    {
      name: "Action",
      label: "id",
      width: 200,
      //   render: (rowData) => {
      //     return (
      //       <>
      //         <div className="Branches-table-button">
      //           <EditNoteIcon sx={{ scale: 2, color: "green" }} />
      //           <DeleteIcon sx={{ scale: 1.7, color: "red" }} />
      //         </div>
      //       </>
      //     );
      //   },
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValidated(false);
  };
  return (
    <div className="body-container">
      <div className="page-top-bar">
        <h1>Package List</h1>
        <ButtonCom
          type={"button"}
          class_name={"p_btn"}
          text={"Add Package"}
          url_or_onclick={handleClickOpen}
          icon={null}
          width={""}
        />
      </div>
      <div className="page-body">
        <Table
          tableHead={heading}
          data={packageList}
          rowPagesPlay={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          Pages={10}
          editId={false}
        />
      </div>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          className="pb-2 mb-4"
          style={{
            borderBottom: "1px solid lightgray",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Create Package
        </DialogTitle>

        <DialogContent>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Row>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control placeholder="Title" required />
                  <Form.Control.Feedback type="invalid">
                    PLease provide valid title.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm="6">
                <Form.Group>
                  <Form.Label>Plan Type</Form.Label>
                  <Form.Select required>
                    <option>1</option>
                    <option>1</option>
                    <option>1</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    PLease provide valid plan type.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col sm="12">
                <div>
                  <div
                    className="header"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      textAlign: "left",
                      gap: "40px",
                    }}
                  >
                    <span style={{ width: "100%" }}>Tenure</span>
                    <span style={{ width: "100%" }}>Month(s)</span>
                    <span style={{ width: "100%" }}>Rate *</span>
                  </div>

                  <div
                    className="body"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Form.Group>
                      <Form.Control
                        placeholder="Ex. Weekkly, Quarterly, Monthly, Yearly"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        PLease provide valid tenure.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Control placeholder="1" required />
                      <Form.Control.Feedback type="invalid">
                        PLease provide valid month.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Control placeholder="Rate" required />
                      <Form.Control.Feedback type="invalid">
                        PLease provide valid rate.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    PLease provide valid description.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="12">
                <Form.Group>
                  <Form.Label>Modules</Form.Label>
                  <br />
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col
                sm="12"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "15px",
                  marginTop: "20px",
                }}
              >
                {modules?.map((row, idx) => {
                  return (
                    <Form.Group key={idx}>
                      <Form.Check
                        label={row.module_name}
                        value={row.moduleId}
                        className="modules-check"
                      />
                    </Form.Group>
                  );
                })}
              </Col>
            </Row>

            <DialogActions
              className="pt-3 mt-5"
              style={{ borderTop: "1px solid lightgray" }}
            >
              <Button
                style={{
                  padding: "7px 30px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                variant="contained"
                color="default"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                style={{
                  padding: "7px 30px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                variant="contained"
                color="info"
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Index;
