import "./style.css";
import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CiSearch } from "react-icons/ci";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import Avatar from "../../../assets/Images/user-avatar.jpg";

import DataTable from "react-data-table-component";

import { useDispatch, useSelector } from "react-redux";
import {
  commonSaveData,
  commonGetData,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import {
  fetchData,
} from "../../../redux/slices/roleSlice";

const EmployeeManager = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const roleState = useSelector((state) => state.roles);

  const [user] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : []
  );
  const [employeeManagerList, setEmployeeManagerList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(employeeManagerList);

  const getEmployeeManagerList = useCallback(() => {
    dispatch(
      commonGetData(`admin/getEmployeeManageList?userId=${user.user_id}`)
    );
    dispatch(fetchData());
  }, []);

  useEffect(() => {
    getEmployeeManagerList();
  }, []);

  useEffect(() => {
    if (state.commonGetSuccess) {
      setEmployeeManagerList(state.commonGetData);
      setFilteredData(state.commonGetData);
    }
    if (roleState.roleGetSuccess) {
      setRoles(roleState.roleGetData);
    }
  }, [state.commonGetSuccess, roleState.roleGetSuccess]);

  const handleInputSearch = (event) => {
    const value = event.target.value || "";
    setSearchText(value);
  };

  const handleSearch = () => {
    const value = searchText;

    if (value && typeof value === "string") {
      const lowerCaseValue = value.toLowerCase();

      const filtered = employeeManagerList.filter((item) =>
        item.full_name.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filtered);
    }
    if(value == ""){
      setFilteredData(employeeManagerList)
    }
  };

  const columns = [
    {
      name: "Full Name",
      selector: (row) => {
        return (
          <div style={{ display: "flex", alignItem: "center", gap: "10px" }}>
            <div
              style={{
                position: "relative",
                top: "2px",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
            >
              <img
                src={Avatar}
                alt="avatar"
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
              />
            </div>
            <div>
              <h3>{row.full_name}</h3>
              <span>{row.empRoleName}</span>
            </div>
          </div>
        );
      },
    },
    {
      name: "Action",
      width: "30%",
      selector: (row) => {
        console.log(row)
        return (
          <Row style={{ position: "absolute", width: "100%", top: "4px" }}>
            <Col sm="12">
              <Form.Select
                style={{ width: "100%" }}
                onChange={(e) => {
                  handleSubmit(e, row);
                }}
              >
              {roles.map((r, i) => {
                return <option value={r.roleId} selected={row.emp_role == r.roleId ? "selected" : ""}>{r.rolename}</option>
              })}
                
              </Form.Select>
            </Col>
          </Row>
        );
      },
    },
  ];

  const handleSubmit = (e, row) => {
    const {target: {value}} = e
      let d = {
        path: "/admin/updateEmployerManageList",
        data: {
          userId: user.user_id,
          staffId: parseInt(row.staffId),
          emp_role: parseInt(value),
        },
      };
    dispatch(commonSaveData(d))
  };

  useEffect(() => {
    if(state.commonSuccess){
      toast.success(state.commonMessage);
      dispatch(initCommonState())
      getEmployeeManagerList();
      setSearchText("")
    }
    if(state.commonError){
      toast.error(state.commonMessage);
      dispatch(initCommonState())
      setSearchText("")
    }
  }, [state.commonSuccess, state.commonError])

  const customStyles = {
    header: {
      style: {
        textAlign: "left", // Center-align header text
        justifyContent: "start", // Additional property to center content horizontally
        display: "flex",
      },
    },
    headCells: {
      style: {
        textAlign: "left", // Center-align header text
        justifyContent: "start", // Additional property to center content horizontally
        display: "flex",
        borderRight: "1px solid lightgray",
      },
    },
  };
  return (
    <div className="SettingBody">
    <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Employee & Managers</p>
      </div>

      <div className="SettingBody-card mt-2">
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="Branches-btn"></div>
          <div className="Branches-table emp-tbl">
            <Row>
              <Col sm="6">
                <InputGroup size="sm" className="">
                  <InputGroup.Text>
                    <CiSearch />
                  </InputGroup.Text>
                  <Form.Control
                    value={searchText}
                    onChange={handleInputSearch}
                    style={{ fontSize: "16px" }}
                  />
                  <InputGroup.Text>
                    <Button
                      style={{ height: "30px", lineHeight: "10px" }}
                      variant="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>

            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              subHeader
              customStyles={customStyles}
              // subHeaderComponent={
              //   <input
              //     type="text"
              //     placeholder="Search table"
              //     value={searchText}
              //     onChange={handleSearch}
              //     style={{
              //       marginBottom: "10px",
              //       padding: "8px",
              //       width: "300px",
              //     }}
              //   />
              // }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeManager;
