import { Outlet } from 'react-router-dom';
import './SuperAdminLayOut.css'
import SuperAdminNavBar from '../../components/super-admin-page-componets/super-admin-navbare/SuperAdminNavBar';

const SuperAdminLayOut = () => {
 return (
   <div className="superAdminPanelLayOut">
     <SuperAdminNavBar />
     <div className="superAdminPanelLayOut-body">
       <div className="superAdminPanelLayOut-body-right">
         <Outlet />
       </div>
     </div>
   </div>
 );
}

export default SuperAdminLayOut