import React from 'react'

const ErrorPage = () => {
  return (
    <div>
      <p>The Requested Page Doesn't Exist</p>
      <a href="/dashboard"
      style={{textDecoration:'underLine',color:"blue"}}
      >Please Click here to go back to Homepage</a>
    </div>
  );
}

export default ErrorPage