import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
// const userId = user?.user_id;
const accountType = user?.account_type;
const baseUrl = process.env.REACT_APP_BASE_URI;

let initialState = {
  desLoading: false,
  desSuccess: false,
  desError: false,
  desMessage: null,

  desigLoading: false,
  desigSuccess: false,
  desigError: false,
  desigMessage: null,
  desigData: null,

  desGetLoading: false,
  desGetSuccess: false,
  desGetError: false,
  desGetMessage: null,
  desGetData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

export const fetchDesignationList = createAsyncThunk(
  "fetchDesignationList",
  async (userId) => {
    return await axios
      .get(`${baseUrl}admin/getDesignationList?userId=${userId}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
        let data = {
          response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
        }
         return data
      } else {
        return error
      }
      });
  }
);

export const fetchModuleData = createAsyncThunk("fetchModuleData", async () => {
  return await axios
    .get(`${baseUrl}admin/getAppModulesList?userId=3`, {}, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
        }
         return data
      } else {
        return error
      }
    });
});

export const saveDesignation = createAsyncThunk(
  "saveDesignation",
  async (data) => {
    const result = {
      data: {
        message: "Sorry, you can only view the data, not create it.",
        data: [],
      },
    };
    return parseInt(accountType) === 2
      ? result
      : await axios
      .post(`${baseUrl}admin/createDesignation`, data, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
        let data = {
          response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
        }
         return data
      } else {
        return error
      }
      });
  }
);

const designationSlice = createSlice({
  name: "designation",
  initialState,

  reducers: {
    initState: (state) => {
      state.desGetLoading = false;
      state.desGetSuccess = false;
      state.desGetError = false;
      state.desGetMessage = null;

      state.desLoading = false;
      state.desSuccess = false;
      state.desError = false;
      state.desMessage = null;

      state.desigLoading = false;
      state.desigSuccess = false;
      state.desigError = false;
      state.desigMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchModuleData.pending, (state, action) => {
      state.desGetLoading = true;
      state.desGetSuccess = false;
      state.desGetError = false;
      state.desGetMessage = null;
      state.desGetData = null;
    });
    builder.addCase(fetchModuleData.fulfilled, (state, action) => {
      state.desGetLoading = false;
      state.desGetSuccess = true;
      state.desGetError = false;
      state.desGetMessage = action.payload.data.message;
      state.desGetData = action.payload.data.data;
    });
    builder.addCase(fetchModuleData.rejected, (state, action) => {
      state.desGetLoading = false;
      state.desGetSuccess = false;
      state.desGetError = true;
      state.desGetMessage = action.payload.data.message;
      state.desGetData = null;
    });
    // To create designation
    builder.addCase(saveDesignation.pending, (state, action) => {
      state.desLoading = true;
      state.desSuccess = false;
      state.desError = false;
      state.desMessage = null;
    });
    builder.addCase(saveDesignation.fulfilled, (state, action) => {
      state.desLoading = false;
      state.desSuccess = accountType == 2 ? false : true;
      state.desError = accountType == 2 ? true : false;
      state.desMessage = action.payload.data.message;
    });
    builder.addCase(saveDesignation.rejected, (state, action) => {
      state.desLoading = false;
      state.desSuccess = false;
      state.desError = true;
      //   state.desMessage = action.payload.data.message;
    });

    // To get designation
    builder.addCase(fetchDesignationList.pending, (state, action) => {
      state.desigLoading = true;
      state.desigSuccess = false;
      state.desigError = false;
      state.desigMessage = null;
    });
    builder.addCase(fetchDesignationList.fulfilled, (state, action) => {
      state.desigLoading = false;
      state.desigSuccess = true;
      state.desigError = false;
      state.desigMessage = action.payload.data.message;
      state.desigData = action.payload.data.data;
    });
    builder.addCase(fetchDesignationList.rejected, (state, action) => {
      state.desigLoading = false;
      state.desigSuccess = false;
      state.desigError = true;
      state.desMessage = action.payload.data.message;
    });
  },
});

export const { initState } = designationSlice.actions;
export default designationSlice.reducer;
