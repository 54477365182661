import "./MoreSetting.css";
import { useState, useEffect, useRef, useCallback } from "react";
import Switch from "@mui/material/Switch";
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import FingerprintIcon from "@mui/icons-material/Fingerprint";

import { PiMinusSquare } from "react-icons/pi";
import { PiPlusSquare } from "react-icons/pi";

import ButtonCom from "../../components/button/button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { getDaysInMonth } from "../../services/commonServices";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../redux/slices/shiftsSlice";
import { commonSaveData, initState } from "../../redux/slices/commonSlice";

const AttendanceDetails = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const [userDetails, setUserDetails] = useState([]);
  const commonState = useSelector((state) => state.common);

  const tabList = [
    {
      name: "Work Timings",
      tabItem: (
        <WorkTimings
          user={user}
          userDetails={userDetails}
          commonState={commonState}
        />
      ),
    },
    {
      name: "Attendance Mode",
      tabItem: (
        <AttendanceModes
          user={user}
          userDetails={userDetails}
          commonState={commonState}
        />
      ),
    },
    {
      name: "Attendance Rules",
      tabItem: (
        <AttendanceRules
          user={user}
          userDetails={userDetails}
          commonState={commonState}
        />
      ),
    },
  ];
  const [highlightTap, setHighlightTap] = useState(0);
  return (
    <div className="body-container">
      <div className="page-top-bar">
        <h1>Attendance Details</h1>
      </div>
      <div className="page-body" style={{ padding: 0 }}>
        <div className="AttendanceDetails-heading">
          <div className="AttendanceDetails-tap">
            {tabList.map((item, i) => (
              <p
                style={{
                  borderBottom: highlightTap === i ? "2px solid gray" : "",
                }}
                key={i}
                onClick={() => setHighlightTap(i)}
              >
                {item.name}
              </p>
            ))}
          </div>
        </div>
        <div className="AttendanceDetails-body">
          {tabList.map((item, i) => (
            <div key={i}>
              {highlightTap === i ? <>{item.tabItem}</> : <></>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const WorkTimings = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const [userDetails, setUserDetails] = useState([]);
  const commonState = useSelector((state) => state.common);

  const today = dayjs();
  const tomorrow = dayjs().add(1, "day");
  const dispatch = useDispatch();
  const state = useSelector((state) => state.shift);

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const [attendanceType, setAttendanceType] = useState("");
  const [dummyFixed, setDummyFixed] = useState("");
  const [fixedData, setFixedData] = useState({
    isWoShMon: "",
    fixedMon: "",

    isWoShTue: "",
    fixedTue: "",

    isWoShWed: "",
    fixedWed: "",

    isWoShThu: "",
    fixedThu: "",

    isWoShFri: "",
    fixedFri: "",

    isWoShSat: "",
    fixedSat: "",

    isWoShSun: "",
    fixedSun: "",
  });

  const [flexibleDates, setFlexibleDate] = useState([]);
  const [maxMonth, setMaxMonth] = useState(null);
  const [shiftData, setShiftData] = useState([]);
  const [flexibleId, setFlexibleId] = useState("");
  const [fixedId, setFixedId] = useState("");

  const getShiftData = useCallback(() => {
    dispatch(fetchData());
  });

  useEffect(() => {
    getShiftData();

    var today = new Date();
    var dd = today.getDate(); //January is 0!
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;

    setMaxMonth(tomorrow);
  }, []);

  useEffect(() => {
    if (state.shiftGetSuccess) {
      setShiftData(state.shiftGetData);
    }
  }, [state.shiftGetSuccess]);

  // Confirm Dialog box for change attendance type
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => setConfirm(true);
  const handleCloseConfirm = () => setConfirm(false);
  const [preAttType, setPreAttType] = useState("");

  const handleType = (e) => {
    const { value } = e.target;
    if (attendanceType == "") {
      setAttendanceType(value);
    } else {
      setPreAttType(value);
      handleConfirm();
    }
  };

  const handleFixedCheck = (e, typeName, isWoSh, value) => {
    const { checked } = e.target;
    if (checked) {
      setFixedData({
        ...fixedData,
        [typeName]: typeName == "fixedSun" ? "All sundays week off" : "weekoff",
        [isWoSh]: "wo",
      });
    } else {
      setFixedData({
        ...fixedData,
        [typeName]: "",
        [isWoSh]: "",
      });
    }
  };
  const [isWoSh, setIsWoSh] = useState("");
  const [isWoShValue, setIsWoShValue] = useState("");
  const handleFixedId = (id, isWoSh, value) => {
    setFixedId(id);
    setIsWoSh(isWoSh);
    setIsWoShValue("sf");
    setOpen(true);
  };

  const handleFixed = (val) => {
    setDummyFixed(val);
  };

  const handleFixedOption = () => {
    setFixedData({
      ...fixedData,
      [fixedId]: dummyFixed,
      [isWoSh]: isWoShValue,
    });
    document.getElementById(fixedId).checked = false;
    setOpen(false);
  };

  const handleClose = () => {
    setValidated(false);
    setOpen(false);
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  // Flexible functions are started from here
  const [flexibleAtt, setFlexibleAtt] = useState({
    flexible: [],
  });
  const [flexibleIdArr, setFlexibleIdArr] = useState([]);
  const [flexibleMonth, setFlexibleMonth] = useState("");
  const [flexibleYear, setFlexibleYear] = useState("");
  const [flexibleDate_, setFlexibleDate_] = useState("");
  const [flexibleDay, setFlexibleDay] = useState("");

  const handleFlexible = (id, date, day) => {
    setFlexibleId(id);
    setFlexibleDate_(date);
    setFlexibleDay(day);
    setOpen(true);
  };
  // Date selection
  const handleDateChange = (e) => {
    const { $M, $y } = e;
    const dates = getDaysInMonth($M, $y);
    setFlexibleDate(dates);
    setFlexibleYear($y);
    setFlexibleMonth($M);
  };

  const handleRemoveFlexibleIcon = (id, chk) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.value = "";
      let arr = flexibleIdArr?.filter(function (item) {
        return item !== id;
      });
      setFlexibleIdArr(arr);

      const index = flexibleAtt.flexible.findIndex(
        (item) => parseInt(item.date) === parseInt(flexibleDate_)
      );
      if (index !== -1) {
        flexibleAtt.flexible.splice(index, 1);
      }

      if (localStorage.getItem("flexibleAttendance")) {
        let dataLocal = JSON.parse(localStorage.getItem("flexibleAttendance"));
        delete dataLocal[id];

        localStorage.setItem("flexibleAttendance", JSON.stringify(dataLocal));
      }
    }
  };

  const handleRemoveFlexibleCheck = (id, chk) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.value = "";
      let arr = flexibleIdArr?.filter(function (item) {
        return item !== id;
      });
      setFlexibleIdArr(arr);
    }

    if (localStorage.getItem("flexibleAttendance")) {
      let dataLocal = JSON.parse(localStorage.getItem("flexibleAttendance"));
      delete dataLocal[id];

      localStorage.setItem("flexibleAttendance", JSON.stringify(dataLocal));
    }
  };

  const handleFlexibleCheck = (e, id, chk, date, day) => {
    const { checked } = e.target;
    const elem = document.getElementById(id);
    if (checked) {
      handleRemoveFlexibleCheck(id);
      elem.value = "Weekoff";
      setFlexibleDate_(date);
      setFlexibleDay(day);
      let d = {
        date: date,
        timing: "Weekoff",
        day:
          day == "Mon"
            ? "monday"
            : day == "Tue"
            ? "Tuesday"
            : day == "Wed"
            ? "Wednesday"
            : day == "Thu"
            ? "Thursday"
            : day == "Fri"
            ? "Friday"
            : day == "Sat"
            ? "Saturday"
            : "Sunday",
        weekoff:
          day == "Mon"
            ? 1
            : day == "Tue"
            ? 2
            : day == "Wed"
            ? 3
            : day == "Thu"
            ? 4
            : day == "Fri"
            ? 5
            : day == "Sat"
            ? 6
            : 7,
        shift: 0,
      };
      if (flexibleAtt.flexible.length) {
        const index = flexibleAtt.flexible?.findIndex(
          (item) => parseInt(item.date) === parseInt(date)
        );
        if (index !== -1) {
          flexibleAtt.flexible?.splice(index, 1);
        }
      }

      const flexible1 = [...flexibleAtt.flexible];
      flexible1.push(d);
      setFlexibleAtt({ ...flexibleAtt, flexible: flexible1 });
    } else {
      elem.value = "";
      setFlexibleDate_("");
      setFlexibleDay("");

      if (flexibleAtt.flexible.length) {
        const index = flexibleAtt.flexible?.findIndex(
          (item) => parseInt(item.date) === parseInt(date)
        );
        if (index !== -1) {
          flexibleAtt.flexible?.splice(index, 1);
        }
      }
    }
  };

  const [dummyFlexibleData, setDummyFlexibleData] = useState("");
  const [errorVal, setErrorVal] = useState(false);

  const handleShiftSelection = (v) => {
    setDummyFlexibleData(v);
    setErrorVal(false);
  };

  const [flexible_shift_arr, set_flexible_shift_arr] = useState(
    localStorage.getItem("flexibleAttendance")
      ? JSON.parse(localStorage.getItem("flexibleAttendance"))
      : {}
  );

  const handleSelectOption = () => {
    if (dummyFlexibleData) {
      let elem = document.getElementById(flexibleId);
      let k = flexibleId.split("flexible");
      let el = document.getElementById(`check${k[1]}`);

      if (elem) {
        flexibleIdArr.push(flexibleId);
        setFlexibleIdArr(flexibleIdArr);
        elem.value = dummyFlexibleData;
        handleClose();
        el.checked = false;

        let d = {
          date: flexibleDate_,
          timing: dummyFlexibleData,
          day:
            flexibleDay == "Mon"
              ? "monday"
              : flexibleDay == "Tue"
              ? "Tuesday"
              : flexibleDay == "Wed"
              ? "Wednesday"
              : flexibleDay == "Thu"
              ? "Thursday"
              : flexibleDay == "Fri"
              ? "Friday"
              : flexibleDay == "Sat"
              ? "Saturday"
              : "Sunday",
          shift:
            flexibleDay == "Mon"
              ? 1
              : flexibleDay == "Tue"
              ? 2
              : flexibleDay == "Wed"
              ? 3
              : flexibleDay == "Thu"
              ? 4
              : flexibleDay == "Fri"
              ? 5
              : flexibleDay == "Sat"
              ? 6
              : 7,
          weekoff: 0,
        };

        const index = flexibleAtt.flexible.findIndex(
          (item) => parseInt(item.date) === parseInt(flexibleDate_)
        );

        if (index !== -1) {
          // Object exists, remove it
          flexibleAtt.flexible.splice(index, 1);
        }

        const flexible = [...flexibleAtt.flexible];
        flexible.push(d);
        setFlexibleAtt({ ...flexibleAtt, flexible: flexible });

        set_flexible_shift_arr({
          ...flexible_shift_arr,
          [flexibleId]: {
            value: dummyFlexibleData,
          },
        });

        if (localStorage.getItem("flexibleAttendance")) {
          let dataLocal = JSON.parse(
            localStorage.getItem("flexibleAttendance")
          );
          dataLocal[flexibleId] = {
            value: dummyFlexibleData,
          };
          let k = JSON.stringify(dataLocal);
          localStorage.setItem("flexibleAttendance", k);
        } else {
          let kl = {
            [flexibleId]: {
              value: dummyFlexibleData,
            },
          };
          let k = JSON.stringify(kl);
          localStorage.setItem("flexibleAttendance", k);
        }
      }
    } else {
      setErrorVal(true);
    }
  };

  const isExistFlexibleAttendance = (key, value) => {
    if (key) {
      if (localStorage.getItem("flexibleAttendance")) {
        let dataLocal = JSON.parse(localStorage.getItem("flexibleAttendance"));
        return dataLocal[key]?.value == value ? true : false;
      }
    }
  };

  const getFlexVal = useCallback((key) => {
    if (key) {
      if (localStorage.getItem("flexibleAttendance")) {
        let dataLocal = JSON.parse(localStorage.getItem("flexibleAttendance"));
        return dataLocal[key] ? dataLocal[key].value : "";
      }
    }
  }, []);

  // -----------------------------------------------------
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const fixedInput = [
    {
      name: "Mon",
      label: "Monday",
      id: "fixedMon",
      isWoSh: "isWoShMon",
      value: "Monday",
    },
    {
      name: "Tue",
      label: "Tuesday",
      id: "fixedTue",
      isWoSh: "isWoShTue",
      value: "Tuesday",
    },
    {
      name: "Wed",
      label: "Wednesday",
      id: "fixedWed",
      isWoSh: "isWoShWed",
      value: "Wednesday",
    },
    {
      name: "Thu",
      label: "Thursday",
      id: "fixedThu",
      isWoSh: "isWoShThu",
      value: "Thursday",
    },
    {
      name: "Fri",
      label: "Friday",
      id: "fixedFri",
      isWoSh: "isWoShFri",
      value: "Friday",
    },
    {
      name: "Sat",
      label: "Saturday",
      id: "fixedSat",
      isWoSh: "isWoShSat",
      value: "Saturday",
    },
    {
      name: "Sun",
      label: "Sunday",
      id: "fixedSun",
      isWoSh: "isWoShSun",
      value: "Sunday",
    },
  ];

  const handelSubmit = (e) => {
    e.preventDefault();
    let url = "admin/empAttendanceWorktype";
    let data = null;
    if (attendanceType == "fixed") {
      const fixedArr = [];

      if (fixedData.fixedMon) {
        fixedArr.push({
          timing: fixedData.fixedMon,
          day: "monday",
          weekoff: fixedData.isWoShMon == "wo" ? 1 : 0,
          shift: fixedData.isWoShMon == "sf" ? 1 : 0,
        });
      }
      if (fixedData.fixedTue) {
        fixedArr.push({
          timing: fixedData.fixedTue,
          day: "tuesday",
          weekoff: fixedData.isWoShTue == "wo" ? 2 : 0,
          shift: fixedData.isWoShTue == "sf" ? 2 : 0,
        });
      }
      if (fixedData.fixedWed) {
        fixedArr.push({
          timing: fixedData.fixedWed,
          day: "wednesday",
          weekoff: fixedData.isWoShWed == "wo" ? 3 : 0,
          shift: fixedData.isWoShWed == "sf" ? 3 : 0,
        });
      }
      if (fixedData.fixedThu) {
        fixedArr.push({
          timing: fixedData.fixedThu,
          day: "thursday",
          weekoff: fixedData.isWoShThu == "wo" ? 4 : 0,
          shift: fixedData.isWoShThu == "sf" ? 4 : 0,
        });
      }
      if (fixedData.fixedFri) {
        fixedArr.push({
          timing: fixedData.fixedFri,
          day: "friday",
          weekoff: fixedData.isWoShFri == "wo" ? 5 : 0,
          shift: fixedData.isWoShFri == "sf" ? 5 : 0,
        });
      }
      if (fixedData.fixedSat) {
        fixedArr.push({
          timing: fixedData.fixedSat,
          day: "saturday",
          weekoff: fixedData.isWoShSat == "wo" ? 6 : 0,
          shift: fixedData.isWoShSat == "sf" ? 6 : 0,
        });
      }
      if (fixedData.fixedTSun) {
        fixedArr.push({
          timing: fixedData.fixedTSun,
          day: "sunday",
          weekoff: fixedData.isWoShTSun == "wo" ? 7 : 0,
          shift: fixedData.isWoShTSun == "sf" ? 7 : 0,
        });
      }
      let dd = {
        path: url,
        data: {
          userId: user.user_id,
          staffId: userDetails[0].staffId,
          worktype: 1,
          workshift: fixedArr,
        },
      };

      dispatch(commonSaveData(dd));
    } else {
      let dd = {
        path: url,
        data: {
          userId: user.user_id,
          staffId: userDetails[0].staffId,
          worktype: 2,
          month: flexibleMonth,
          year: flexibleYear,
          workshift: flexibleAtt.flexible,
        },
      };
      dispatch(commonSaveData(dd));
    }
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initState());
    }
  }, [commonState.commonSuccess]);

  const handleFixedChange = () => {
    setAttendanceType("fixed");
    localStorage.removeItem("flexibleAttendance");
    setFlexibleAtt({ ...flexibleAtt, flexible: [] });
    setFlexibleIdArr([]);
    handleCloseConfirm();
  };
  const handleFlexChange = () => {
    setFixedData({
      ...fixedData,
      isWoShMon: "",
      fixedMon: "",

      isWoShTue: "",
      fixedTue: "",

      isWoShWed: "",
      fixedWed: "",

      isWoShThu: "",
      fixedThu: "",

      isWoShFri: "",
      fixedFri: "",

      isWoShSat: "",
      fixedSat: "",

      isWoShSun: "",
      fixedSun: "",
    });
    setAttendanceType("flexible");
    handleCloseConfirm();
  };
  const cancelChange = () => {
    if (preAttType == "fixed") {
      setAttendanceType("flexible");
    } else {
      setAttendanceType("fixed");
    }
    handleCloseConfirm();
  };
  return (
    <div className="page-body" style={{ margin: "0 auto" }}>
      <Dialog
        fullWidth={true}
        open={confirm}
        onClose={handleCloseConfirm}
        style={{ width: "30%", margin: "0 auto" }}
      >
        <DialogContent
          style={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          You will loss your previous data if you change attendance type.
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid lightgray" }}>
          <Button
            variant="contained"
            color="gray"
            onClick={cancelChange}
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              height: "38px",
              backgroundColor: "gray",
              color: "white",
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="info"
            onClick={
              preAttType == "flexible" ? handleFlexChange : handleFixedChange
            }
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              height: "38px",
              // backgroundColor: "gray",
              color: "white",
            }}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div
        style={{
          position: "relative",
          top: "-148px",
          zIndex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Update Details"
          url_or_onclick={handelSubmit}
          width={""}
        />
      </div>
      <Form>
        <Form.Group className="mt-3">
          <div className="flex-justify-center">
            <Form.Label>Select Attendance Type</Form.Label>&nbsp;
            <Form.Check
              type="radio"
              name="attendanceType"
              value={"fixed"}
              label="Fixed"
              required
              checked={attendanceType == "fixed" ? true : false}
              // style={{
              //   padding: "8px 30px",
              //   border: "1px solid lightgray",
              //   borderRadius: "7px",
              // }}
              onChange={handleType}
            />
            <Form.Check
              type="radio"
              name="attendanceType"
              value={"flexible"}
              label="Flexible"
              onChange={handleType}
              checked={attendanceType == "flexible" ? true : false}
              // style={{
              //   padding: "8px 18px 12px 30px",
              //   border: "1px solid lightgray",
              //   borderRadius: "7px",
              // }}
              required
            />
          </div>
          <Form.Control.Feedback type="invalid">
            Please select a valid type.
          </Form.Control.Feedback>
        </Form.Group>
        <Row
          className="mt-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col sm="8">
            {attendanceType == "fixed" && (
              <table>
                <thead style={{ borderBottom: "1px solid lightgray" }}>
                  <tr>
                    <th>Day</th>
                    <th>Weekoff</th>
                    <th>Shift</th>
                  </tr>
                </thead>
                <tbody>
                  {fixedInput.map((fv, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {fv.name} <span style={{ color: "red" }}>*</span>{" "}
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Check
                              name="monCheck"
                              id={fv.id}
                              onChange={(e) =>
                                handleFixedCheck(e, fv.id, fv.isWoSh, fv.value)
                              }
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              // required = {attendanceType == "fixed" ? true : false}
                              name={fv.name}
                              placeholder="Select valid shift"
                              value={fixedData[fv.id]}
                              onClick={() =>
                                handleFixedId(fv.id, fv.isWoSh, fv.value)
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Please select a valid shift.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {attendanceType == "flexible" && (
              <>
                <Form.Group>
                  <Form.Label>Select Month</Form.Label>
                  {/* <Form.Control
                    type="month"
                    name="flexibleDate"
                    onChange={handleDateChange}
                    min={maxMonth}
                    style={{ colorScheme: "red" }}
                  /> */}

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DemoItem>
                        <DateTimePicker
                          views={["month", "year"]}
                          viewRenderers={{
                            hours: null,
                            minutes: null,
                            seconds: null,
                            date: null,
                          }}
                          openTo="month"
                          onChange={(newValue) => handleDateChange(newValue)}
                          minDate={maxMonth}
                          required
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                  <Form.Control.Feedback type="invalid">
                    Please select a valid month.
                  </Form.Control.Feedback>
                </Form.Group>
                <table>
                  <thead style={{ borderBottom: "1px solid lightgray" }}>
                    <tr>
                      <th>Day</th>
                      <th>Weekoff</th>
                      <th>Shift</th>
                    </tr>
                  </thead>
                  <tbody>
                    {flexibleDates.length
                      ? flexibleDates.map((row, idx) => {
                          return (
                            <tr>
                              <td>
                                {row.month + "" + row.date + " | " + row.day}
                              </td>
                              <td>
                                <Form.Group>
                                  <Form.Check
                                    name="monCheck"
                                    onChange={(e) =>
                                      handleFlexibleCheck(
                                        e,
                                        `flexible${idx}`,
                                        `${idx}`,
                                        row.date,
                                        row.day
                                      )
                                    }
                                    id={`check${idx}`}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Form.Control
                                    // required = {attendanceType == "flexible" ? true : false}
                                    name={`flexible${idx}`}
                                    placeholder="Select valid shift"
                                    // value={getFlexVal(`flexible${idx}`)}
                                    onClick={() =>
                                      handleFlexible(
                                        `flexible${idx}`,
                                        row.date,
                                        row.day
                                      )
                                    }
                                    id={`flexible${idx}`}
                                  />

                                  {flexibleIdArr.includes(`flexible${idx}`) && (
                                    <>
                                      {" "}
                                      <PiMinusSquare
                                        style={{
                                          fontSize: "23px",
                                          position: "absolute",
                                          color: "#cb4335",
                                          top: "7px",
                                          right: "40px",
                                        }}
                                        onClick={() =>
                                          handleRemoveFlexibleIcon(
                                            `flexible${idx}`,
                                            idx
                                          )
                                        }
                                      />
                                      <PiPlusSquare
                                        style={{
                                          fontSize: "25px",
                                          color: "#1e8449",
                                        }}
                                        onClick={() =>
                                          handleFlexible(
                                            `flexible${idx}`,
                                            row.date,
                                            row.day
                                          )
                                        }
                                      />
                                    </>
                                  )}

                                  <Form.Control.Feedback type="invalid">
                                    Please select a valid shift.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </>
            )}
          </Col>
        </Row>
        <Dialog
          fullWidth={true}
          open={open}
          onClose={handleClose}
          style={{ width: "30%", margin: "0 auto" }}
        >
          <DialogTitle
            style={{
              borderBottom: "1px solid lightgray",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Select shift
          </DialogTitle>
          <DialogContent>
            {attendanceType == "fixed" && (
              <>
                <Form.Group className="pt-4">
                  {shiftData.length
                    ? shiftData.map((r, i) => {
                        return (
                          <Form.Check
                            type="radio"
                            name="shift"
                            value={
                              r.shift_start_time + " - " + r.shift_end_time
                            }
                            required
                            onChange={() =>
                              handleFixed(
                                r.shift_start_time + " - " + r.shift_end_time
                              )
                            }
                            label={
                              r.shift_start_time + " - " + r.shift_end_time
                            }
                            className="mb-2"
                            // checked={
                            //   isExistFlexibleAttendance(
                            //     flexibleId,
                            //     r.shift_start_time + " - " + r.shift_end_time
                            //   )
                            //     ? "checked"
                            //     : null
                            // }
                          />
                        );
                      })
                    : null}
                </Form.Group>

                {errorVal && (
                  <Form.Label style={{ color: "red" }}>
                    Please select an option first
                  </Form.Label>
                )}
              </>
            )}
            {attendanceType == "flexible" && (
              <>
                <Form.Group className="pt-4">
                  {shiftData.length
                    ? shiftData.map((r, i) => {
                        return (
                          <Form.Check
                            type="radio"
                            name="shift"
                            value={
                              r.shift_start_time + " - " + r.shift_end_time
                            }
                            required
                            onChange={() =>
                              handleShiftSelection(
                                r.shift_start_time + " - " + r.shift_end_time
                              )
                            }
                            label={
                              r.shift_start_time + " - " + r.shift_end_time
                            }
                            className="mb-2"
                            checked={
                              isExistFlexibleAttendance(
                                flexibleId,
                                r.shift_start_time + " - " + r.shift_end_time
                              )
                                ? "checked"
                                : null
                            }
                          />
                        );
                      })
                    : null}
                </Form.Group>

                {errorVal && (
                  <Form.Label style={{ color: "red" }}>
                    Please select an option first
                  </Form.Label>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions style={{ borderTop: "1px solid lightgray" }}>
            <Button
              variant="contained"
              color="gray"
              onClick={handleClose}
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                height: "38px",
                backgroundColor: "gray",
                color: "white",
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="info"
              onClick={
                attendanceType == "flexible"
                  ? handleSelectOption
                  : handleFixedOption
              }
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                height: "38px",
                // backgroundColor: "gray",
                color: "white",
              }}
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    </div>
  );
};
//AttendanceModes

const AttendanceModes = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const [userDetails, setUserDetails] = useState([]);
  const commonState = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const [attendanceMode, setAttendanceMode] = useState({
    userId: user.user_id,
    staffId: userDetails[0].staffId,
    allow_punchin: false,
    selfie_attend: false,
    qr_attend: false,
    gps_attend: false,
    attend_from_location: false,
  });

  const handleModeChange = (e) => {
    const {
      target: { name, checked },
    } = e;
    setAttendanceMode({
      ...attendanceMode,
      [name]: checked,
    });
  };

  const handleFrom = (e) => {
    const {
      target: { name, value },
    } = e;
    setAttendanceMode({
      ...attendanceMode,
      [name]: value,
    });
  };

  const handleModSubmit = () => {
    let d = {
      path: "admin/empAttendanceModes",
      data: attendanceMode,
    };
    console.log(d);
    dispatch(commonSaveData(d));
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initState());
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      dispatch(initState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  return (
    <div className="AttendanceRules">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div
        style={{
          position: "relative",
          top: "-10px",
          zIndex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Update Details"
          url_or_onclick={handleModSubmit}
          width={""}
        />
      </div>
      <div className="AttendanceRules-tap">
        <p>All Punch in from staff App</p>
        <Switch
          name="allow_punchin"
          checked={attendanceMode.allow_punchin}
          onChange={handleModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>

      <div className="AttendanceRules-tap">
        <p>
          <CameraFrontIcon /> Selfie Attendance
        </p>
        <Switch
          name={"selfie_attend"}
          checked={attendanceMode.selfie_attend}
          onChange={handleModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      <div className="AttendanceRules-tap">
        <p>
          <QrCodeScannerIcon /> QR Attendance
        </p>
        <Switch
          name="qr_attend"
          checked={attendanceMode.qr_attend}
          onChange={handleModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>

      <div className="AttendanceRules-tap">
        <p>
          <ShareLocationIcon /> GPS Attendance
        </p>
        <Switch
          name={"gps_attend"}
          checked={attendanceMode.gps_attend}
          onChange={handleModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>

      <div className="AttendanceRules-tap">
        <b>
          Mark Attendance form
          <div className="MarkAttendanceform-radio-card">
            <div className="MarkAttendanceform-radio">
              <input
                name="attend_from_location"
                type="radio"
                value="1"
                onChange={handleFrom}
              />
              <label>From Office</label>
            </div>
            <div className="MarkAttendanceform-radio">
              <input
                name="attend_from_location"
                type="radio"
                value="0"
                onChange={handleFrom}
              />
              <label>From Outside</label>
            </div>
          </div>
        </b>
      </div>

      <div className="AttendanceRules-tap Biometric">
        <p>
          <FingerprintIcon /> Biometric Attendance
        </p>
        <samp id="Biometric_il">Manage biometric devices</samp>
      </div>
    </div>
  );
};

//AttendanceRules
const AttendanceRules = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const [userDetails, setUserDetails] = useState([]);
  const commonState = useSelector((state) => state.common);
  return (
    <div className="AttendanceRules">
      <div className="AttendanceRules-tap">
        <p>Auto Present at day start</p>
        <Switch
          //   checked={checked}
          //   onChange={handleModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>

      <div className="AttendanceRules-tap">
        <p>Present On Punch In</p>
        <Switch
          //   checked={checked}
          //   onChange={handleModeChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>

      <div className="AttendanceRules-tap">
        <p>Auto half day if late by</p>
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Not Set"
          url_or_onclick={() => {}}
          width={"50px"}
        />
      </div>
      <div className="AttendanceRules-tap">
        <p>Mandatory half day hours</p>
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Not Set"
          url_or_onclick={() => {}}
          width={"50px"}
        />
      </div>
      <div className="AttendanceRules-tap">
        <p>Mandatory full day hours</p>
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Not Set"
          url_or_onclick={() => {}}
          width={"50px"}
        />
      </div>
    </div>
  );
};

export default AttendanceDetails;
