import "./style.css";
import ButtonCom from "../../../components/button/button";
import verificationImg from "../../../assets/Images/balnk-page/verified.png";

const BackgroundVerification = () => {
  const handlePopUp = () => {};
  return (
    <div className="body-container">
      <div className="page-top-bar">
        <h1>Background Verification</h1>
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Add"
          url_or_onclick={handlePopUp}
          width={""}
        />
      </div>
      <div className="page-body">
        <div className="empty-content">
          <img src={verificationImg} alt="empty-page-icon" />
          <p>Background Verification</p>
        </div>
      </div>
    </div>
  );
};

export default BackgroundVerification;
