import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URI;
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};
const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
// const userId = user?.user_id;
const accountType = user?.account_type;

const initialState = {
  noAuthLoading: false,
  noAuthSuccess: false,
  noAuthError: false,
  noAuthMessage: null,
  noAuthData: null,

  noAuthGetLoading: false,
  noAuthGetSuccess: false,
  noAuthGetError: false,
  noAuthGetMessage: null,
  noAuthGetData: null,

  noAuthGet1Loading: false,
  noAuthGet1Success: false,
  noAuthGet1Error: false,
  noAuthGet1Message: null,
  noAuthGet1Data: null,

  noAuthGet2Loading: false,
  noAuthGet2Success: false,
  noAuthGet2Error: false,
  noAuthGet2Message: null,
  noAuthGet2Data: null,

  noAuthGet3Loading: false,
  noAuthGet3Success: false,
  noAuthGet3Error: false,
  noAuthGet3Message: null,
  noAuthGet3Data: null,

  noAuthGet4Loading: false,
  noAuthGet4Success: false,
  noAuthGet4Error: false,
  noAuthGet4Message: null,
  noAuthGet4Data: null,

  noAuthGet5Loading: false,
  noAuthGet5Success: false,
  noAuthGet5Error: false,
  noAuthGet5Message: null,
  noAuthGet5Data: null,

  noAuthGet6Loading: false,
  noAuthGet6Success: false,
  noAuthGet6Error: false,
  noAuthGet6Message: null,
  noAuthGet6Data: null,


  noAuthGet7Loading: false,
  noAuthGet7Success: false,
  noAuthGet7Error: false,
  noAuthGet7Message: null,
  noAuthGet7Data: null,


  noAuthGet8Loading: false,
  noAuthGet8Success: false,
  noAuthGet8Error: false,
  noAuthGet8Message: null,
  noAuthGet8Data: null,
};

export const noAuthSaveData = createAsyncThunk(
  "noAuthSaveData",
  async (body) => {
    const { path, data } = body;
    const url = baseUrl + path;

    const result = {
      data: {
        status: 200,
        message: "Sorry, you can only view the data, not create it.",
        data: [],
      },
    };
    return parseInt(accountType) === 2
      ? result
      : await axios
      .post(url, data, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
          data: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
        }
         return data
      } else {
        return error
      }
      });
  }
);

export const noAuthGet = createAsyncThunk("noAuthGet", async (path) => {
  return await axios
    .get(`${baseUrl}${path}`, {}, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
    });
});

export const noAuthGet1 = createAsyncThunk(
  "noAuthGet1",
  async (path) => {
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
      });
  }
);

export const noAuthGet2 = createAsyncThunk(
  "noAuthGet2",
  async (path) => {
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
      });
  }
);

export const noAuthGet3 = createAsyncThunk(
  "noAuthGet3",
  async (path) => {
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
      });
  }
);

export const noAuthGet4 = createAsyncThunk(
    "noAuthGet4",
    async (path) => {
      return await axios
        .get(`${baseUrl}${path}`, {}, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
        });
    }
  );

  export const noAuthGet5 = createAsyncThunk(
    "noAuthGet5",
    async (path) => {
      return await axios
        .get(`${baseUrl}${path}`, {}, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
        });
    }
  );

  export const noAuthGet6 = createAsyncThunk(
    "noAuthGet6",
    async (path) => {
      return await axios
        .get(`${baseUrl}${path}`, {}, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
        });
    }
  );

  export const noAuthGet7 = createAsyncThunk(
    "noAuthGet7",
    async (path) => {
      return await axios
        .get(`${baseUrl}${path}`, {}, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
        });
    }
  );

  export const noAuthGet8 = createAsyncThunk(
    "noAuthGet8",
    async (path) => {
      return await axios
        .get(`${baseUrl}${path}`, {}, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
        let data = {
          response: {
            data : [],
            message: "No Internet Connection Found",
            success: false
          },
        }
         return data
      } else {
        return error
      }
        });
    }
  );

const noAuthSlice = createSlice({
  name: "noAuth",
  initialState,

  reducers: {
    noAuthInitState: (state) => {
      state.noAuthLoading = false;
      state.noAuthSuccess = false;
      state.noAuthError = false;
      state.noAuthMessage = null;
      state.noAuthData = null;

      state.noAuthGetLoading = false;
      state.noAuthGetSuccess = false;
      state.noAuthGetError = false;
      state.noAuthGetMessage = null;
      state.noAuthGetData = null;

      state.noAuthGet1Loading = false;
      state.noAuthGet1Success = false;
      state.noAuthGet1Error = false;
      state.noAuthGet1Message = null;
      state.noAuthGet1Data = null;

      state.noAuthGet2Loading = false;
      state.noAuthGet2Success = false;
      state.noAuthGet2Error = false;
      state.noAuthGet2Message = null;
      state.noAuthGet2Data = null;

      state.noAuthGet3Loading = false;
      state.noAuthGet3Success = false;
      state.noAuthGet3Error = false;
      state.noAuthGet3Message = null;
      state.noAuthGet3Data = null;

      state.noAuthGet4Loading = false;
      state.noAuthGet4Success = false;
      state.noAuthGet4Error = false;
      state.noAuthGet4Message = null;
      state.noAuthGet4Data = null;

      state.noAuthGet5Loading = false;
      state.noAuthGet5Success = false;
      state.noAuthGet5Error = false;
      state.noAuthGet5Message = null;
      state.noAuthGet5Data = null;

      state.noAuthGet6Loading = false;
      state.noAuthGet6Success = false;
      state.noAuthGet6Error = false;
      state.noAuthGet6Message = null;
      state.noAuthGet6Data = null;

      state.noAuthGet7Loading = false;
      state.noAuthGet7Success = false;
      state.noAuthGet7Error = false;
      state.noAuthGet7Message = null;
      state.noAuthGet7Data = null;

      state.noAuthGet8Loading = false;
      state.noAuthGet8Success = false;
      state.noAuthGet8Error = false;
      state.noAuthGet8Message = null;
      state.noAuthGet8Data = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(noAuthGet.pending, (state, action) => {
      state.noAuthGetLoading = true;
      state.noAuthGetSuccess = false;
      state.noAuthGetError = false;
      state.noAuthGetMessage = null;
      state.noAuthGetData = null;
    });
    builder.addCase(noAuthGet.fulfilled, (state, action) => {
      state.noAuthGetLoading = false;
      state.noAuthGetSuccess = true;
      state.noAuthGetError = false;
      state.noAuthGetMessage =
        action.payload.status == 200
          ? action.payload.data.message
          : action.payload.response.data.message;
      state.noAuthGetData =
        action.payload.status == 200
          ? action.payload.data.data
          : action.payload.response.data.data;
    });
    builder.addCase(noAuthGet.rejected, (state, action) => {
      state.noAuthGetLoading = false;
      state.noAuthGetSuccess = true;
      state.noAuthGetError = false;
      state.noAuthGetMessage = null;
      state.noAuthGetData = null;
    });
    // -----------------------------------------
    builder.addCase(noAuthGet1.pending, (state, action) => {
      state.noAuthGet1Loading = true;
      state.noAuthGet1Success = false;
      state.noAuthGet1Error = false;
      state.noAuthGet1Message = null;
      state.noAuthGet1Data = null;
    });
    builder.addCase(noAuthGet1.fulfilled, (state, action) => {
      state.noAuthGet1Loading = false;
      state.noAuthGet1Success = true;
      state.noAuthGet1Error = false;
      state.noAuthGet1Message = action.payload.data.message;
      state.noAuthGet1Data = action.payload.data.data;
    });
    builder.addCase(noAuthGet1.rejected, (state, action) => {
      state.noAuthGet1Loading = false;
      state.noAuthGet1Success = true;
      state.noAuthGet1Error = false;
      state.noAuthGet1Message = null;
      state.noAuthGet1Data = null;
    });
    // -----------------------------------------
    builder.addCase(noAuthGet2.pending, (state, action) => {
      state.noAuthGet2Loading = true;
      state.noAuthGet2Success = false;
      state.noAuthGet2Error = false;
      state.noAuthGet2Message = null;
      state.noAuthGet2Data = null;
    });
    builder.addCase(noAuthGet2.fulfilled, (state, action) => {
      state.noAuthGet2Loading = false;
      state.noAuthGet2Success = true;
      state.noAuthGet2Error = false;
      state.noAuthGet2Message = action.payload.data.message;
      state.noAuthGet2Data = action.payload.data.data;
    });
    builder.addCase(noAuthGet2.rejected, (state, action) => {
      state.noAuthGet2Loading = false;
      state.noAuthGet2Success = true;
      state.noAuthGet2Error = false;
      state.noAuthGet2Message = null;
      state.noAuthGet2Data = null;
    });

    // -----------------------------------------
    builder.addCase(noAuthGet3.pending, (state, action) => {
      state.noAuthGet3Loading = true;
      state.noAuthGet3Success = false;
      state.noAuthGet3Error = false;
      state.noAuthGet3Message = null;
      state.noAuthGet3Data = null;
    });
    builder.addCase(noAuthGet3.fulfilled, (state, action) => {
      state.noAuthGet3Loading = false;
      state.noAuthGet3Success = true;
      state.noAuthGet3Error = false;
      state.noAuthGet3Message = action.payload.data.message;
      state.noAuthGet3Data = action.payload.data.data;
    });
    builder.addCase(noAuthGet3.rejected, (state, action) => {
      state.noAuthGet3Loading = false;
      state.noAuthGet3Success = true;
      state.noAuthGet3Error = false;
      state.noAuthGet3Message = null;
      state.noAuthGet3Data = null;
    });

    // -----------------------------------------
    builder.addCase(noAuthGet4.pending, (state, action) => {
        state.noAuthGet4Loading = true;
        state.noAuthGet4Success = false;
        state.noAuthGet4Error = false;
        state.noAuthGet4Message = null;
        state.noAuthGet4Data = null;
      });
      builder.addCase(noAuthGet4.fulfilled, (state, action) => {
        state.noAuthGet4Loading = false;
        state.noAuthGet4Success = true;
        state.noAuthGet4Error = false;
        state.noAuthGet4Message = action.payload.data.message;
        state.noAuthGet4Data = action.payload.data.data;
      });
      builder.addCase(noAuthGet4.rejected, (state, action) => {
        state.noAuthGet4Loading = false;
        state.noAuthGet4Success = true;
        state.noAuthGet4Error = false;
        state.noAuthGet4Message = null;
        state.noAuthGet4Data = null;
      });

      // -----------------------------------------
    builder.addCase(noAuthGet5.pending, (state, action) => {
        state.noAuthGet5Loading = true;
        state.noAuthGet5Success = false;
        state.noAuthGet5Error = false;
        state.noAuthGet5Message = null;
        state.noAuthGet5Data = null;
      });
      builder.addCase(noAuthGet5.fulfilled, (state, action) => {
        state.noAuthGet5Loading = false;
        state.noAuthGet5Success = true;
        state.noAuthGet5Error = false;
        state.noAuthGet5Message = action.payload.data.message;
        state.noAuthGet5Data = action.payload.data.data;
      });
      builder.addCase(noAuthGet5.rejected, (state, action) => {
        state.noAuthGet5Loading = false;
        state.noAuthGet5Success = true;
        state.noAuthGet5Error = false;
        state.noAuthGet5Message = null;
        state.noAuthGet5Data = null;
      });

      // -----------------------------------------
    builder.addCase(noAuthGet6.pending, (state, action) => {
        state.noAuthGet6Loading = true;
        state.noAuthGet6Success = false;
        state.noAuthGet6Error = false;
        state.noAuthGet6Message = null;
        state.noAuthGet6Data = null;
      });
      builder.addCase(noAuthGet6.fulfilled, (state, action) => {
        state.noAuthGet6Loading = false;
        state.noAuthGet6Success = true;
        state.noAuthGet6Error = false;
        state.noAuthGet6Message = action.payload.data.message;
        state.noAuthGet6Data = action.payload.data.data;
      });
      builder.addCase(noAuthGet6.rejected, (state, action) => {
        state.noAuthGet6Loading = false;
        state.noAuthGet6Success = true;
        state.noAuthGet6Error = false;
        state.noAuthGet6Message = null;
        state.noAuthGet6Data = null;
      });

      // -----------------------------------------
    builder.addCase(noAuthGet7.pending, (state, action) => {
        state.noAuthGet7Loading = true;
        state.noAuthGet7Success = false;
        state.noAuthGet7Error = false;
        state.noAuthGet7Message = null;
        state.noAuthGet7Data = null;
      });
      builder.addCase(noAuthGet7.fulfilled, (state, action) => {
        state.noAuthGet7Loading = false;
        state.noAuthGet7Success = true;
        state.noAuthGet7Error = false;
        state.noAuthGet7Message = action.payload.data.message;
        state.noAuthGet7Data = action.payload.data.data;
      });
      builder.addCase(noAuthGet7.rejected, (state, action) => {
        state.noAuthGet7Loading = false;
        state.noAuthGet7Success = true;
        state.noAuthGet7Error = false;
        state.noAuthGet7Message = null;
        state.noAuthGet7Data = null;
      });

      // -----------------------------------------
    builder.addCase(noAuthGet8.pending, (state, action) => {
        state.noAuthGet8Loading = true;
        state.noAuthGet8Success = false;
        state.noAuthGet8Error = false;
        state.noAuthGet8Message = null;
        state.noAuthGet8Data = null;
      });
      builder.addCase(noAuthGet8.fulfilled, (state, action) => {
        state.noAuthGet8Loading = false;
        state.noAuthGet8Success = true;
        state.noAuthGet8Error = false;
        state.noAuthGet8Message = action.payload.data.message;
        state.noAuthGet8Data = action.payload.data.data;
      });
      builder.addCase(noAuthGet8.rejected, (state, action) => {
        state.noAuthGet8Loading = false;
        state.noAuthGet8Success = true;
        state.noAuthGet8Error = false;
        state.noAuthGet8Message = null;
        state.noAuthGet8Data = null;
      });

    // Save data
    builder.addCase(noAuthSaveData.pending, (state) => {
      state.noAuthLoading = true;
      state.noAuthSuccess = false;
      state.noAuthError = false;
      state.noAuthMessage = null;
      state.noAuthData = null;
    });
    builder.addCase(noAuthSaveData.fulfilled, (state, action) => {
      if (action.payload.status == 200) {
        console.log(action)
        state.noAuthLoading = false;
        state.noAuthSuccess = accountType == 2 ? false : true;
        state.noAuthError = accountType == 2 ? true : false;
        state.noAuthMessage = action.payload.data.message;
        state.noAuthData = action.payload.data.data;
      } else {
        console.log(action)
        state.noAuthLoading = false;
        state.noAuthSuccess = false;
        state.noAuthError = true;
        state.noAuthMessage =  accountType == 2 ? action.payload.data.message : action.payload.response.data.message;
        state.noAuthData = null;
      }
    });
    builder.addCase(noAuthSaveData.rejected, (state) => {
      state.noAuthLoading = false;
      state.noAuthSuccess = false;
      state.noAuthError = true;
      state.noAuthMessage = null;
      state.noAuthData = null;
    });
  },
});

export const { noAuthInitState } = noAuthSlice.actions;

export default noAuthSlice.reducer;
