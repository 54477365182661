import "./LadingPageCardFour.css";
import bulding from "../../../assets/icons/bulding.png";
import factory from "../../../assets/icons/factory.png";
import graduation from "../../../assets/icons/graduation-cap.png";
import logistics from "../../../assets/icons/logistics.png";

const LadingPageCardFour = () => {
  return (
    <div className="LadingPageCardFour">
      <div className="heading">
        <h1>Empowering Success Across Industries</h1>
        <p>
          HCM solutions, tailored for diverse industries, fostering a culture of
          continuous growth
        </p>
      </div>
      <div className="LadingPageCardFour-body">
        <div className="LadingPageCardFour-box">
          <div className="LadingPageCardFour-box-img">
            <img src={bulding} alt="icon" />
          </div>

          <h1>IT & SaaS</h1>
          <p>
            In the tech realm, our HCM system pioneers the way, enhancing
            recruitment, skill development, and employee engagement for a
            thriving, innovative workforce.
          </p>
        </div>
        <div className="LadingPageCardFour-box">
          <div className="LadingPageCardFour-box-img">
            <img src={factory} alt="icon" />
          </div>

          <h1>Manufacturing</h1>
          <p>
            Propel manufacturing into the future with our HCM technology,
            synchronizing workforce planning and performance to meet production
            demands with precision.
          </p>
        </div>
        <div className="LadingPageCardFour-box">
          <div className="LadingPageCardFour-box-img">
            <img src={graduation} alt="icon" />
          </div>

          <h1>Education</h1>
          <p>
            Transform education HR practices with our visionary HCM, promoting
            staff development and student success through streamlined
            administrative processes.
          </p>
        </div>

        <div className="LadingPageCardFour-box">
          <div className="LadingPageCardFour-box-img">
            <img src={logistics} alt="icon" />
          </div>

          <h1>Logistics</h1>
          <p>
            Navigate supply chain complexities effortlessly with our advanced
            HCM system, harmonizing workforce planning & performance for
            unmatched excellence.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LadingPageCardFour;
