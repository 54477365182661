import "./Shifts.css";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import ButtonCom from "../../../components/button/button";
// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { RxCrossCircled } from "react-icons/rx";
import { CiEdit } from "react-icons/ci";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { MdOutlineDeleteSweep } from "react-icons/md";
// Library
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import makeAnimated from "react-select/animated";

import { useDispatch, useSelector } from "react-redux";
import {
  saveShift,
  fetchData,
  initState,
} from "../../../redux/slices/shiftsSlice";
import {
  commonGetData,
  commonSaveData,
  initCommonState,
  commonGetDataFourth,
} from "../../../redux/slices/commonSlice";

import { getDayName } from "./function";
const animatedComponents = makeAnimated();
const Shifts = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const state = useSelector((state) => state.shift);
  const commonState = useSelector((state) => state.common);

  // --------------------------------------------
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  // Assign employee to shift variables and functions
  const [shiftId, setShiftId] = useState("");
  const [assignedEmp, setAssignedEmp] = useState([]);

  const [show1, setShow1] = useState(false);

  const handleModalClose1 = () => {
    setShow1(false);
    setSelectedUsers([]);
  };
  const handleShow1 = () => setShow1(true);

  // User list Variable and functions
  const [employeeList, setEmployeeList] = useState([]);
  const [filterEmployeeList, setFilterEmployeeList] = useState([]);

  const getEmployeeList = useCallback((id) => {
    const path = `admin/getStaffList?userId=${user.user_id}&shiftId=${id}`;
    dispatch(commonGetDataFourth(path));
  }, []);

  useEffect(() => {
    if (commonState.commonGetFourthSuccess) {
      console.log(commonState.commonGetFourthData);
      setEmployeeList(commonState.commonGetFourthData);
      setFilterEmployeeList(commonState.commonGetFourthData);
      dispatch(initCommonState());
    }
  }, [commonState.commonGetFourthSuccess]);

  const handleAssignId = (id) => {
    setShiftId(id);
    getEmployeeList(id);
  };

  // Add Shifts functions and variables
  const [shiftList, setShiftList] = useState([]);
  const [shiftData, setShiftData] = useState({
    userId: user.user_id,
    shiftId: null,
    shiftName: "",
    shiftType: null,
  });

  const [fixedData, setFixedData] = useState([
    {
      sun: 1,
      mon: 1,
      tue: 1,
      wed: 1,
      thi: 1,
      fri: 1,
      sat: 1,
      shiftStartTime: "",
      shiftEndTime: "",
      breakDuration: "",
      weekOff: "",
      fromAmPm: "AM",
      toAmPm: "AM",
      weekOffDays: [],
    },
  ]);

  const [flexibleData, setFlexibleData] = useState([
    {
      sun: 1,
      mon: 1,
      tue: 1,
      wed: 1,
      thi: 1,
      fri: 1,
      sat: 1,
      grossHours: "",
      breakDuration: "",
      weekOff: "",
      weekOffDays: [],
    },
  ]);

  // GET DATA FROM DATABASE USING API
  const getShiftList = useCallback(() => {
    dispatch(fetchData(shiftData.userId));
  }, []);

  useEffect(() => {
    getShiftList();
  }, []);

  useEffect(() => {
    if (state.shiftGetSuccess) {
      setShiftList(state.shiftGetData);
    }
  }, [state.shiftGetSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  // function for handle to close he popup dialogue box
  const handleClose = () => {
    setShiftData({
      ...shiftData,
      shiftName: "",
      shiftType: null,
    });
    setFixedData([
      {
        sun: 1,
        mon: 1,
        tue: 1,
        wed: 1,
        thi: 1,
        fri: 1,
        sat: 1,
        shiftStartTime: "",
        shiftEndTime: "",
        breakDuration: "",
        weekOff: "",
        fromAmPm: "AM",
        toAmPm: "AM",
        weekOffDays: [],
      },
    ]);
    setFlexibleData([
      {
        sun: 1,
        mon: 1,
        tue: 1,
        wed: 1,
        thi: 1,
        fri: 1,
        sat: 1,
        grossHours: "",
        breakDuration: "",
        weekOff: "",
        weekOffDays: [],
      },
    ]);
    setValidated(false);
    setOpen(false);
    setTimeout(() => {
      setIsEdit(false);
    }, 500);
  };
  // function for handle to change the form field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShiftData({
      ...shiftData,
      [name]: value,
    });
  };
  // function for handle to change the fixed data form field
  const handleFixedChange = (e, index) => {
    const { name, value } = e.target;
    const newArr = [...fixedData];
    if (/^[0-9:]*$/.test(value)) {
      newArr[index][name] = value;
      setFixedData(newArr);
    }
  };
  // function for handle to change the fixed data form field like check box
  const handleCheckChange = (e, idx, type) => {
    const { checked } = e.target;
    if (type == "fixed") {
      const newArr = [...fixedData];
      newArr[idx].weekOff = checked ? 1 : 0;
      setFixedData(newArr);
      if (!checked) {
        newArr[idx].shiftStartTime = "";
        newArr[idx].shiftEndTime = "";
        newArr[idx].weekOff = "";
        newArr[idx].fromAmPm = "AM";
        newArr[idx].toAmPm = "AM";
        newArr[idx].weekOffDays = [];
      }
    }
    if (type == "flexible") {
      const newArr = [...flexibleData];
      newArr[idx].weekOff = checked ? 1 : 0;
      setFlexibleData(newArr);
      if (!checked) {
        newArr[idx].grossHours = "";
        newArr[idx].weekOff = "";
        newArr[idx].weekOffDays = [];
      }
    }
  };

  const handleShiftType = (val) => {
    setValidated(false);
    setShiftData({
      ...shiftData,
      shiftType: val,
    });
    if (val == 1) {
      setFlexibleData([
        {
          sun: 1,
          mon: 1,
          tue: 1,
          wed: 1,
          thi: 1,
          fri: 1,
          sat: 1,
          grossHours: "",
          breakDuration: "",
          weekOff: "",
          weekOffDays: [],
        },
      ]);
    }
    if (val == 2) {
      setFixedData([
        {
          sun: 1,
          mon: 1,
          tue: 1,
          wed: 1,
          thi: 1,
          fri: 1,
          sat: 1,
          shiftStartTime: "",
          shiftEndTime: "",
          breakDuration: "",
          weekOff: "",
          fromAmPm: "AM",
          toAmPm: "AM",
          weekOffDays: [],
        },
      ]);
    }
  };
  // Fixed shift functions
  const selectDays = (name) => {
    const newArr = [...fixedData];
    newArr[0][name] = newArr[0][name] == 1 ? 0 : 1;
    setFixedData(newArr);
  };

  const changeAmPm = (val, type) => {
    const newArr = [...fixedData];
    if (type == "from") {
      newArr[val].fromAmPm = newArr[val].fromAmPm == "AM" ? "PM" : "AM";
    }
    if (type == "to") {
      newArr[val].toAmPm = newArr[val].toAmPm == "AM" ? "PM" : "AM";
    }

    setFixedData(newArr);
  };

  const addFixedDataFields = (val) => {
    const newArr = [...fixedData];

    const index = newArr.findIndex((item) => item.id === val);

    if (index !== -1) {
      newArr.splice(index, 1);
    } else {
      newArr.push({
        id: val,
        days: val,
        shiftStartTime: "",
        shiftEndTime: "",
        breakDuration: "",
        weekOff: "",
        fromAmPm: "AM",
        toAmPm: "AM",
        weekOffDays: [],
      });
    }

    setFixedData(newArr);
  };

  // Flexible shift functions =================
  const selectFlexibleDays = (name) => {
    const newArr = [...flexibleData];
    newArr[0][name] = newArr[0][name] == 1 ? 0 : 1;
    setFlexibleData(newArr);
  };

  const handleFlexibleChange = (e, index) => {
    const { name, value } = e.target;
    const newArr = [...flexibleData];

    if (/^[0-9:]*$/.test(value)) {
      newArr[index][name] = value;
      setFlexibleData(newArr);
    }
  };

  const changeFlexibleAmPm = (val) => {};

  const addFlexibleDataFields = (val) => {
    const newArr = [...flexibleData];
    const index = newArr.findIndex((item) => item.id === val);

    if (index !== -1) {
      newArr.splice(index, 1);
    } else {
      newArr.push({
        id: val,
        days: val,
        grossHours: "",
        breakDuration: "",
        weekOff: "",
        weekOffDays: [],
      });
    }
    setFlexibleData(newArr);
  };

  const [selectIs, setSelectIs] = useState(true);

  const handleWeekOffDaysChange = (e, index, type) => {
    setSelectIs(true);
    if (type == "fixed") {
      const newArr = [...fixedData];

      const existsByValue = e.some((obj) => obj.value === 5);
      if (existsByValue) {
        newArr[index].weekOffDays = e.filter((obj) => obj.value === 5);
      } else {
        newArr[index].weekOffDays = e;
      }
      setFixedData(newArr);
    }
    if (type == "flexible") {
      const newArr = [...flexibleData];

      const existsByValue = e.some((obj) => obj.value === 5);
      if (existsByValue) {
        newArr[index].weekOffDays = e.filter((obj) => obj.value === 5);
      } else {
        newArr[index].weekOffDays = e;
      }
      setFlexibleData(newArr);
    }
  };

  const weekOffDays = [
    { label: "1st ", value: 1 },
    { label: "2nd ", value: 2 },
    { label: "3rd ", value: 3 },
    { label: "Last ", value: 4 },
    { label: "All ", value: 5 },
  ];
  const [isEdit, setIsEdit] = useState(false);
  // ==========================================
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      setSelectIs(false);
    } else {
      const fixedArr = [];
      const flexArr = [];
      setSelectIs(true);
      fixedData.map((row, idx) => {
        if (idx == 0) {
          const d = [];
          row.sun == 1 ? d.push(7) : null;
          row.mon == 1 ? d.push(1) : null;
          row.tue == 1 ? d.push(2) : null;
          row.wed == 1 ? d.push(3) : null;
          row.thi == 1 ? d.push(4) : null;
          row.fri == 1 ? d.push(5) : null;
          row.sat == 1 ? d.push(6) : null;

          let fixedObj = {
            days: d.toString(),
            shiftStartTime: row.shiftStartTime + " " + row.fromAmPm,
            shiftEndTime: row.shiftEndTime + " " + row.toAmPm,
            breakDuration: row.breakDuration,
            weekOff: row.weekOff,
          };
          fixedArr.push(fixedObj);
        } else {
          let fixedObj = {
            days: row.days.toString(),
            shiftStartTime: row.shiftStartTime + " " + row.fromAmPm,
            shiftEndTime: row.shiftEndTime + " " + row.toAmPm,
            breakDuration: row.breakDuration,
            weekOff: row.weekOff,
            weekOffDays: row.weekOffDays
              .map((val) => {
                return val.value;
              })
              .toString(),
          };
          fixedArr.push(fixedObj);
        }
      });

      flexibleData.map((row, idx) => {
        if (idx == 0) {
          const d = [];
          row.sun == 1 ? d.push(7) : null;
          row.mon == 1 ? d.push(1) : null;
          row.tue == 1 ? d.push(2) : null;
          row.wed == 1 ? d.push(3) : null;
          row.thi == 1 ? d.push(4) : null;
          row.fri == 1 ? d.push(5) : null;
          row.sat == 1 ? d.push(6) : null;

          let flexObj = {
            days: d.toString(),
            grossHours: row.grossHours + " Hours",
            breakDuration: row.breakDuration + "  Mins",
            weekOff: row.weekOff,
            weekOffDays: "",
          };
          flexArr.push(flexObj);
        } else {
          let flexObj = {
            days: row.days.toString(),
            grossHours: row.grossHours + " Hours",
            breakDuration: row.breakDuration + "  Mins",
            weekOff: row.weekOff,
            weekOffDays: row.weekOffDays
              .map((val) => {
                return val.value;
              })
              .toString(),
          };
          flexArr.push(flexObj);
        }
      });

      const fData = {
        shiftId: shiftData.shiftId,
        userId: shiftData.userId,
        shiftName: shiftData.shiftName,
        shiftType: shiftData.shiftType,
        shiftData: fixedArr,
      };

      const fleData = {
        shiftId: shiftData.shiftId,
        userId: shiftData.userId,
        shiftName: shiftData.shiftName,
        shiftType: shiftData.shiftType,
        shiftData: flexArr,
      };

      let data = shiftData.shiftType == 1 ? fData : fleData;
      if (isEdit) {
        let d = {
          path: "admin/updateShift",
          data,
        };
        dispatch(commonSaveData(d));
      } else {
        dispatch(saveShift(data));
      }
    }
  };

  useEffect(() => {
    if (state.shiftSuccess) {
      handleClose();
      toast.success(state.shiftMessage);
      dispatch(initState());
      setShiftData({
        ...shiftData,
        shiftName: "",
        shiftType: null,
      });
      setFixedData([
        {
          sun: 1,
          mon: 1,
          tue: 1,
          wed: 1,
          thi: 1,
          fri: 1,
          sat: 1,
          shiftStartTime: "",
          shiftEndTime: "",
          breakDuration: "",
          weekOff: "",
          fromAmPm: "AM",
          toAmPm: "AM",
          weekOffDays: [],
        },
      ]);
      setFlexibleData([
        {
          sun: 1,
          mon: 1,
          tue: 1,
          wed: 1,
          thi: 1,
          fri: 1,
          sat: 1,
          grossHours: "",
          breakDuration: "",
          weekOff: "",
          weekOffDays: [],
        },
      ]);
      getShiftList();
    }
    if (state.shiftError) {
      // handleClose();
      dispatch(initState());
      // setShiftData({
      //   ...shiftData,
      //   shiftName: "",
      // });
      toast.error(state.shiftMessage);
    }
  }, [state.shiftSuccess, state.shiftError]);

  // Edit functions ======================================
  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = (row) => {
    setIsEdit(true);

    setShiftData({
      ...shiftData,
      shiftId: row.shiftId,
      shiftName: row.shiftName,
      shiftType: row.shiftType,
    });

    if (row.shiftType == 1) {
      const arr = [];
      row.details.map((v, idx) => {
        if (idx == 0) {
          const daysArr = v.days.split(",");
          let fl = {
            sun: daysArr.includes("7") ? 1 : 0,
            mon: daysArr.includes("1") ? 1 : 0,
            tue: daysArr.includes("2") ? 1 : 0,
            wed: daysArr.includes("3") ? 1 : 0,
            thi: daysArr.includes("4") ? 1 : 0,
            fri: daysArr.includes("5") ? 1 : 0,
            sat: daysArr.includes("6") ? 1 : 0,
            shiftStartTime:
              v.shiftStartTime != ""
                ? v.shiftStartTime.replace(/[^0-9:]/g, "")
                : "",
            fromAmPm:
              v.shiftStartTime != "" ? v.shiftStartTime.match(/AM|PM/)[0] : "",
            shiftEndTime:
              v.shiftEndTime != ""
                ? v.shiftEndTime.replace(/[^0-9:]/g, "")
                : "",
            toAmPm:
              v.shiftEndTime != "" ? v.shiftEndTime.match(/AM|PM/)[0] : "",
            breakDuration:
              v.breakDuration != ""
                ? v.breakDuration.replace(/[^0-9:]/g, "")
                : "",
            weekOff: v.weekOff,
          };
          arr.push(fl);
        } else {
          const weekOffDays_ = [];
          if (v.weekOffDays != null) {
            v.weekOffDays.split(",").forEach((el) => {
              weekOffDays_.push({
                label:
                  el == 1
                    ? "1st"
                    : el == 2
                    ? "2nd"
                    : el == 3
                    ? "3rd"
                    : el == 4
                    ? "Las"
                    : "All",
                value: el,
              });
            });
          }

          let fl1 = {
            id: v.days,
            days: v.days,
            shiftStartTime:
              v.shiftStartTime != ""
                ? v.shiftStartTime.replace(/[^0-9:]/g, "")
                : "",
            fromAmPm:
              v.shiftStartTime != "" ? v.shiftStartTime.match(/AM|PM/)[0] : "",
            shiftEndTime:
              v.shiftEndTime != ""
                ? v.shiftEndTime.replace(/[^0-9:]/g, "")
                : "",
            toAmPm:
              v.shiftEndTime != "" ? v.shiftEndTime.match(/AM|PM/)[0] : "",
            breakDuration:
              v.breakDuration != ""
                ? v.breakDuration.replace(/[^0-9:]/g, "")
                : "",
            weekOff: v.weekOff,
            weekOffDays: weekOffDays_,
          };
          arr.push(fl1);
        }
      });

      setFixedData(arr);
    } else {
      const arr = [];
      row.details.map((v, idx) => {
        if (idx == 0) {
          const daysArr = v.days.split(",");
          let fl = {
            sun: daysArr.includes("7") ? 1 : 0,
            mon: daysArr.includes("1") ? 1 : 0,
            tue: daysArr.includes("2") ? 1 : 0,
            wed: daysArr.includes("3") ? 1 : 0,
            thi: daysArr.includes("4") ? 1 : 0,
            fri: daysArr.includes("5") ? 1 : 0,
            sat: daysArr.includes("6") ? 1 : 0,
            grossHours:
              v.grossHours != "" ? v.grossHours.replace(/\D/g, "") : "",
            breakDuration:
              v.breakDuration != "" ? v.breakDuration.replace(/\D/g, "") : "",
            weekOff: v.weekOff,
          };
          arr.push(fl);
        } else {
          const weekOffDays_ = [];
          if (v.weekOffDays != null) {
            v.weekOffDays.split(",").forEach((el) => {
              weekOffDays_.push({
                label:
                  el == 1
                    ? "1st"
                    : el == 2
                    ? "2nd"
                    : el == 3
                    ? "3rd"
                    : el == 4
                    ? "Las"
                    : "All",
                value: el,
              });
            });
          }

          let fl1 = {
            id: v.days,
            days: v.days,
            grossHours:
              v.grossHours != "" ? v.grossHours.replace(/\D/g, "") : "",
            breakDuration:
              v.breakDuration != "" ? v.breakDuration.replace(/\D/g, "") : "",
            weekOff: v.weekOff,
            weekOffDays: weekOffDays_,
          };
          arr.push(fl1);
        }
      });

      setFlexibleData(arr);
    }

    handleClickOpen();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateShift",
      data: shiftData,
    };
    if (isEdit) {
      dispatch(commonSaveData(d));
    }
  };

  // Delete functions ================================
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.shiftId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteShift",
      data: {
        userId: user?.user_id,
        shiftId: delId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      getShiftList();
      setIsEdit(false);
      dispatch(initCommonState());
      handleModalClose1();

      setShiftData({
        ...shiftData,
        shiftId: "",
        shiftName: "",
      });
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      setOpen(false);
      getShiftList();
      dispatch(initCommonState());
      setShiftData({
        ...shiftData,
        shiftId: "",
        shiftName: "",
      });
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const columns = [
    {
      headerName: "Shift Name",
      field: "shiftName",
      flex: 1,
    },
    {
      headerName: "Shift Type",
      cellRenderer: (rowData) => {
        return rowData.data.shiftType == "1" ? "Fixed" : "Flexible";
      },
      flex: 1,
    },

    {
      headerName: "Shift Timing",
      autoHeight: true,
      cellRenderer: (rowData) => {
        return (
          <ul className="tbl-row-list">
            {rowData.data.details.map((row, idx) => {
              return <><li key={idx}>{row.shiftStartTime + " - " + row.shiftEndTime}</li></>;
            })}
          </ul>
        );
      },
      flex: 1,
    },

    {
      headerName: "Gross Hours",
      autoHeight: true,
      cellRenderer: (rowData) => {
        return (
          <ul className="tbl-row-list">
            {rowData.data.details.map((row, idx) => {
              return <><li key={idx}>{row.grossHours == null ? "-" : row.grossHours}</li></>;
            })}
          </ul>
        );
      },
      flex: 1,
    },

    {
      headerName: "Break Time (Mins)",
      autoHeight: true,
      cellRenderer: (rowData) => {
        return (
          <ul className="tbl-row-list">
            {rowData.data.details.map((row, idx) => {
              return <><li key={idx}>{row.breakDuration == null ? "-" : row.breakDuration}</li></>;
            })}
          </ul>
        );
      },
      flex: 1,
    },

    {
      headerName: "Actions",
      cellRenderer: (row) => {
        return (
          <div>
            <MdOutlineAssignmentTurnedIn
              style={{
                cursor: "pointer",
                fontSize: 25,
                color: "#58d68d",
                marginRight: "10px",
              }}
              onClick={() => {
                handleShow1();
                handleAssignId(row.data.shiftId);
              }}
            />
            <CiEdit
              style={{
                cursor: "pointer",
                fontSize: 30,
                color: "blue",
                marginRight: "10px",
              }}
              onClick={() => {
                handleEdit(row.data);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(row.data)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px", // Set a width for the action buttons
    },
  ];

  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  // Handle individual checkbox change
  const selectUser = (event, userId) => {
    const isChecked = event.target.checked;
    setSelectedUsers(
      (prevSelected) =>
        isChecked
          ? [...prevSelected, userId] // Add user to the list
          : prevSelected.filter((id) => id !== userId) // Remove user from the list
    );
  };

  // Handle "Select All" checkbox change
  const toggleSelectAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allUserIds = employeeList.map((row) => row.staffId); // Get all user IDs
      setSelectedUsers(allUserIds); // Select all users
    } else {
      setSelectedUsers([]); // Deselect all users
    }
  };

  // Determine if "Select All" should be checked
  const isSelectAll =
    selectedUsers.length === employeeList.length && employeeList.length > 0;

  // Filter employees based on the search query
  const searchEmployees = (val) => {
    setSearchQuery(val);
    const filteredEmployees = filterEmployeeList.filter((v) =>
      v.full_name.toLowerCase().includes(val.toLowerCase())
    );
    setEmployeeList(filteredEmployees);
  };

  const handleAssignShiftSubmit = (e) => {
    e.preventDefault();
    const data = {
      path: "admin/assignEmployeeShift",
      data: {
        userId: user.user_id,
        staffId: selectedUsers.toString(),
        shiftId: shiftId,
      },
    };
    dispatch(commonSaveData(data));
  };

  // End all func------------------------------------------------
  return (
    <div className="SettingBody">
      {/* Modal for assign userI */}
      <Modal show={show1} onHide={handleModalClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Employee to this Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
          <input
            className="search-employee"
            type="text"
            placeholder="Search employees..."
            value={searchQuery}
            onChange={(e) => searchEmployees(e.target.value)}
          />

          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={isSelectAll}
                    onChange={toggleSelectAll}
                    id="sel-all"
                    className="colored-checkbox"
                  />
                  &nbsp; &nbsp;
                  <label style={{ cursor: "pointer" }} htmlFor="sel-all">
                    Select All
                  </label>
                </th>
                <th>Employee Name</th>
              </tr>
            </thead>
            <tbody>
              {employeeList.length > 0 ? (
                employeeList.map((row) => (
                  <tr key={row.staffId}>
                    <td>
                      {/* {
                      row.shiftAssignedStatus == "1" ? <input
                        type="checkbox"
                        checked={selectedUsers.includes(row.staffId)}
                        onChange={(e) => selectUser(e, row.staffId)}
                        id={"shift" + row.staffId}
                      />
                    } */}
                      <input
                        type="checkbox"
                        checked={
                          row.shiftAssignedStatus == "1" ||
                          selectedUsers.includes(row.staffId)
                        }
                        onChange={(e) => selectUser(e, row.staffId)}
                        id={"shift" + row.staffId}
                        className="colored-checkbox"
                      />
                    </td>
                    <td>
                      <label
                        style={{ cursor: "pointer" }}
                        htmlFor={"shift" + row.staffId}
                      >
                        {" "}
                        {row.full_name}{" "}
                      </label>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No employees found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#566573",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose1}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#5dade2", color: "#fff" }}
            onClick={handleAssignShiftSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for delete shift record */}
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Shifts</p>
      </div>

      <div className="SettingBody-card mt-4" style={{ borderRadius: "7px" }}>
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="Branches-btn">
            <ButtonCom
              type={"button"}
              class_name={"p_btn"}
              text={"Add Shift"}
              ext
              url_or_onclick={handleClickOpen}
              icon={<AddIcon />}
              width={""}
            />
          </div>
          <div
            className="ag-theme-quartz" // applying the Data Grid theme
            style={{ height: "500px", width: "100%" }}
          >
            <AgGridReact
              rowData={shiftList.length > 0 ? shiftList : []}
              columnDefs={columns}
              omLayout="autoHeight"
              pagination={true}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
            />
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle style={{ fontSize: "18px", fontWeight: "bold" }}>
              {isEdit ? "Update Shift" : "Add New Shift"}
            </DialogTitle>
            <div style={{ overflow: "auto" }}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <DialogContent style={{ height: "400px" }}>
                  <Row>
                    <Col sm="4">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          Shift Name <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="shiftName"
                          value={shiftData.shiftName}
                          placeholder="Enter Shift Name"
                          onChange={handleChange}
                          required
                          readOnly={isEdit ? true : false}
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid shift name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* --------------------------------------------------- */}
                  </Row>
                  <Row className="mt-3">
                    <Form.Label>Select Shift Type </Form.Label>
                    <Col
                      sm={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          label="Fixed shift timing"
                          name="shiftType"
                          onChange={() => handleShiftType(1)}
                          value="1"
                          checked={shiftData.shiftType == 1 ? "checked" : null}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Check
                          type="radio"
                          label="Flexible work hours"
                          name="shiftType"
                          onChange={() => handleShiftType(2)}
                          value="2"
                          checked={shiftData.shiftType == 2 ? "checked" : null}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {shiftData.shiftType == 1 && (
                    <Row className="mt-3">
                      <Col sm="12">
                        {fixedData.map((row, idx) => {
                          return (
                            <div
                              className="shift-type-container mb-3"
                              key={idx}
                            >
                              <div className="days-container">
                                <label className="title">Days</label>
                                <div className="button-container">
                                  <button
                                    className={
                                      idx == 0
                                        ? row.sun == 1
                                          ? "active"
                                          : null
                                        : row.days == 7
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={
                                      idx == 0
                                        ? () => {
                                            selectDays("sun");
                                            addFixedDataFields(7);
                                          }
                                        : null
                                    }
                                  >
                                    S
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.mon == 1
                                          ? "active"
                                          : null
                                        : row.days == 1
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={
                                      idx == 0
                                        ? () => {
                                            selectDays("mon");
                                            addFixedDataFields(1);
                                          }
                                        : null
                                    }
                                  >
                                    M
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.tue == 1
                                          ? "active"
                                          : null
                                        : row.days == 2
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={
                                      idx == 0
                                        ? () => {
                                            selectDays("tue");
                                            addFixedDataFields(2);
                                          }
                                        : null
                                    }
                                  >
                                    T
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.wed == 1
                                          ? "active"
                                          : null
                                        : row.days == 3
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={
                                      idx == 0
                                        ? () => {
                                            selectDays("wed");
                                            addFixedDataFields(3);
                                          }
                                        : null
                                    }
                                  >
                                    W
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.thi == 1
                                          ? "active"
                                          : null
                                        : row.days == 4
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={
                                      idx == 0
                                        ? () => {
                                            selectDays("thi");
                                            addFixedDataFields(4);
                                          }
                                        : null
                                    }
                                  >
                                    T
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.fri == 1
                                          ? "active"
                                          : null
                                        : row.days == 5
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={
                                      idx == 0
                                        ? () => {
                                            selectDays("fri");
                                            addFixedDataFields(5);
                                          }
                                        : null
                                    }
                                  >
                                    F
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.sat == 1
                                          ? "active"
                                          : null
                                        : row.days == 6
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={
                                      idx == 0
                                        ? () => {
                                            selectDays("sat");
                                            addFixedDataFields(6);
                                          }
                                        : null
                                    }
                                  >
                                    S
                                  </button>
                                </div>
                              </div>
                              {/* ----------------------------------- */}
                              {idx != 0 && (
                                <div className="week-off-container">
                                  <label className="title">Week Off</label>
                                  <Form.Check
                                    name="weekOff"
                                    value={row.weekOff}
                                    checked={row.weekOff ? "checked" : null}
                                    onChange={(e) =>
                                      handleCheckChange(e, idx, "fixed")
                                    }
                                    className="week-off"
                                  />
                                </div>
                              )}
                              {/* Select Week off days ----------------------------------- */}
                              {idx != 0 && row.weekOff == 1 && (
                                <div className="break-duration-container">
                                  <label className="title">Week Off Days</label>
                                  <div className="input-container">
                                    <FormControl
                                      style={{
                                        width: "210px",
                                        border:
                                          row.weekOffDays.length == 0 &&
                                          !selectIs
                                            ? "1px solid red"
                                            : "",
                                        borderRadius:
                                          row.weekOffDays.length == 0 &&
                                          !selectIs
                                            ? "7px"
                                            : "",
                                      }}
                                    >
                                      <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        value={row.weekOffDays}
                                        name={"weekOffDays"}
                                        isMulti
                                        placeholder="Select week off days"
                                        options={weekOffDays}
                                        onChange={(e) =>
                                          handleWeekOffDaysChange(
                                            e,
                                            idx,
                                            "fixed"
                                          )
                                        }
                                        required
                                      />
                                    </FormControl>
                                    <span>
                                      {row.days == 1 && "Monday"}
                                      {row.days == 2 && "Tuesday"}
                                      {row.days == 3 && "Wednesday"}
                                      {row.days == 4 && "Thursday"}
                                      {row.days == 5 && "Friday"}
                                      {row.days == 6 && "Saturday"}
                                      {row.days == 7 && "Sunday"}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {/* Shift Timings ----------------------------------- */}
                              {idx == 0 && (
                                <>
                                  <div className="time-gross-container">
                                    <label className="title">
                                      Shift Timings
                                    </label>
                                    <div className="input-container">
                                      <InputGroup className="time-input">
                                        <Form.Control
                                          name="shiftStartTime"
                                          value={row.shiftStartTime}
                                          onChange={(e) =>
                                            handleFixedChange(e, idx)
                                          }
                                          placeholder="10:00"
                                          required
                                        />
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          onClick={() =>
                                            changeAmPm(idx, "from")
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#2980b9",
                                          }}
                                        >
                                          {row.fromAmPm}
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <label>-</label>
                                      <InputGroup className="time-input">
                                        <Form.Control
                                          name="shiftEndTime"
                                          value={row.shiftEndTime}
                                          onChange={(e) =>
                                            handleFixedChange(e, idx)
                                          }
                                          placeholder="07:00"
                                          required
                                        />
                                        <InputGroup.Text
                                          id="basic-addon2"
                                          onClick={() => changeAmPm(idx, "to")}
                                          style={{
                                            cursor: "pointer",
                                            color: "#2980b9",
                                          }}
                                        >
                                          {row.toAmPm}
                                        </InputGroup.Text>
                                      </InputGroup>
                                      {/* <label>(9 hrs 0 mins)</label> */}
                                    </div>
                                  </div>
                                  {/* Break duration */}
                                  <div className="break-duration-container">
                                    <label className="title">
                                      Break Duration
                                    </label>
                                    <div className="input-container">
                                      <InputGroup className="time-input">
                                        <Form.Control
                                          name="breakDuration"
                                          value={row.breakDuration}
                                          onChange={(e) =>
                                            handleFixedChange(e, idx)
                                          }
                                          placeholder="120"
                                          required
                                        />
                                        <InputGroup.Text
                                          id="basic-addon2"
                                          style={{ cursor: "pointer" }}
                                        >
                                          mins
                                        </InputGroup.Text>
                                      </InputGroup>
                                      {/* <label>(9 hrs 0 mins)</label> */}
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* Shift Timings ----------------------------------- */}
                              {idx != 0 &&
                                (row.weekOff == 0 || row.weekOff == "") && (
                                  <>
                                    <div className="time-gross-container">
                                      <label className="title">
                                        Shift Timings
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="shiftStartTime"
                                            value={row.shiftStartTime}
                                            onChange={(e) =>
                                              handleFixedChange(e, idx)
                                            }
                                            placeholder="10:00"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon1"
                                            onClick={() =>
                                              changeAmPm(idx, "from")
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#2980b9",
                                            }}
                                          >
                                            {row.fromAmPm}
                                          </InputGroup.Text>
                                        </InputGroup>
                                        <label>-</label>
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="shiftEndTime"
                                            value={row.shiftEndTime}
                                            onChange={(e) =>
                                              handleFixedChange(e, idx)
                                            }
                                            placeholder="07:00"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            onClick={() =>
                                              changeAmPm(idx, "to")
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#2980b9",
                                            }}
                                          >
                                            {row.toAmPm}
                                          </InputGroup.Text>
                                        </InputGroup>
                                      </div>
                                    </div>
                                    {/* Break duration */}
                                    <div className="break-duration-container">
                                      <label className="title">
                                        Break Duration
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="breakDuration"
                                            value={row.breakDuration}
                                            onChange={(e) =>
                                              handleFixedChange(e, idx)
                                            }
                                            placeholder="120"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            style={{ cursor: "pointer" }}
                                          >
                                            mins
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {/* <label>(9 hrs 0 mins)</label> */}
                                      </div>
                                    </div>
                                  </>
                                )}
                              {/* Shift Timings ----------------------------------- */}
                              {idx != 0 &&
                                row.weekOffDays?.some((obj) => obj.value != 5) >
                                  0 && (
                                  <>
                                    <div className="time-gross-container">
                                      <label className="title">
                                        Shift Timings
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="shiftStartTime"
                                            value={row.shiftStartTime}
                                            onChange={(e) =>
                                              handleFixedChange(e, idx)
                                            }
                                            placeholder="10:00"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon1"
                                            onClick={() =>
                                              changeAmPm(idx, "from")
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#2980b9",
                                            }}
                                          >
                                            {row.fromAmPm}
                                          </InputGroup.Text>
                                        </InputGroup>
                                        <label>-</label>
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="shiftEndTime"
                                            value={row.shiftEndTime}
                                            onChange={(e) =>
                                              handleFixedChange(e, idx)
                                            }
                                            placeholder="07:00"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            onClick={() =>
                                              changeAmPm(idx, "to")
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#2980b9",
                                            }}
                                          >
                                            {row.toAmPm}
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {/* <label>(9 hrs 0 mins)</label> */}
                                      </div>
                                    </div>
                                    {/* Break duration */}
                                    <div className="break-duration-container">
                                      <label className="title">
                                        Break Duration
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="breakDuration"
                                            value={row.breakDuration}
                                            onChange={(e) =>
                                              handleFixedChange(e, idx)
                                            }
                                            placeholder="120"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            style={{ cursor: "pointer" }}
                                          >
                                            mins
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {/* <label>(9 hrs 0 mins)</label> */}
                                      </div>
                                    </div>
                                  </>
                                )}
                              {/* Break Duration----------------------------------- */}
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  )}
                  {/* ======================================= */}
                  {shiftData.shiftType == 2 && (
                    <Row className="mt-3">
                      <Col sm="12">
                        {flexibleData.map((row, idx) => {
                          return (
                            <div
                              className="shift-type-container mb-3"
                              key={idx}
                            >
                              <div className="days-container">
                                <label className="title">Days</label>
                                <div className="button-container">
                                  <button
                                    className={
                                      idx == 0
                                        ? row.sun == 1
                                          ? "active"
                                          : null
                                        : row.days == 7
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={() => {
                                      selectFlexibleDays("sun");
                                      addFlexibleDataFields(7);
                                    }}
                                  >
                                    S
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.mon == 1
                                          ? "active"
                                          : null
                                        : row.days == 1
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={() => {
                                      selectFlexibleDays("mon");
                                      addFlexibleDataFields(1);
                                    }}
                                  >
                                    M
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.tue == 1
                                          ? "active"
                                          : null
                                        : row.days == 2
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={() => {
                                      selectFlexibleDays("tue");
                                      addFlexibleDataFields(2);
                                    }}
                                  >
                                    T
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.wed == 1
                                          ? "active"
                                          : null
                                        : row.days == 3
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={() => {
                                      selectFlexibleDays("wed");
                                      addFlexibleDataFields(3);
                                    }}
                                  >
                                    W
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.thi == 1
                                          ? "active"
                                          : null
                                        : row.days == 4
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={() => {
                                      selectFlexibleDays("thi");
                                      addFlexibleDataFields(4);
                                    }}
                                  >
                                    T
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.fri == 1
                                          ? "active"
                                          : null
                                        : row.days == 5
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={() => {
                                      selectFlexibleDays("fri");
                                      addFlexibleDataFields(5);
                                    }}
                                  >
                                    F
                                  </button>
                                  <button
                                    className={
                                      idx == 0
                                        ? row.sat == 1
                                          ? "active"
                                          : null
                                        : row.days == 6
                                        ? "active"
                                        : null
                                    }
                                    type="button"
                                    onClick={() => {
                                      selectFlexibleDays("sat");
                                      addFlexibleDataFields(6);
                                    }}
                                  >
                                    S
                                  </button>
                                </div>
                              </div>
                              {/* ----------------------------------- */}
                              {idx != 0 && (
                                <div className="week-off-container">
                                  <label className="title">Week Off</label>
                                  <Form.Check
                                    name="weekOff"
                                    value={row.weekOff}
                                    checked={row.weekOff ? "checked" : null}
                                    onChange={(e) =>
                                      handleCheckChange(e, idx, "flexible")
                                    }
                                  />
                                </div>
                              )}
                              {/* Select Week off days ----------------------------------- */}
                              {idx != 0 && row.weekOff == 1 && (
                                <div className="break-duration-container">
                                  <label className="title">Week Off Days</label>
                                  <div className="input-container">
                                    <FormControl
                                      style={{
                                        width: "210px",
                                        border:
                                          row.weekOffDays.length == 0 &&
                                          !selectIs
                                            ? "1px solid red"
                                            : "",
                                        borderRadius:
                                          row.weekOffDays.length == 0 &&
                                          !selectIs
                                            ? "7px"
                                            : "",
                                      }}
                                    >
                                      <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        value={row.weekOffDays}
                                        name={"weekOffDays"}
                                        isMulti
                                        placeholder="Select week off days"
                                        options={weekOffDays}
                                        onChange={(e) =>
                                          handleWeekOffDaysChange(
                                            e,
                                            idx,
                                            "flexible"
                                          )
                                        }
                                      />
                                    </FormControl>
                                    <span>
                                      {row.days == 1 && "Monday"}
                                      {row.days == 2 && "Tuesday"}
                                      {row.days == 3 && "Wednesday"}
                                      {row.days == 4 && "Thursday"}
                                      {row.days == 5 && "Friday"}
                                      {row.days == 6 && "Saturday"}
                                      {row.days == 7 && "Sunday"}
                                    </span>
                                  </div>
                                </div>
                              )}

                              {idx == 0 && (
                                <>
                                  {/* Gross Hours----------------------------------- */}
                                  <div className="time-gross-container">
                                    <label className="title">Gross Hours</label>
                                    <div className="input-container">
                                      <InputGroup className="time-input">
                                        <Form.Control
                                          name="grossHours"
                                          value={row.grossHours}
                                          onChange={(e) =>
                                            handleFlexibleChange(e, idx)
                                          }
                                          placeholder="9"
                                          required
                                        />
                                        <InputGroup.Text
                                          id="basic-addon2"
                                          onClick={() =>
                                            changeFlexibleAmPm(idx)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          Hours
                                        </InputGroup.Text>
                                      </InputGroup>
                                      {/* <label>(9 hrs 0 mins)</label> */}
                                    </div>
                                  </div>
                                  {/* Break duration----------------------------------- */}
                                  <div className="break-duration-container">
                                    <label className="title">
                                      Break Duration
                                    </label>
                                    <div className="input-container">
                                      <InputGroup className="time-input">
                                        <Form.Control
                                          name="breakDuration"
                                          value={row.breakDuration}
                                          onChange={(e) =>
                                            handleFlexibleChange(e, idx)
                                          }
                                          placeholder="120"
                                          required
                                        />
                                        <InputGroup.Text
                                          id="basic-addon2"
                                          style={{ cursor: "pointer" }}
                                        >
                                          mins
                                        </InputGroup.Text>
                                      </InputGroup>
                                      {/* <label>(2 hrs 0 mins)</label> */}
                                    </div>
                                  </div>
                                </>
                              )}

                              {idx != 0 &&
                                (row.weekOff == 0 || row.weekOff == "") && (
                                  <>
                                    {/* Gross Hours----------------------------------- */}
                                    <div className="time-gross-container">
                                      <label className="title">
                                        Gross Hours
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="grossHours"
                                            value={row.grossHours}
                                            onChange={(e) =>
                                              handleFlexibleChange(e, idx)
                                            }
                                            placeholder="9"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            onClick={() =>
                                              changeFlexibleAmPm(idx)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            Hours
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {/* <label>(9 hrs 0 mins)</label> */}
                                      </div>
                                    </div>
                                    {/* Break duration----------------------------------- */}
                                    <div className="break-duration-container">
                                      <label className="title">
                                        Break Duration
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="breakDuration"
                                            value={row.breakDuration}
                                            onChange={(e) =>
                                              handleFlexibleChange(e, idx)
                                            }
                                            placeholder="120"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            style={{ cursor: "pointer" }}
                                          >
                                            mins
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {/* <label>(2 hrs 0 mins)</label> */}
                                      </div>
                                    </div>
                                  </>
                                )}

                              {idx != 0 &&
                                row.weekOffDays.some((obj) => obj.value != 5) >
                                  0 && (
                                  <>
                                    {/* Gross Hours----------------------------------- */}
                                    <div className="time-gross-container">
                                      <label className="title">
                                        Gross Hours
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="grossHours"
                                            value={row.grossHours}
                                            onChange={(e) =>
                                              handleFlexibleChange(e, idx)
                                            }
                                            placeholder="9"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            onClick={() =>
                                              changeFlexibleAmPm(idx)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            Hours
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {/* <label>(9 hrs 0 mins)</label> */}
                                      </div>
                                    </div>
                                    {/* Break duration----------------------------------- */}
                                    <div className="break-duration-container">
                                      <label className="title">
                                        Break Duration
                                      </label>
                                      <div className="input-container">
                                        <InputGroup className="time-input">
                                          <Form.Control
                                            name="breakDuration"
                                            value={row.breakDuration}
                                            onChange={(e) =>
                                              handleFlexibleChange(e, idx)
                                            }
                                            placeholder="120"
                                            required
                                          />
                                          <InputGroup.Text
                                            id="basic-addon2"
                                            style={{ cursor: "pointer" }}
                                          >
                                            mins
                                          </InputGroup.Text>
                                        </InputGroup>
                                        {/* <label>(2 hrs 0 mins)</label> */}
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="gray"
                    onClick={handleClose}
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </DialogActions>
              </Form>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Shifts;
