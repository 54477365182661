import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import // getAuthUserDetails,
// loginCheck,
// getAccessToken,
// getRefreshToken,
"../../services/authService";

const loginCheck = () => {
  const value = localStorage.getItem("auth");
  return value;
};

const superAdminLoginCheck = () => {
  const value = localStorage.getItem("superadmin");
  return value;
};
const getAuthUserDetails = () => {
  const user = JSON.parse(localStorage.getItem("user_data"));
  // const user = JSON.parse(localStorage.getItem("userDetails"));
  return user ? user : {};
};

const getAccessToken = () => {
  const accessToken = JSON.parse(localStorage.getItem("accessToken") || "null");
  if (accessToken) {
    return accessToken;
  }
  return null;
};

const getRefreshToken = () => {
  const refreshToken = JSON.parse(
    localStorage.getItem("refreshToken") || "null"
  );
  if (refreshToken) {
    return refreshToken;
  }
  return null;
};

const initialState = {
  isLoggedIn: loginCheck(),
  superAdmin: superAdminLoginCheck(),
  authUser: Object(getAuthUserDetails()),
  userPermission: [],
  accessToken: getAccessToken(),
  refreshToken: getRefreshToken(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, { payload }) => ({
      ...state,
      authUser: payload,
    }),
    setUserPermission: (state, { payload }) => ({
      ...state,
      userPermission: payload,
    }),
    setIsLoggedIn: (state, { payload }) => ({
      ...state,
      isLoggedIn: payload,
    }),
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
    setRefreshToken: (state, { payload }) => {
      state.refreshToken = payload;
    },
  },
});

export const {
  setAuthUser,
  setUserPermission,
  setIsLoggedIn,
  setAccessToken,
  setRefreshToken,
} = authSlice.actions;

export default authSlice.reducer;
