import React from "react";
import ButtonCom from "../../components/button/button";
// image
import SettingImg from "../../assets/Images/balnk-page/setting.png"

const Index = () => {
  const Logout = () => {
    localStorage.removeItem("superadmin")
    localStorage.removeItem("auth")
    window.localStorage.clear()
    window.location.reload();
  }
  return (
    <div style={{ padding: "0px 30px 10px 30px" }}>
    
      <div className="page-top-bar">
        <div>Settings</div>
        <ButtonCom
          type={"button"}
          class_name={"p_btn"}
          text={"Logout"}
          url_or_onclick={Logout}
          icon={null}
          width={""}
        />
      </div>

      <div className="page-body">
        <div className="empty-content">
            {/* <img src={SettingImg} alt="setting" /> */}
            {/* <p>Settings</p> */}
        </div>
      </div>

    </div>
  );
};

export default Index;
