import "./LadingNaveBar.css";
import logo from "../../../assets/Images/seekbluelogostright.jpeg";

const LadingNaveBar = () => {
  return (
    <nav>
      <div className="nav-container">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="naveList">
          <li>COMPANY</li>
          <li>SUPPORT</li>
          <li id="last-li">Talk To an Expert</li>
        </div>
      </div>
    </nav>
  );
};

export default LadingNaveBar;
