import { useState, useCallback, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TableMui from "../../components/table/TableMui";
import Avatar from "../../assets/Images/user-avatar.jpg";
import { Link, useNavigate } from "react-router-dom";

import {
  fetchBranchData,
  initBranchState,
} from "../../redux/slices/branchSlice";
import {
  fetchDepartmentData,
  initState,
} from "../../redux/slices/departmentSlice";
import { fetchData, initShiftState } from "../../redux/slices/shiftsSlice";

import { useDispatch, useSelector } from "react-redux";

const LiveAttendance = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [user] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : null
  );

  const branchState = useSelector((state) => state.branch);
  const departState = useSelector((state) => state.department);
  const shiftState = useSelector((state) => state.shift);

  const getData = useCallback(() => {
    dispatch(fetchBranchData(user.user_id));
    dispatch(fetchDepartmentData(user.user_id));
    dispatch(fetchData(user.user_id));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const [branch, setBranch] = useState([]);
  const [depart, setDepart] = useState([]);
  const [shift, setShift] = useState([]);

  useEffect(() => {
    if (branchState.brnGetSuccess) {
      setBranch(branchState.brnGetData);
      dispatch(initBranchState());
    }

    if (departState.depGetSuccess) {
      setDepart(departState.depGetData);
      dispatch(initState());
    }

    if (shiftState.shiftGetSuccess) {
      setShift(shiftState.shiftGetData);
      dispatch(initShiftState());
    }
  }, [
    branchState.brnGetSuccess,
    departState.depGetSuccess,
    shiftState.shiftGetSuccess,
  ]);

  const goEmployeeAttendance = (data) => {
    navigate("employee-attendance");
  };

  const [usersList, setUsersList] = useState([
    { name: "Ravi Ojha", address: "HNo. 30/123", status: "Out" },
    { name: "Shantanu Sahu", address: "HNo. 30/123", status: "In" },
    { name: "Kaustub Mehra", address: "HNo. 30/123", status: "No Punch In" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const heading = [
    {
      name: "Staff",
      label: "name",
      render: (rowData) => {
        return (
          <>
            <div
              style={{ display: "flex", gap: "10px", cursor: "pointer" }}
              onClick={() => goEmployeeAttendance(rowData)}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={Avatar}
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              </div>
              <div>
                <h5>{rowData.name}</h5>
                <p style={{ fontSize: "12px", color: "gray" }}>
                  {rowData.address}
                </p>
              </div>
            </div>
          </>
        );
      },
    },

    {
      name: "Status",
      label: "status",
      width: "200px",
      render: (rowData) => {
        return (
          <>
            <div
              style={{
                textAlign: "center",
                width: "110px",
                borderRadius: "7px",
                padding: "7px",
                color:
                  rowData.status == "In"
                    ? "#82e0aa"
                    : rowData.status == "Out"
                    ? "gray"
                    : "#ec7063",
                backgroundColor:
                  rowData.status == "In"
                    ? "#eafaf1"
                    : rowData.status == "Out"
                    ? "#f8f9f9"
                    : "#fadbd8",
              }}
            >
              {rowData.status}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="">
      <div
        className="page-top-bar-1"
        style={{ marginTop: "-2px", borderRadius: "none" }}
      >
        Live Attendance
      </div>
      <div className="page-body">
        <Row>
          <Col
            sm="2"
            style={{
              borderRight: "1px solid lightgray",
              height: "75vh",
            }}
          >
            <Form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  <Link style={{ textDecoration: "none" }}>Reset</Link>
                </div>
                <Form.Group>
                  <Form.Label>Select Branch</Form.Label>
                  <Form.Select>
                    <option value="">All Branches</option>
                    {branch.map((row, idx) => {
                      return (
                        <option value={row.branchId} key={idx}>
                          {row.branch_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Department</Form.Label>
                  <Form.Select>
                    <option value="">All Departments</option>
                    {depart.map((row, idx) => {
                      return (
                        <option value={row.departmentId} key={idx}>
                          {row.department_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Shift</Form.Label>
                  <Form.Select>
                    <option value="">All Shifts</option>
                    {shift.map((row, idx) => {
                      return (
                        <option value={row.shiftId} key={idx}>
                          {row.shift_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col sm="10">
            <div
              style={{
                display: "flex",
                gap: "10px",
                borderBottom: "1px solid lightgray",
                paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "7px",
                  padding: "5px 10px",
                }}
              >
                In(14)
              </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "7px",
                  padding: "5px 10px",
                }}
              >
                Out(20)
              </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "7px",
                  padding: "5px 10px",
                }}
              >
                No Punch In(14)
              </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "7px",
                  padding: "5px 10px",
                }}
              >
                Break(14)
              </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "7px",
                  padding: "5px 10px",
                }}
              >
                Late(14)
              </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "7px",
                  padding: "5px 10px",
                }}
              >
                Early Learning(14)
              </div>
            </div>
            <div className="search-staff mt-3">
              <Form>
                <Form.Control
                  value=""
                  placeholder="Search Staff"
                  style={{ borderRadius: "7px", width: "300px" }}
                />
              </Form>
            </div>

            <div className="mt-3">
              <TableMui
                tableHead={heading}
                data={usersList}
                rowPagesPlay={true}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                Pages={10}
                editId={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LiveAttendance;
