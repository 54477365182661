import popperClasses from "@mui/material/Popper/popperClasses";
import "./MoreSetting.css";
import React, { useCallback, useEffect, useState } from "react";

import { RiDeleteBin6Line } from "react-icons/ri";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdFingerprint } from "react-icons/md";
import Switch from "@mui/material/Switch";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CustButton from "../../components/button/button";
import { ToastContainer, toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  getAttendOptions,
  getMoreSettings,
  saveMoreSettings,
  initMoreSettState,
} from "../../redux/slices/moreSettings";
import { Link } from "react-router-dom";

const MoreSettings = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.moreSettings);
  // Tabs variables ===========================
  const settingTabs = [
    {
      id: 0,
      tabName: "Clock In & Clock Out",
      childTabs: [],
    },
    {
      id: 1,
      tabName: "Attendance Regularization",
      childTabs: [],
    },
    {
      id: 2,
      tabName: "Late Check In & Early Check Out",
      childTabs: [],
    },
    {
      id: 3,
      tabName: "Approval Chain",
      childTabs: [],
    },
    {
      id: 4,
      tabName: "General Setting",
      childTabs: [],
    },
    {
      id: 5,
      tabName: "Auto Half Day",
      childTabs: [],
    },
  ];
  const [mainTabIndex, setMainTabIndex] = useState(0);
  // Attend option variables and functions
  const [attendOptions, setAttendOptions] = useState([]);
  const fetchAttendOptions = useCallback(() => {
    let path = "admin/getAttendOptions";
    dispatch(getAttendOptions(path));
  }, []);

  useEffect(() => {
    fetchAttendOptions();
  }, []);
  useEffect(() => {
    if (store.mSetGetAttSuccess) {
      setAttendOptions(store.mSetGetAttData);
    }
  }, [store.mSetGetAttSuccess]);

  // Get more settings prefill data variables and function
  const [moreSettingsData, setMoreSettingsData] = useState([]);
  const fetchMoreSettings = useCallback(() => {
    let path = "";
    dispatch(getMoreSettings(path));
  }, []);
  useEffect(() => {
    fetchMoreSettings();
  }, []);
  useEffect(() => {
    if (store.mSetGetSuccess) {
      setMoreSettingsData(store.mSetGetData);
    }
  }, [store.mSetGetSuccess]);

  return (
    <>
      <div className="more-setting-container">
        <div className="main-tab">
          {settingTabs.map((tab, idx) => {
            return (
              <button
                className={mainTabIndex == tab.id ? "active" : null}
                onClick={() => setMainTabIndex(idx)}
                key={idx}
              >
                {tab.tabName}
              </button>
            );
          })}
        </div>
        {/* ----------------- */}
        <div className="main-tab-body">
          {mainTabIndex == 0 && (
            <ClockInOut
              attendOptions={attendOptions}
              settingsData={moreSettingsData}
              store={store}
            />
          )}
          {mainTabIndex == 1 && (
            <AttendanceRegularization
              attendOptions={attendOptions}
              settingsData={moreSettingsData}
              store={store}
            />
          )}
          {mainTabIndex == 2 && (
            <LateCheckInEarlyCheckOut
              attendOptions={attendOptions}
              settingsData={moreSettingsData}
              store={store}
            />
          )}
          {mainTabIndex == 3 && (
            <ApprovalChain
              attendOptions={attendOptions}
              settingsData={moreSettingsData}
              store={store}
            />
          )}
          {mainTabIndex == 4 && (
            <GeneralSetting
              attendOptions={attendOptions}
              settingsData={moreSettingsData}
              store={store}
            />
          )}
          {mainTabIndex == 5 && (
            <AutoHalfDay
              attendOptions={attendOptions}
              settingsData={moreSettingsData}
              store={store}
            />
          )}
        </div>
      </div>
    </>
  );
};

const ClockInOut = ({ attendOptions, moreSettingsData, store }) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    userId: user.user_id,
    employeeLocation: "1",
  });

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let data = {
        path: "admin/attendanceGeneralSettings",
        data: state,
      };
      dispatch(saveMoreSettings(data));
    }
  };

  useEffect(() => {
    console.log(store.mSetError);
    if (store.mSetSuccess) {
      toast.success(store.mSetMessage);
      dispatch(initMoreSettState());
    }
    if (store.mSetError) {
      toast.error(store.mSetMessage);
      dispatch(initMoreSettState());
    }
  }, [store.mSetSuccess, store.mSetError]);

  return (
    <>
      <h4 className="more-setting-title">
        <Link to="/setting">
          <IoIosArrowRoundBack style={{ fontSize: "32px", color: "gray" }} />
        </Link>{" "}
        Clock In & Clock Out
      </h4>
      <div className="more-setting-form-container">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          className="toast-custom"
        />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <div className="AttendanceRules-tap">
              <b>
                Mark Attendance form
                <div className="MarkAttendanceform-radio-card">
                  <div className="MarkAttendanceform-radio">
                    <input
                      name="employeeLocation"
                      type="radio"
                      value="1"
                      onChange={handleChange}
                      checked={state.employeeLocation == "1" ? "checked" : null}
                    />
                    <label>Only in Assigned Location</label>
                  </div>
                  <div className="MarkAttendanceform-radio">
                    <input
                      name="employeeLocation"
                      type="radio"
                      value="2"
                      onChange={handleChange}
                      checked={state.employeeLocation == "2" ? "checked" : null}
                    />
                    <label>From Anywhere</label>
                  </div>
                </div>
              </b>
            </div>
          </Form.Group>
          <Form.Group style={{ padding: "10px" }}>
            <Row>
              <Col sm="3" style={{ padding: "0" }}>
                <CustButton
                  type={"submit"}
                  class_name="p_btn"
                  text="Submit"
                  url_or_onclick={null}
                  icon={null}
                  width="150px"
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

const AttendanceRegularization = ({
  attendOptions,
  moreSettingsData,
  store,
}) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    userId: user.user_id,
    reqularizeAttendDays: "",
    regularizeAttendYear: "",
    reasonForPermission: "",
    reqularizeUpto: "",
    reasonForLate: "",
  });

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let data = {
        path: "admin/attendanceGeneralSettings",
        data: state,
      };
      dispatch(saveMoreSettings(data));
    }
  };

  useEffect(() => {
    console.log(store.mSetError);
    if (store.mSetSuccess) {
      toast.success(store.mSetMessage);
      dispatch(initMoreSettState());
    }
    if (store.mSetError) {
      toast.error(store.mSetMessage);
      dispatch(initMoreSettState());
    }
  }, [store.mSetSuccess, store.mSetError]);

  return (
    <>
      <h4 className="more-setting-title">
        <Link to="/setting">
          <IoIosArrowRoundBack style={{ fontSize: "32px", color: "gray" }} />
        </Link>
        Late Check In Early Check Out Permissions
      </h4>

      <div className="more-setting-form-container">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          className="toast-custom"
        />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Row>
              <Col sm={7} className="flex-form mb-3">
                <label>Employees Can regularise Attendance in &nbsp;</label>
                <Form.Control
                  name="reqularizeAttendDays"
                  value={state.reqularizeAttendDays}
                  onChange={handleChange}
                  placeholder="10"
                  style={{ width: "80px" }}
                />
                <label>&nbsp;&nbsp;days in a&nbsp;&nbsp;</label>
                <Form.Select
                  name="regularizeAttendYear"
                  value={state.regularizeAttendYear}
                  onChange={handleChange}
                  style={{ width: "170px" }}
                >
                  <option value="">Select an option</option>
                  {attendOptions.map((row, idx) => {
                    return (
                      <option value={row.id} key={idx}>
                        {row.options}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>

          {/* {state.reqularizeAttendDays && state.regularizeAttendYear && (
            <Form.Group>
              <Row>
                <Col sm={7} className="flex-form mb-3">
                  <Form.Control
                    name="reasonForreqularizeAttendDays"
                    value={state.reasonForreqularizeAttendDays}
                    onChange={handleChange}
                    placeholder="Reason for reqularizeAttendDays"
                    required
                  />
                </Col>
              </Row>
              <Form.Control.Feedback type={"invalid"}>
                Reason for reqularizeAttendDays is required.
              </Form.Control.Feedback>
            </Form.Group>
          )} */}
          <Form.Group>
            <Row>
              <Col sm={7} className="flex-form mb-3">
                <label>Employees Can regularise attendance upto &nbsp;</label>
                <Form.Control
                  name="reqularizeUpto"
                  value={state.reqularizeUpto}
                  onChange={handleChange}
                  style={{ width: "200px" }}
                  placeholder="Enter days"
                />
              </Col>
            </Row>
          </Form.Group>

          {/* {state.late && (
            <Form.Group>
              <Row>
                <Col sm={7} className="flex-form mb-3">
                  <Form.Control
                    name="reasonForLate"
                    value={state.reasonForLate}
                    onChange={handleChange}
                    placeholder="Reason for late"
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          )} */}

          <Row>
            <Col sm="3" style={{ padding: "0" }}>
              <CustButton
                type={"submit"}
                class_name="p_btn"
                text="Submit"
                url_or_onclick={null}
                icon={null}
                width="150px"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const LateCheckInEarlyCheckOut = ({
  attendOptions,
  moreSettingsData,
  store,
}) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    userId: user.user_id,
    permissionAvailDays: "",
    permissionAvailYears: "",
    reasonForPermission: "",
    lateArrivalMinutes: "",
    reasonForLate: "",
    earlyCheckoutMinutes: "",
    reasonForEarly: "",
  });

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let data = {
        path: "admin/attendanceGeneralSettings",
        data: state,
      };
      dispatch(saveMoreSettings(data));
    }
  };

  useEffect(() => {
    console.log(store.mSetError);
    if (store.mSetSuccess) {
      toast.success(store.mSetMessage);
      dispatch(initMoreSettState());
    }
    if (store.mSetError) {
      toast.error(store.mSetMessage);
      dispatch(initMoreSettState());
    }
  }, [store.mSetSuccess, store.mSetError]);

  return (
    <>
      <h4 className="more-setting-title">
        <Link to="/setting">
          <IoIosArrowRoundBack style={{ fontSize: "32px", color: "gray" }} />
        </Link>
        Late Check In Early Check Out Permissions
      </h4>

      <div className="more-setting-form-container">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          className="toast-custom"
        />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Row>
              <Col sm={6} className="flex-form mb-3">
                <label>Permissions can be availed for &nbsp;</label>
                <Form.Control
                  name="permissionAvailDays"
                  value={state.permissionAvailDays}
                  onChange={handleChange}
                  placeholder="10"
                  style={{ width: "80px" }}
                />
                <label>&nbsp;&nbsp;days in a&nbsp;&nbsp;</label>
                <Form.Select
                  name="permissionAvailYears"
                  value={state.permissionAvailYears}
                  onChange={handleChange}
                  style={{ width: "170px" }}
                >
                  <option value="">Select an option</option>
                  {attendOptions.map((row, idx) => {
                    return (
                      <option value={row.id} key={idx}>
                        {row.options}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>

          {/* {state.permission && state.permissionAvailYears && (
            <Form.Group>
              <Row>
                <Col sm={6} className="flex-form mb-3">
                  <Form.Control
                    name="reasonForPermission"
                    value={state.reasonForPermission}
                    onChange={handleChange}
                    placeholder="Reason for permission"
                    required
                  />
                </Col>
              </Row>
              <Form.Control.Feedback type={"invalid"}>
                Reason for permission is required.
              </Form.Control.Feedback>
            </Form.Group>
          )} */}
          <Form.Group>
            <Row>
              <Col sm={6} className="flex-form mb-3">
                <label>Late Arrival of &nbsp;</label>
                <Form.Control
                  name="lateArrivalMinutes"
                  value={state.lateArrivalMinutes}
                  onChange={handleChange}
                  style={{ width: "200px" }}
                  placeholder="Enter minutes"
                />
                <label>&nbsp; Minutes is allowed per request</label>
              </Col>
            </Row>
          </Form.Group>

          {/* {state.lateArrivalMinutes && (
            <Form.Group>
              <Row>
                <Col sm={6} className="flex-form mb-3">
                  <Form.Control
                    name="reasonForLate"
                    value={state.reasonForLate}
                    onChange={handleChange}
                    placeholder="Reason for late"
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          )} */}
          <Form.Group>
            <Row>
              <Col sm={6} className="flex-form mb-3">
                <label>Early Check Out of &nbsp;</label>
                <Form.Control
                  name="earlyCheckoutMinutes"
                  value={state.earlyCheckoutMinutes}
                  onChange={handleChange}
                  style={{ width: "200px" }}
                  placeholder="Enter minutes"
                />
                <label>&nbsp; Minutes is allowed per request</label>
              </Col>
            </Row>
          </Form.Group>

          {/* {state.earlyCheckoutMinutes && (
            <Form.Group>
              <Row>
                <Col sm={6} className="flex-form mb-3">
                  <Form.Control
                    name="reasonForEarly"
                    value={state.reasonForEarly}
                    onChange={handleChange}
                    placeholder="Reason for early"
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          )} */}
          <Row>
            <Col sm="3" style={{ padding: "0" }}>
              <CustButton
                type={"submit"}
                class_name="p_btn"
                text="Submit"
                url_or_onclick={null}
                icon={null}
                width="150px"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const ApprovalChain = ({ attendOptions, moreSettingsData, store }) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    permission: "",
    daysIn: "",
    reasonForPermission: "",
    late: "",
    reasonForLate: "",
    early: "",
    reasonForEarly: "",
  });

  const [assignees, setAssignees] = useState([
    {
      assignees: "",
      skip: "",
      skipDuration: "",
    },
  ]);

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const addMoreAssignees = () => {
    const newAssignees = [...assignees];
    newAssignees.push({
      assignees: "",
      skip: "",
      skipDuration: "",
    });
    setAssignees(newAssignees);
  };

  const removeLevel = (id) => {
    const newAssignees = [...assignees];

    newAssignees.splice(id, 1);
    setAssignees(newAssignees);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let data = {
        path: "admin/attendanceGeneralSettings",
        data: state,
      };
      dispatch(saveMoreSettings(data));
    }
  };

  useEffect(() => {
    console.log(store.mSetError);
    if (store.mSetSuccess) {
      toast.success(store.mSetMessage);
      dispatch(initMoreSettState());
    }
    if (store.mSetError) {
      toast.error(store.mSetMessage);
      dispatch(initMoreSettState());
    }
  }, [store.mSetSuccess, store.mSetError]);
  return (
    <>
      <h4 className="more-setting-title">
        <Link to="/setting">
          <IoIosArrowRoundBack style={{ fontSize: "32px", color: "gray" }} />
        </Link>{" "}
        Approval Chain
      </h4>
      <div className="more-setting-form-container">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Row>
              <Col sm={8} className="flex-form mb-3">
                <label>
                  Mandatory Approval required if request exceeds &nbsp;
                </label>
                <Form.Control
                  name="permission"
                  value={state.permission}
                  onChange={handleChange}
                  placeholder="10"
                  style={{ width: "80px" }}
                />
                <label>&nbsp;&nbsp;times in a&nbsp;&nbsp;</label>
                <Form.Select
                  name="daysIn"
                  value={state.daysIn}
                  onChange={handleChange}
                  style={{ width: "170px" }}
                >
                  <option value="">Select an option</option>
                  {attendOptions.map((row, idx) => {
                    return (
                      <option value={row.id} key={idx}>
                        {row.options}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>

          {state.permission && state.daysIn && (
            <Form.Group>
              <Row>
                <Col sm={8} className="flex-form mb-3">
                  <Form.Control
                    name="reasonForPermission"
                    value={state.reasonForPermission}
                    onChange={handleChange}
                    placeholder="Reason for permission"
                    required
                  />
                </Col>
              </Row>
              <Form.Control.Feedback type={"invalid"}>
                Reason for permission is required.
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group>
            <Row className="flex-form mb-5" style={{ width: "68%" }}>
              {assignees.map((row, idx) => {
                return (
                  <Col key={idx} sm={12} className="p-0 mb-3 approval-con">
                    <Card style={{ width: "100%" }}>
                      <Card.Header
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          Level {idx + 1}
                          <b> Approval</b>
                        </span>
                        {idx != 0 && (
                          <RiDeleteBin6Line
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => removeLevel(idx)}
                          />
                        )}
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <Form.Label>Assignees</Form.Label>
                          <Form.Control
                            placeholder="Role / Employee"
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              paddingTop: "20px",
                            }}
                          >
                            <Form.Check label="Skip step if no action taken in" />

                            <InputGroup
                              style={{
                                width: "110px",
                              }}
                            >
                              <Form.Control placeholder="10" />
                              <InputGroup.Text id="basic-addon1">
                                days
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    {/* <div className="ver-line"></div> */}
                  </Col>
                );
              })}

              <Col sm="5" className="p-0 mt-2">
                <Button
                  variant="outline-primary"
                  style={{ width: "150px", marginTop: "-16px" }}
                  onClick={addMoreAssignees}
                >
                  Add More Level
                </Button>
              </Col>
            </Row>
          </Form.Group>

          <Row>
            <Col sm="3" style={{ padding: "0" }}>
              <CustButton
                type={"submit"}
                class_name="p_btn"
                text="Submit"
                url_or_onclick={null}
                icon={null}
                width="150px"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const GeneralSetting = ({ attendOptions, moreSettingsData, store }) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    userId: user.user_id,
    isFaceEnable: 1,
    isLocationEnable: 1,
    trackLocationTime: "",
    reasonForPermission: "",
  });

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const [isFaceEnableChecked, setIsFaceEnableChecked] = React.useState(true);
  const [isLocationEnableChecked, setIsLocationEnableChecked] =
    React.useState(true);

  const handleChecked = (event) => {
    const {
      target: { name, checked },
    } = event;
    setState({
      ...state,
      [name]: checked ? 1 : 0,
    });
    if (name == "isLocationEnable") {
      setIsLocationEnableChecked(checked);
    } else {
      setIsFaceEnableChecked(checked);
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let data = {
        path: "admin/attendanceGeneralSettings",
        data: state,
      };
      dispatch(saveMoreSettings(data));
    }
  };

  useEffect(() => {
    console.log(store.mSetError);
    if (store.mSetSuccess) {
      toast.success(store.mSetMessage);
      dispatch(initMoreSettState());
    }
    if (store.mSetError) {
      toast.error(store.mSetMessage);
      dispatch(initMoreSettState());
    }
  }, [store.mSetSuccess, store.mSetError]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <h4 className="more-setting-title">
        <Link to="/setting">
          <IoIosArrowRoundBack style={{ fontSize: "32px", color: "gray" }} />
        </Link>{" "}
        General Settings
      </h4>

      <div className="more-setting-form-container">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Row>
              <Col
                sm={7}
                className="flex-form mb-3"
                style={{ justifyContent: "space-between" }}
              >
                <label>Enable AI Face Recognition Setting &nbsp;</label>
                <Switch
                  name="isFaceEnable"
                  checked={isFaceEnableChecked}
                  onChange={handleChecked}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col
                sm={7}
                className="flex-form mb-3"
                style={{ justifyContent: "space-between" }}
              >
                <label>Track Location Permission &nbsp;</label>
                <Switch
                  name="isLocationEnable"
                  checked={isLocationEnableChecked}
                  onChange={handleChecked}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col sm={7} className="flex-form mb-3">
                <label>Track Location Every &nbsp;</label>
                <Form.Control
                  name="trackLocationTime"
                  value={state.trackLocationTime}
                  onChange={handleChange}
                  style={{ width: "200px" }}
                  placeholder="Enter minutes"
                />
                <label> &nbsp;&nbsp;Minutes</label>
              </Col>
            </Row>
          </Form.Group>

          {/* {state.trackLocationTime && (
            <Form.Group>
              <Row>
                <Col sm={7} className="flex-form mb-3">
                  <Form.Control
                    name="reasonForLate"
                    value={state.reasonForLate}
                    onChange={handleChange}
                    placeholder="Reason for late"
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          )} */}

          <Form.Group>
            <Row>
              <Col
                sm={7}
                className="flex-form mb-3"
                style={{ justifyContent: "space-between" }}
              >
                <label>
                  <ShareLocationIcon /> GPS Attendance &nbsp;
                </label>
                <Switch
                  checked={false}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col
                sm={7}
                className="flex-form mb-3"
                style={{ justifyContent: "space-between" }}
              >
                <label>
                  <MdFingerprint /> Biometric Attendance &nbsp;
                </label>
                <Switch
                  checked={false}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Col>
            </Row>
          </Form.Group>

          <Row>
            <Col sm="3" style={{ padding: "0" }}>
              <CustButton
                type={"submit"}
                class_name="p_btn"
                text="Submit"
                url_or_onclick={null}
                icon={null}
                width="150px"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const AutoHalfDay = ({ attendOptions, moreSettingsData, store }) => {
  const [user] = useState(JSON.parse(localStorage.getItem("user_data")));
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    userId: user.user_id,
    allowHalfDays: "",
    allowHalfDaysYears: "",
  });

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let data = {
        path: "admin/attendanceGeneralSettings",
        data: state,
      };
      dispatch(saveMoreSettings(data));
    }
  };

  useEffect(() => {
    console.log(store.mSetError);
    if (store.mSetSuccess) {
      toast.success(store.mSetMessage);
      dispatch(initMoreSettState());
    }
    if (store.mSetError) {
      toast.error(store.mSetMessage);
      dispatch(initMoreSettState());
    }
  }, [store.mSetSuccess, store.mSetError]);

  return (
    <>
      <h4 className="more-setting-title">
        <Link to="/setting">
          <IoIosArrowRoundBack style={{ fontSize: "32px", color: "gray" }} />
        </Link>{" "}
        Auto Half Day
      </h4>
      <div className="more-setting-form-container">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          className="toast-custom"
        />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group>
            <Row>
              <Col sm={7} className="flex-form mb-3">
                <label>Auto Half Day if Late by&nbsp;</label>
                <Form.Control
                  name="allowHalfDays"
                  value={state.allowHalfDays}
                  onChange={handleChange}
                  placeholder="10"
                  style={{ width: "80px" }}
                />
                <label>&nbsp;&nbsp;Hours&nbsp;&nbsp;</label>
              </Col>
            </Row>
            <Form.Control.Feedback type={"invalid"}>
              Field is required
            </Form.Control.Feedback>
          </Form.Group>
          {/* ---------------- */}
          <Form.Group>
            <Row>
              <Col sm={7} className="flex-form mb-3">
                <label>Allow upto &nbsp;</label>
                <Form.Control
                  name="allowHalfDaysYears"
                  value={state.allowHalfDaysYears}
                  onChange={handleChange}
                  style={{ width: "200px" }}
                  placeholder="Enter days"
                />
                <label>&nbsp;&nbsp;days&nbsp;&nbsp;</label>
              </Col>
            </Row>
          </Form.Group>
          {/* ------------------------ */}
          <Form.Group style={{ padding: "10px" }}>
            <Row>
              <Col sm="3" style={{ padding: "0" }}>
                <CustButton
                  type={"submit"}
                  class_name="p_btn"
                  text="Submit"
                  url_or_onclick={null}
                  icon={null}
                  width="150px"
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default MoreSettings;
