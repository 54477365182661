import React, { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "../../assets/Images/user-avatar.jpg";
import { Col, Form, Row } from "react-bootstrap";
import ButtonCom from "../../components/button/button";

import { getDaysInMonth } from "../../services/commonServices";

const EmployeeAttendance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    if (location.pathname == "/daily-attendance/employee-attendance") {
      navigate("/daily-attendance");
    } else {
      navigate("/live-attendance");
    }
  };
  const [dates, setDates] = useState([]);
  const [week1, setWeek1] = useState([]);
  const [week2, setWeek2] = useState([]);
  const [week3, setWeek3] = useState([]);
  const [week4, setWeek4] = useState([]);
  const [week5, setWeek5] = useState([]);

  useState(() => {
    let d = getDaysInMonth(2025, 1);
    setDates(d);
    setWeek1(d.slice(0, 7));
    setWeek2(d.slice(7, 14));
    setWeek3(d.slice(14, 21));
    setWeek4(d.slice(21, 28));
    setWeek5(d.slice(28));
  }, []);

  const checkContent = (day) => {
    let content = null;
    if (day == "Sun") {
      let data = week1.map((v) => {
        console.log(v);
        return v.day == "Sun" ? v.date : null;
      });
      content = data.includes(1) ? "start" : "end";
    }

    if (day == "Mon") {
      let data = week1.map((v) => {
        console.log(v);
        return v.day == "Mon" ? v.date : null;
      });
      content = data.includes(1) || data.includes(2) ? "start" : "end";
    }

    if (day == "Tue") {
      let data = week1.map((v) => {
        console.log(v);
        return v.day == "Tue" ? v.date : null;
      });
      content =
        data.includes(1) || data.includes(2) || data.includes(3)
          ? "start"
          : "end";
    }

    if (day == "Wed") {
      let data = week1.map((v) => {
        console.log(v);
        return v.day == "Wed" ? v.date : null;
      });
      content =
        data.includes(1) ||
        data.includes(2) ||
        data.includes(3) ||
        data.includes(4)
          ? "start"
          : "end";
    }

    if (day == "Thu") {
      let data = week1.map((v) => {
        console.log(v);
        return v.day == "Thu" ? v.date : null;
      });
      content =
        data.includes(1) ||
        data.includes(2) ||
        data.includes(3) ||
        data.includes(4) ||
        data.includes(5)
          ? "start"
          : "end";
    }

    if (day == "Fri") {
      let data = week1.map((v) => {
        console.log(v);
        return v.day == "Fri" ? v.date : null;
      });
      content =
        data.includes(1) ||
        data.includes(2) ||
        data.includes(3) ||
        data.includes(4) ||
        data.includes(5) ||
        data.includes(6)
          ? "start"
          : "end";
    }

    if (day == "Sat") {
      let data = week1.map((v) => {
        console.log(v);
        return v.day == "Sat" ? v.date : null;
      });
      content =
        data.includes(1) ||
        data.includes(2) ||
        data.includes(3) ||
        data.includes(4) ||
        data.includes(5) ||
        data.includes(6) ||
        data.includes(7)
          ? "start"
          : "end";
    }

    return content;
  };

  return (
    <div className="body-container">
      <div className="page-top-bar">
        <h1 onClick={goBack} style={{ cursor: "pointer" }}>
          <IoIosArrowRoundBack className="icon" /> Employee Attendance
        </h1>
      </div>

      <div className="page-body">
        {/* header */}
        <div className="body-header">
          <div className="left-header">
            <img src={Avatar} alt="user-details" />
            <span>User Name</span>
          </div>
          <div className="right-header">
            <Form>
              <Row>
                <Col sm="6">
                  <Form.Group>
                    <Form.Select>
                      <option value="1">January</option>
                      <option value="2">February</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col sm="6">
                  <Form.Group>
                    <Form.Select>
                      <option value="1">2024</option>
                      <option value="2">2025</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <ButtonCom
              type={"button"}
              class_name={"p_btn"}
              text="Download Report"
              url_or_onclick={() => {}}
              icon={null}
              width=""
            />

            <ButtonCom
              type={"button"}
              class_name={"p_btn"}
              text="Mark All Absent as Present"
              url_or_onclick={() => {}}
              icon={null}
              width=""
            />
          </div>
        </div>
        {/*sub header */}
        <div
          className="body-subheader mt-4 pb-4"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="flex-column present">
            <span>Present</span>
            <span>21</span>
          </div>

          <div className="flex-column absent">
            <span>Absent</span>
            <span>4</span>
          </div>

          <div className="flex-column half-day">
            <span>Half Day</span>
            <span>1</span>
          </div>

          <div className="flex-column paid-leave">
            <span>Paid Leave</span>
            <span>1.5</span>
          </div>

          <div className="flex-column week-off">
            <span>Week Off</span>
            <span>3</span>
          </div>
        </div>
        {/* content */}
        <div className="body-content mt-4">
          <div className="day-container">
            <div
              className="day-names"
              style={{ position: "relative", top: "1px" }}
            >
              Sun
            </div>
            <div
              className="day-inner-container"
              style={{ justifyContent: checkContent("Sun") }}
            >
              {dates.map((v, i) => {
                return v.day == "Sun" ? (
                  <div key={i} className="date mt-1">
                    {v.date}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="day-container">
            <div
              className="day-names"
              style={{ position: "relative", top: "1px" }}
            >
              Mon
            </div>
            <div
              className="day-inner-container"
              style={{ justifyContent: checkContent("Mon") }}
            >
              {dates.map((v, i) => {
                return v.day == "Mon" ? (
                  <div key={i} className="date mt-1">
                    {v.date}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="day-container">
            <div
              className="day-names"
              style={{ position: "relative", top: "1px" }}
            >
              Tue
            </div>
            <div
              className="day-inner-container"
              style={{ justifyContent: checkContent("Tue") }}
            >
              {dates.map((v, i) => {
                return v.day == "Tue" ? (
                  <div key={i} className="date mt-1">
                    {v.date}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="day-container">
            <div
              className="day-names"
              style={{ position: "relative", top: "1px" }}
            >
              Wed
            </div>
            <div
              className="day-inner-container"
              style={{ justifyContent: checkContent("Wed") }}
            >
              {dates.map((v, i) => {
                return v.day == "Wed" ? (
                  <div key={i} className="date mt-1">
                    {v.date}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="day-container">
            <div
              className="day-names"
              style={{ position: "relative", top: "1px" }}
            >
              Thu
            </div>
            <div
              className="day-inner-container"
              style={{ justifyContent: checkContent("Thu") }}
            >
              {dates.map((v, i) => {
                return v.day == "Thu" ? (
                  <div key={i} className="date mt-1">
                    {v.date}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="day-container">
            <div
              className="day-names"
              style={{ position: "relative", top: "1px" }}
            >
              Fri
            </div>
            <div
              className="day-inner-container"
              style={{ justifyContent: checkContent("Fri") }}
            >
              {dates.map((v, i) => {
                return v.day == "Fri" ? (
                  <div key={i} className="date mt-1">
                    {v.date}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="day-container">
            <div
              className="day-names"
              style={{ position: "relative", top: "1px" }}
            >
              Sat
            </div>
            <div
              className="day-inner-container"
              style={{ justifyContent: checkContent("Sat") }}
            >
              {dates.map((v, i) => {
                return v.day == "Sat" ? (
                  <div key={i} className="date mt-1">
                    {v.date}
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAttendance;
