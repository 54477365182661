import axios from "axios";
import { refreshTokenUpdate } from "../services/authService";

const getToken = () => {
  const accessToken = JSON.parse(localStorage.getItem("accessToken") || "null");
  const refreshToken = JSON.parse(
    localStorage.getItem("refreshToken") || "null"
  );

  if (accessToken != null && refreshToken != null) {
    return {
      access_token: accessToken,
      token_type: "Bearer",
      refresh_token: refreshToken,
    };
  } else {
    return {
      access_token: null,
      token_type: "Bearer",
      refresh_token: null,
    };
  }
};

// Function to refresh the token
const getRefreshToken = async () => {
  try {
    const refreshToken = JSON.parse(
      localStorage.getItem("refreshToken") || "null"
    );

    if (!refreshToken) {
      return null;
    }

    const response = await refreshTokenUpdate({
      refresh_token: refreshToken,
    });

    if (!response.access_token) {
      return null;
    }
    return response.access_token;
  } catch (error) {
    return null;
  }
};

let isRefreshing = false;
let failedQueue = [];

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await getRefreshToken();

          if (newAccessToken) {
            processQueue(null, newAccessToken);
            return await retryOriginalRequest(originalRequest, newAccessToken);
          }
          return Promise.reject(new Error("Error refreshing token"));
        } catch (refreshError) {
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        return retryFailedRequest(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

const retryOriginalRequest = async (requestConfig, token) => {
  requestConfig.headers.Authorization = `Bearer ${token}`;
  requestConfig._retry = true;
  return axios(requestConfig);
};

const retryFailedRequest = (originalRequest) => {
  return new Promise((resolve, reject) => {
    failedQueue.push({
      resolve: (token) => {
        if (token) {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        } else {
          reject(new Error("Token refresh failed"));
        }
      },
      reject: (error) => {
        reject(error);
      },
    });
  });
};

const processQueue = (error, token) => {
  failedQueue.forEach((prom) => {
    if (error !== null && error !== undefined) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

function sessionTimeout() {
  alert("Session timeout, please login again");
}

const apiUtils = {
  getWithoutToken: async (url, body) =>
    await new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            authorization: `Bearer ${body.refresh_token}`,
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          localStorage.clear();
          sessionTimeout();
          window.location.reload();
          reject(error);
        });
    }),
  getWithToken: async (url) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken();
      if (
        credentials?.access_token != null &&
        credentials.token_type.length > 0 &&
        credentials.token_type.length > 0
      ) {
        axios
          .get(url, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),

  getExcelFile: async (url) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken();
      if (
        credentials?.access_token != null &&
        credentials.token_type.length > 0 &&
        credentials.token_type.length > 0
      )
        axios
          .get(url, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
            },
            responseType: "arraybuffer",
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
    }),

  postWithoutToken: async (url, body) =>
    await new Promise((resolve, reject) => {
      axios
        .post(url, body, {
          headers: {
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  postWithToken: async (url, body) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken();

      if (
        credentials?.access_token != null &&
        credentials.token_type.length > 0 &&
        credentials.token_type.length > 0
      ) {
        axios
          .post(url, body, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),

  putWithToken: async (url, body) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken();

      if (
        credentials?.access_token != null &&
        credentials.token_type.length > 0 &&
        credentials.token_type.length > 0
      ) {
        axios
          .put(url, body, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),

  putWithoutToken: async (url, body) =>
    await new Promise((resolve, reject) => {
      axios
        .put(url, body, {
          headers: {
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  deleteWithToken: async (url) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken();

      if (
        credentials?.access_token != null &&
        credentials.token_type.length > 0 &&
        credentials.token_type.length > 0
      ) {
        axios
          .delete(url, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),
};

export default apiUtils;
