import "./CompanyReports.css";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";

import { useDispatch, useSelector } from "react-redux";
import { commonGetData } from "../../../redux/slices/commonSlice";
import { fetchBranchData } from "../../../redux/slices/branchSlice";
import { fetchDepartmentData } from "../../../redux/slices/departmentSlice";

const CompanyReports = () => {
  const location = useLocation();
  const pathname = location.pathname;

  // console.log(state)
  // admin/getReportType?report_type=1

  // const [ reportApiType, setReportApiType ] = useState(1)

  // const handleChangeReportApiType = (val) => {
  //   setReportApiType(val)
  // }

  return (
    <div className="CompanyReports">
      {pathname != "/reports" && (
        <div className="SettingBody-head" style={{ fontSize: "16px" }} >
          <Link to={"/setting/"}  >
            <ArrowBackIcon sx={{ scale: 1 }} style={{ color: "gray" }}/>
          </Link>
          <p>Company Details</p>
        </div>
      )}

      <div className="CompanyReports-body">
        <CompanyReportsForm />
        <ReportsTable />
      </div>
    </div>
  );
};

const ManagementTab = ["Attendance", "Payroll", "Notes", "Employee List"];
const tabStyle = { borderBottom: "4px solid #309BFF", color: "#309BFF" };

const inputsTwo = [
  {
    name: "personalEmailId",
    label: "Business Type",
    chi: ["select....", "1", "2"],
    width: 200,
  },
  {
    name: "personalEmailId",
    label: "Business Type",
    chi: ["select....", "1", "2"],
    width: 200,
  },
  {
    name: "personalEmailId",
    label: "Business Type",
    chi: ["select....", "1", "2"],
    width: 200,
  },
  {
    name: "personalEmailId",
    label: "Business Type",
    type: "date",
    width: 200,
  },
];

const CompanyReportsForm = () => {
  const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
  const [highlightTap, setHighlightTap] = useState(0);

  const [errorItems, setErrorItems] = useState({});

  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  const branchState = useSelector((state) => state.branch);
  const departmentState = useSelector((state) => state.department);

  const [reportTypeList, setReportTypeList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const getReport = useCallback(() => {
    dispatch(
      commonGetData(`admin/getReportType?report_type=${highlightTap + 1}`)
    );
    dispatch(fetchDepartmentData(user.user_id));
    dispatch(fetchBranchData());
  }, [highlightTap]);

  useEffect(() => {
    getReport();
  }, [highlightTap]);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      setReportTypeList(commonState.commonGetData);
    }
    if (branchState.brnGetSuccess) {
      setBranchList(branchState.brnGetData);
    }
    if (departmentState.depGetSuccess) {
      setDepartmentList(departmentState.depGetData);
    }
  }, [
    commonState.commonGetSuccess,
    branchState.brnGetSuccess,
    departmentState.depGetSuccess,
  ]);

  return (
    <div className="CompanyReportsForm" style={{ borderRadius: "7px" }}>
      <div className="EmployeeManagementTab" style={{ borderRadius: "7px" }}>
        {ManagementTab.map((item, i) => (
          <p
            style={highlightTap === i ? tabStyle : null}
            onClick={() => setHighlightTap(i)}
            key={i}
          >
            {item}
          </p>
        ))}
      </div>

      <form>
        <div className="CompanyReports-input">
          <label> Report Type</label>
          <select id="dropdown" className="dropdown">
            {reportTypeList.length > 0 && reportTypeList.map((row, idx) => {
              return (
                <option value={row.reportTypeId} key={idx}>
                  {row.report_name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="CompanyReports-input">
          <label>Select Branch</label>
          <select id="dropdown" className="dropdown">
          <option value="">Select Branch</option>
            {branchList?.map((row, idx) => {
              return <option value={row.branchId}>{row.branch_name}</option>;
            })}
          </select>
        </div>

        <div className="CompanyReports-input">
          <label>Select Department</label>
          <select id="dropdown" className="dropdown">
          <option value="">Select Department</option>
            {departmentList?.map((row, idx) => {
              return (
                <option value={row.departmentId}>{row.department_name}</option>
              );
            })}
          </select>
        </div>

        {highlightTap !== 3 && (
          <div className="CompanyReports-input">
            <label>Date</label>
            <input type="date" />
          </div>
        )}

        <div className="CompanyReports-input">
          <label>Format</label>
          <input value={highlightTap == 3 ? "pdf" : "csv"} readOnly/>
          {/* <select id="dropdown" className="dropdown" aria-readonly readOnly>
            <option value="csv" selected={highlightTap !== 3 ? "selected" : ""}> CSV </option>
            <option value="pdf" selected={highlightTap == 3 ? "selected" : ""}> PDF </option>
          </select> */}
          <div id="ReportType-checkbox">
            <input type="checkbox" />
            <label>Payroll Attendance </label>
          </div>
        </div>
      </form>
      <div className="CompanyReports-btn">
        <button>Gender Reports</button>
        <p>
          some reports might task time to generate. Once these are done, you can
          download all the report generate from the list below.
        </p>
      </div>
    </div>
  );
};

const ReportsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [usersList, setUsersList] = useState([
    {
      //   id: "1",
      //   name: "sakthi",
      //   jobTitle: "sathi@1",
      //   employeeId: "001",
      //   employeeType: "manager",
      //   dateOfJoin: "1/1/2009",
      //   dateOfBirth: "1/1/2010",
      //   mobileNumber: "123456789",
    },
  ]);

  const heading = [
    {
      name: "Name",
      label: "name",
      render: (rowData) => {
        return (
          <>
            <Link
              style={{ textDecoration: "underline", color: "blue" }}
              to={`user-info/${rowData.id}`}
            >
              {rowData.name}
            </Link>
          </>
        );
      },
    },
    {
      name: "REPORT TYPE",
      label: "jobTitle",
    },
    {
      name: "BRANCH",
      label: "employeeId",
    },
    {
      name: "DURATION",
      label: "employeeType",
    },
    {
      name: "fORMAT",
      label: "dateOfJoin",
    },
    {
      name: "GENERATED ON",
      label: "dateOfBirth",
    },
    {
      name: "STATUS",
      label: "mobileNumber",
    },
  ];

  return (
    <div className="ReportsTable-body">
      <div className="ReportsTable-head">
        <p>Recent Reports</p>
        <button>Refresh</button>
      </div>
      <div className="ReportsTable-table">
        <TableMui
          tableHead={heading}
          data={usersList}
          rowPagesPlay={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          Pages={10}
          editId={false}
        />
      </div>
    </div>
  );
};

export default CompanyReports;
