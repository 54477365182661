import { LiaRupeeSignSolid } from "react-icons/lia";
import { IoEyeOutline } from "react-icons/io5";
import { FaCircle } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdModeEdit } from "react-icons/md";


const PencilIcon = ({
  class_name,
  onClick = () => {
    console.log("");
  },
  color = "yellow",
  fontSize = "25px",
  fontWeight = "600",
  cursor = "pointer",
}) => {
  return (
    <MdModeEdit
      className={class_name}
      onClick={onClick}
      style={{ color:color, fontSize, fontWeight, cursor }}
    />
  );
};


const EyeIcon = ({
  class_name,
  onClick = () => {
    console.log("");
  },
  color = "#2ecc71",
  fontSize = "25px",
  fontWeight = "600",
  cursor = "pointer",
}) => {
  return (
    <IoEyeOutline
      className={class_name}
      onClick={onClick}
      style={{ color, fontSize, fontWeight, cursor }}
    />
  );
};

const RupeesIcon = ({
  class_name,
  onClick = () => {
    console.log("");
  },
  color = "#000",
  fontSize = "25px",
  fontWeight = "600",
}) => {
  return (
    <LiaRupeeSignSolid
      className={class_name}
      onClick={onClick}
      style={{ color, fontSize, fontWeight }}
    />
  );
};

const Circle = ({
  class_name,
  onClick = () => {
    console.log("");
  },
  color = "#000",
  fontSize = "10px",
  fontWeight = "600",
}) => {
  return (
    <FaCircle
      className={class_name}
      onClick={onClick}
      style={{ color, fontSize, fontWeight }}
    />
  );
};
export {PencilIcon, RupeesIcon, EyeIcon, Circle };
