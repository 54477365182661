// Author :  G MANIKAM : Super admin dashboard
import { useState } from "react";
import { Cards } from "../../../components/cards/Cards";
// images
import UserAvatar from "../../../assets/Images/user-avatar.jpg";
import { Col, Row } from "react-bootstrap";
import { BarChart } from "@mui/x-charts/BarChart";
import Avatar from "@mui/material/Avatar";
import { Form } from "react-bootstrap";

const Index = () => {
  const [info, setInfo] = useState([
    {
      avatarSrc: <UserAvatar />,
      title: "Total Employer",
      count: 10,
      supCount: 15,
    },
    {
      avatarSrc: <UserAvatar />,
      title: "Total Demo",
      count: 10,
      supCount: 15,
    },

    {
      avatarSrc: <UserAvatar />,
      title: "Total Past Employer",
      count: 10,
      supCount: 15,
    },
  ]);

  const [paymentType, setPaymentType] = useState("monthly");
  const filterPayment = (e) => {
    const {
      target: { value },
    } = e;
    setPaymentType(value);
  };

  return (
    <div className="Dashboard">
      <div className="Dashboard-head">
        <div className="Dashboard-info">
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 70, height: 70 }}
          />
          <h1>Company Name</h1>
        </div>
        <div className="Dashboard-card">
          <div className="Dashboard-card-items">
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"Total Employer"}
              count={134}
              supCount={134}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"Total Demo"}
              count={134}
              supCount={134}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"Total Amount"}
              count={134}
              supCount={134}
            />
          </div>
        </div>
      </div>

      <div className="Dashboard-bar-chart">
        <Row className="mb-5">
          <Col sm="4">
            <Form.Group className="flex-align-center">
              <Form.Label className="nowrap">Filter data</Form.Label>
              <Form.Select onChange={filterPayment}>
                <option value="monthly">Monthly Payment</option>
                <option value="weekly">Weekly Payment</option>
                <option value="daily">Daily Payment</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {paymentType === "monthly" && (
          <BarChart
            series={[
              { data: [30, 44, 24, 34, 30, 44, 24, 34, 30, 44, 24, 34] },
              { data: [30, 44, 24, 34, 30, 44, 24, 34, 30, 44, 24, 34] },
            ]}
            height={290}
            xAxis={[
              {
                data: [
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                  "January",
                  "February",
                  "March",
                ],
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        )}
        {paymentType === "weekly" && (
          <BarChart
            series={[
              { data: [30, 44, 24, 34, 30, 44, 24] },
              { data: [30, 44, 24, 34, 30, 44, 24] },
            ]}
            height={290}
            xAxis={[
              {
                data: [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ],
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        )}
        {paymentType === "daily" && (
          <BarChart
            series={[{ data: [30] }, { data: [30] }]}
            height={290}
            xAxis={[
              {
                data: ["Today"],
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
