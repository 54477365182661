import React from "react";

const Index = () => {
  return (
    <div style={{ height: "100%" }}>
      <h1
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        Coming Soon
      </h1>
    </div>
  );
};

export default Index;
