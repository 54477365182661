import { useState } from "react";
import "./PenaltyOvertimeDetails.css";
import { formValidation } from "../../../hooks/formValidation";
import ButtonCom from "../../../components/button/button";
import { Form, Row, Col } from "react-bootstrap";
const PenaltyOvertimeDetails = () => {
  const tabItem = [
    { name: "Early Leave Policy", item: <EarlyLeavePolicy /> },
    { name: "Late Coming Policy", item: <LateComingPolicy /> },
    { name: "Over Time Policy", item: <OverTimePolicy /> },
  ];
  const [highlightTap, setHighlightTap] = useState(0);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
    } else {
    }
  };

  return (
    <div className="PenaltyOvertimeDetails">
      <div className="PenaltyOvertimeDetails-body">
        <p> Penalty & Overtime Details</p>
        <div className="PenaltyOvertimeDetail-card">
          <div className="PenaltyOvertimeDetail-tab-List">
            {tabItem.map((item, i) => (
              <p
                style={{
                  borderBottom:
                    highlightTap === i ? "2px solid rgb(147, 147, 147)" : "",
                  fontSize: "16px",
                }}
                key={i}
                onClick={() => setHighlightTap(i)}
              >
                {item.name}
              </p>
            ))}
          </div>

          <ButtonCom
            type="button"
            class_name="p_btn"
            text="Add Document"
            url_or_onclick={handleSubmit}
            icon={null}
            width={""}
          />
        </div>

        <div className="PenaltyOvertimeDetail-tab-item">
          {tabItem.map((item, i) => (
            <div key={i}>{highlightTap === i ? <>{item.item}</> : <></>}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

const EarlyLeavePolicy = () => {
  const [addStaff, setAddStaff] = useState({
    name: "",
    jobTitle: "",
    branch: "",
    department: "",
    code: "",
    number: "sasa",
    loginOtp: "",
    gender: "",
    officeEmailId: "",
    dateOfJoining: "",
    address: "",
  });

  const handelChange = (e) => {
    const { name, value } = e.target;
    setAddStaff((pv) => ({ ...pv, [name]: value }));
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const { valueTrim, errorItem, isValid } = formValidation(addStaff);
    const { name, branch, number, loginOtp } = errorItem;

    // if (name && branch && number && loginOtp) {
    //   return setErrorItems({ name, branch, number, loginOtp });
    // } else {
    //   setErrorItems({});
    // }
    // if (isNaN(valueTrim.number)) {
    //   return setErrorMag({ number: "only Enter Number" });
    // }

    //    if (valueTrim.officeEmailId && !emailValidator(valueTrim.officeEmailId)) {
    //      return setErrorMag({ officeEmailId: "This Not Valid Email Id " });
    //    }

    // setErrorMag({});
    console.log(addStaff);

    setAddStaff({
      name: "",
      jobTitle: "",
      branch: "",
      department: "",
      code: "",
      number: "",
      loginOtp: "",
      gender: "",
      officeEmailId: "",
      dateOfJoining: "",
      address: "",
    });
  };
  return (
    // <div className="BasicDetails-form">
    <Form onSubmit={handelSubmit}>
      <Form.Group>
        <div style={{ display: "flex", justifyContent: "start", gap: "20px" }}>
          <Form.Label>Fine Type</Form.Label>
          <Form.Check
            type="radio"
            name="fineType"
            value={"Daily"}
            label="Daily"
            onChange={handelChange}
            required
          />
          <Form.Check
            type="radio"
            name="fineType"
            value={"Hourly"}
            label="Hourly"
            onChange={handelChange}
            required
          />
        </div>
      </Form.Group>
      <Row>
        <Col sm="6">
          <Form.Group className="mt-3">
            <Form.Label>
              Grace Period (mis) <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name=""
              value=""
              placeholder="Enter grace period (mis)"
              onChange={handelChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid grace period
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Form.Group className="mt-3">
            <Form.Label>
              Fine Amount <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control name="" value="" placeholder="Enter fine amount" />
            <Form.Control.Feedback type="invalid">
              Please enter valid fine amount
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm="6">
          <Form.Group className="mt-3">
            <Form.Label>
              Waive Off Days <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control name="" value="" placeholder="Enter waive off days" />
            <Form.Control.Feedback type="invalid">
              Please enter valid waive off days
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Form>
    // </div>
  );
};

///LateComingPolicy

const LateComingPolicy = () => {
  const [errorItems, setErrorItems] = useState({});
  const [errorMsg, setErrorMag] = useState({});

  const [addStaff, setAddStaff] = useState({
    name: "",
    jobTitle: "",
    branch: "",
    department: "",
    code: "",
    number: "sasa",
    loginOtp: "",
    gender: "",
    officeEmailId: "",
    dateOfJoining: "",
    address: "",
  });

  const handelChange = (e) => {
    const { name, value } = e.target;
    setAddStaff((pv) => ({ ...pv, [name]: value }));
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const { valueTrim, errorItem, isValid } = formValidation(addStaff);
    const { name, branch, number, loginOtp } = errorItem;

    if (name && branch && number && loginOtp) {
      return setErrorItems({ name, branch, number, loginOtp });
    } else {
      setErrorItems({});
    }
    if (isNaN(valueTrim.number)) {
      return setErrorMag({ number: "only Enter Number" });
    }

    //    if (valueTrim.officeEmailId && !emailValidator(valueTrim.officeEmailId)) {
    //      return setErrorMag({ officeEmailId: "This Not Valid Email Id " });
    //    }

    setErrorMag({});
    console.log(addStaff);

    setAddStaff({
      name: "",
      jobTitle: "",
      branch: "",
      department: "",
      code: "",
      number: "",
      loginOtp: "",
      gender: "",
      officeEmailId: "",
      dateOfJoining: "",
      address: "",
    });
  };
  return (
    // <div className="BasicDetails-form">
    <Form onSubmit={handelSubmit}>
      <Form.Group>
        <div style={{ display: "flex", justifyContent: "start", gap: "20px" }}>
          <Form.Label>Fine Type</Form.Label>
          <Form.Check
            type="radio"
            name="fineType"
            value={"Daily"}
            label="Daily"
            onChange={handelChange}
            required
          />
          <Form.Check
            type="radio"
            name="fineType"
            value={"Hourly"}
            label="Hourly"
            onChange={handelChange}
            required
          />
        </div>
      </Form.Group>
      <Row>
        <Col sm="6">
          <Form.Group className="mt-3">
            <Form.Label>
              Grace Period (mis) <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name=""
              value=""
              placeholder="Enter grace period (mis)"
              onChange={handelChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid grace period
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Form.Group className="mt-3">
            <Form.Label>
              Fine Amount <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control name="" value="" placeholder="Enter fine amount" />
            <Form.Control.Feedback type="invalid">
              Please enter valid fine amount
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm="6">
          <Form.Group className="mt-3">
            <Form.Label>
              Waive Off Days <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control name="" value="" placeholder="Enter waive off days" />
            <Form.Control.Feedback type="invalid">
              Please enter valid waive off days
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Form>
    // </div>
  );
};

//OverTimePolicy

const OverTimePolicy = () => {
  const [errorItems, setErrorItems] = useState({});
  const [errorMsg, setErrorMag] = useState({});

  const [addStaff, setAddStaff] = useState({
    name: "",
    jobTitle: "",
    branch: "",
    department: "",
    code: "",
    number: "sasa",
    loginOtp: "",
    gender: "",
    officeEmailId: "",
    dateOfJoining: "",
    address: "",
  });

  const handelChange = (e) => {
    const { name, value } = e.target;
    setAddStaff((pv) => ({ ...pv, [name]: value }));
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const { valueTrim, errorItem, isValid } = formValidation(addStaff);
    const { name, branch, number, loginOtp } = errorItem;

    if (name && branch && number && loginOtp) {
      return setErrorItems({ name, branch, number, loginOtp });
    } else {
      setErrorItems({});
    }
    if (isNaN(valueTrim.number)) {
      return setErrorMag({ number: "only Enter Number" });
    }

    setErrorMag({});

    setAddStaff({
      name: "",
      jobTitle: "",
      branch: "",
      department: "",
      code: "",
      number: "",
      loginOtp: "",
      gender: "",
      officeEmailId: "",
      dateOfJoining: "",
      address: "",
    });
  };
  return (
    <div>
      <div className="BasicDetails-form">
        <Form onSubmit={handelSubmit}>
          <Row>
            <Col sm="6">
              <Form.Group>
                <Form.Label>
                  Grace Period (mis) <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  name=""
                  value=""
                  placeholder="Enter grace period (mis)"
                  onChange={handelChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid grace period
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Form.Group className="mt-3">
                <Form.Label>
                  Extra Hours pay <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  name=""
                  value=""
                  placeholder="Enter extra hours pay"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid extra hours pay
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <Form.Group>
                <Form.Label>
                  Public Holiday Pay <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  name=""
                  value=""
                  placeholder="Enter public holiday pay"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid public holiday pay
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <Form.Group>
                <Form.Label>
                  Week Off Pay <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  name=""
                  value=""
                  placeholder="Enter waive off pay"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter valid week off pay
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default PenaltyOvertimeDetails;
