import "./CompanyDepartments.css";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createDepartment,
  fetchDepartmentData,
  initState,
} from "../../../redux/slices/departmentSlice";
import {
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import ButtonCom from "../../../components/button/button";

const CompanyDepartments = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const dispatch = useDispatch();
  const state = useSelector((state) => state.department);
  const commonState = useSelector((state) => state.common);

  // console.log(state)

  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [departmentList, setDepartmentList] = useState([]);

  const [addDepartment, setAddDepartment] = useState({
    userId: user.user_id,
    staffId: null,
    departmentId: "",
    departmentName: "",
  });
  // Edit functions

  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = (row) => {
    setIsEdit(true);
    setAddDepartment({
      ...addDepartment,
      staffId: row.staff_id,
      departmentName: row.department_name,
      departmentId: row.departmentId,
    });
    handleClickOpen();
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateDepartment",
      data: addDepartment,
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.departmentId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteDepartment",
      data: {
        userId: user.user_id,
        departmentId: delId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      getDepartment();
      setIsEdit(false);
      setDelId(null);
      dispatch(initCommonState());
    }
    if (commonState.commonError) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      getDepartment();
      setIsEdit(false);
      setDelId(null);
      dispatch(initCommonState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const heading = [
    {
      name: "Department Name",
      label: "department_name",
    },
    {
      name: "Status",
      label: "status",
      render: (rowData) => {
        return rowData.status == 1 ? "Active" : "InActive";
      },
    },
    {
      name: "",
      label: "id",
      width: 200,
      render: (rowData) => {
        return (
          <>
            <div className="Branches-table-button">
              <CiEdit
                style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
                onClick={() => {
                  handleEdit(rowData);
                }}
              />
              <MdOutlineDeleteSweep
                style={{ cursor: "pointer", fontSize: 30, color: "red" }}
                onClick={() => handleDelete(rowData)}
              />
            </div>
          </>
        );
      },
    },
  ];

  const getDepartment = useCallback((data) => {
    dispatch(fetchDepartmentData(user.user_id));
  });

  useEffect(() => {
    getDepartment();
  }, []);

  useEffect(() => {
    if (state.depGetSuccess) {
      setDepartmentList(state.depGetData);
    }
    if (state.depGetError) {
    }
  }, [state.depGetSuccess, state.depGetError]);

  useEffect(() => {
    if (state.depSuccess) {
      setDepartmentList(state.depGetData);
      getDepartment();
    }
    if (state.depError) {
    }
  }, [state.depSuccess, state.depError]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValidated(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddDepartment((pv) => ({ ...pv, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(createDepartment(addDepartment));
      setAddDepartment({
        ...addDepartment,
        departmentName: "",
      });
    }
  };

  useEffect(() => {
    if (state.depSuccess) {
      getDepartment();
      toast.success(state.depMessage);
      dispatch(initState());
      handleClose();
    }
    if(state.depError){
      toast.error(state.depMessage);
      dispatch(initState());
      handleClose();
    }
  }, [state.depSuccess, state.depError]);

  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Departments</p>
      </div>

      <div className="SettingBody-card mt-4">
        <div className="Branches-body">
          <div className="Branches-table">
            <div className="Departments-btn mb-3">
              <h1> Departments</h1>
              <ButtonCom
                type={"button"}
                class_name={"p_btn"}
                text={"Add Departments"}
                url_or_onclick={handleClickOpen}
                icon={<AddIcon />}
                width={""}
              />
            </div>
            <TableMui
              tableHead={heading}
              data={departmentList}
              rowPagesPlay={true}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              Pages={10}
              editId={false}
            />
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>
              <h3>{isEdit ? "Update" : "Add New"} Department</h3>
            </DialogTitle>
            <Form
              noValidate
              validated={validated}
              onSubmit={isEdit ? handleEditSubmit : handleSubmit}
            >
              <DialogContent>
                <Row>
                  <Col sm="12">
                    <Form.Label>Department Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter department name"
                      name="departmentName"
                      value={addDepartment.departmentName}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid department name
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="gray"
                  onClick={handleClose}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "30px",
                    backgroundColor: "gray",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "30px",
                    backgroundColor: "blue",
                    color: "#fff",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Dialog>

          <div className="Branches-btn"></div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDepartments;
