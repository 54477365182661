import { CircularProgress, ThemeProvider } from "@mui/material";
import "./App.css";
import { Suspense } from "react";
import theme from "./theme";
import routes from "./routes";
import { Route, Routes } from "react-router-dom";
import PublicRoutes from "./routes/publicRoutes";
import PrivateRoutes from "./routes/privateRoutes";
import { useSelector } from "react-redux";
import { SnackBar as AppSnackBar } from "./components/shared";

function App() {
  const { isLoggedIn, superAdmin } = useSelector((state) => state.auth);

  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <div>
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route
            element={
              <PublicRoutes isLoggedIn={isLoggedIn} superAdmin={superAdmin} />
            }
          >
            {routes().publicRoutes.map(
              ({ path, Component, children }, index) => (
                <Route path={path} element={Component} key={index}>
                  {children?.map(({ path, element }, i) => (
                    <Route path={path} element={element} key={i} />
                  ))}
                </Route>
              )
            )}
          </Route>

          <Route
            
          >
            {routes().onboardingRoute.map(
              ({ path, Component, children }, index) => (
                <Route path={path} element={Component} key={index}>
                  {children?.map(({ path, element }, i) => (
                    <Route path={path} element={element} key={i} />
                  ))}
                </Route>
              )
            )}
          </Route>

          <Route
            element={
              <PrivateRoutes isLoggedIn={isLoggedIn} superAdmin={superAdmin} />
            }
          >
            {routes().privateRoutes.map(
              ({ path, Component, children }, index) => (
                <Route key={index} path={path} element={Component}>
                  {children?.map(({ path, Component, children }, i) => (
                    <Route path={path} element={Component} key={i}>
                      {children?.map(({ path, Component, children }, i) => (
                        <Route path={path} element={Component} key={i} />
                      ))}
                    </Route>
                  ))}
                </Route>
              )
            )}
          </Route>
        </Routes>
        <AppSnackBar />
      </Suspense>
    </ThemeProvider>
    // <div>
    //   <LandingPageLayOut/>
    // </div>
  );
}

export default App;
