import "./SalaryDetails.css";
import { lazy, useState } from "react";
import ButtonCom from "../../../components/button/button";
import { Form, Row, Col } from "react-bootstrap";
import makeAnimated from "react-select/animated";

const Earning = lazy(() => import("./Details/Earning"));
const Compliance = lazy(() => import("./Details/Compliances"));
const Deduction = lazy(() => import("./Details/Deductions"));
const SalaryDetails = () => {
  // const [saveData, setSAveData] = useState({
  //   designationName: "",
  //   contributes: [],
  // });

  // const handleChange = (e) => {
  //   setSAveData({
  //     ...saveData,
  //     contributes: e,
  //   });
  // };

  const [earnings, setEarnings] = useState([
    {
      id: "basic1",
      head: "Basic",
      calculation: "basic",
      amount: 0,
    },

    {
      id: "hra1",
      head: "HRA",
      calculation: "hra",
      amount: 0,
    },

    {
      id: "dearness1",
      head: "Dearness Allowance",
      calculation: "dearness",
      amount: 0,
    },
  ]);
  const addEarningField = (e) => {
    const { value, label } = e;
    setEarnings([
      ...earnings,
      {
        id: value,
        head: label,
        calculation: "",
        amount: 0,
      },
    ]);
  };
  const removeEarningFields = (e, index) => {
    e.preventDefault();
    const rows = [...earnings];
    rows.splice(index, 1);
    setEarnings(rows);
  };
  const handleEarningChanges = (index, e) => {
    const { name, value } = e.target;
    const list = [...earnings];
    list[index][name] = value;
    setEarnings(list);
  };
  // End of earning functions

  const [deductions, setDeductions] = useState([]);
  const addDeductionsField = (e) => {
    const { value, label } = e;
    setDeductions([
      ...deductions,
      {
        id: value,
        head: label,
        calculation: "",
        amount: 0,
      },
    ]);

    setTimeout(() => {
      console.log(deductions);
    }, 800);
  };
  const removeDeductionsFields = (e, index) => {
    e.preventDefault();
    const rows = [...deductions];
    rows.splice(index, 1);
    setDeductions(rows);
  };
  const handleDeductionsChanges = (index, e) => {
    const { name, value } = e.target;
    const list = [...deductions];
    list[index][name] = value;
    setDeductions(list);
  };

  return (
    <div className="salary-details-container">
      <div className="page-top-bar">
        <h1>Salary Details</h1>
        <ButtonCom
          type="button"
          class_name={"p_btn"}
          text="Update Details"
          icon={null}
          width={""}
        />
      </div>

      <Form>
        <div className="page-body">
          <Row>
            <Col sm="3">
              <Form.Group>
                <Form.Label>Effective Date of Change</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <Form.Label>Salary Type</Form.Label>
                <Form.Select style={{ color: "gray" }}>
                  <option value="">Select Salary Type</option>
                  <option value="per-month">Per Month</option>
                  <option value="per-week">Per Week</option>
                  <option value="per-day">Per day</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="3">
              <Form.Group>
                <Form.Label>Salary Structure</Form.Label>
                <Form.Select style={{ color: "gray" }}>
                  <option value="">Select Salary Structure</option>
                  <option value="custom">Custom</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm="2">
              <Form.Group>
                <Form.Label>CTC Amount</Form.Label>
                <Form.Control type="number" placeholder="0" />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="page-body mt-1">
          <Earning
            earnings={earnings}
            addField={addEarningField}
            removeFields={removeEarningFields}
            handleChanges={handleEarningChanges}
          />
          <Compliance />
          <Deduction
            deductions={deductions}
            addField={addDeductionsField}
            removeFields={removeDeductionsFields}
            handleChanges={handleDeductionsChanges}
          />

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </Form>
    </div>
  );
};

export default SalaryDetails;
