// User input fields
const inputsOne = [
  {
    name: "staff_name",
    label: "Name",
    placeholder: "Enter Name",
    required: true,
  },
];

const inputsTwo = [
  // {
  //   name: "personalEmail",
  //   label: "Personal Email Id",
  //   placeholder: "Enter Office Email Id",
  //   required: true
  // },

  {
    name: "fatherName",
    label: "Father Name",
    placeholder: "Enter Father Name",
    required: true
  },

  {
    name: "dateOfBirth",
    label: "Date Of Birth",
    placeholder: "Enter Date Of Birth",
    type: "date",
    required: true
  },

  {
    name: "gender",
    label: "Gender",
    chi: true,
    required: true
  },

  {
    name: "nationality",
    label: "Nationality",
    chi: [
      { label: "Select Nationality", value: "" },
      { label: "Indian", value: "Indian" },
      { label: "Others", value: "Others" },
    ],
    required: false
  },

  {
    name: "bloodGroup",
    label: "Blood Group",
    chi: true,
    required: true
  },
  {
    name: "maritalStatus",
    label: "Marital Status",
    chi: true,
    required: true
  },
];

const emergencyContact = [
  {
    name: "emergencyContactName",
    label: "Name",
    placeholder: "Enter Name",
  },
  {
    name: "emergencyContactNumber",
    label: "Contact Number",
    placeholder: "Contact Number",
  },
  {
    name: "emergencyContactRelation",
    label: "Relationship",
    placeholder: "Relationship",
  },
];

const governmentIdsInputsOnes = [
  {
    name: "gov_aadhar",
    label: "Aadhaar",
    placeholder: "Enter Aadhaar Number",
  },
  { name: "gov_pan", label: "PAN", placeholder: "Enter PAN Number" },
  {
    name: "gov_drivinglicense",
    label: "Driving License",
    placeholder: "Enter Driving License Number",
  },
  {
    name: "gov_voterId",
    label: "Voter Id",
    placeholder: "Enter Voter Id Number",
  },
  {
    name: "gov_passportnumber",
    label: "Passport Number",
    placeholder: "Enter Passport Number",
  },
  { name: "gov_uan", label: "UAN", placeholder: "Enter UNA" },
];

const addressInfos = [
  // {
  //   name: "currentAddress",
  //   label: "Current Address",
  //   placeholder: "Enter Current Address",
  // },
  // {
  //   name: "permanentAddress",
  //   label: "Permanent Address",
  //   placeholder: "Enter permanent Address",
  // },
  {
    name: "address_line_1",
    label: "Address Line 1",
    placeholder: "Address Line 1",
    required: true
  },
  {
    name: "address_line_2",
    label: "Address Line 2",
    placeholder: "Address Line 2",
    required: false
  },
  {
    name: "city",
    label: "City",
    placeholder: "City",
    required: true
  },
  {
    name: "state",
    label: "State",
    placeholder: "State",
    required: true
  },
  {
    name: "pincode",
    label: "Pincode",
    placeholder: "Pincode",
    required: true
  },
  {
    name: "country",
    label: "Country",
    placeholder: "Country",
    required: true
  },
];

const addressInfo2 = [
  // {
  //   name: "currentAddress",
  //   label: "Current Address",
  //   placeholder: "Enter Current Address",
  // },
  // {
  //   name: "permanentAddress",
  //   label: "Permanent Address",
  //   placeholder: "Enter permanent Address",
  // },
  {
    name: "address_line_11",
    label: "Address Line 1",
    placeholder: "Address Line 1",
    required: true
  },
  {
    name: "address_line_22",
    label: "Address Line 2",
    placeholder: "Address Line 2",
    required: false
  },
  {
    name: "city1",
    label: "City",
    placeholder: "City",
    required: true
  },
  {
    name: "state1",
    label: "State",
    placeholder: "State",
    required: true
  },
  {
    name: "pincode1",
    label: "Pincode",
    placeholder: "Pincode",
    required: true
  },
  {
    name: "country1",
    label: "Country",
    placeholder: "Country",
    required: true
  },
];


const qualification = [

{
  name: "country",
  label: "Country",
  placeholder: "Country",
},
]

export {
  inputsOne,
  inputsTwo,
  emergencyContact,
  governmentIdsInputsOnes,
  addressInfos,
  addressInfo2
};
