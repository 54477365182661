import React, { useMemo, useState } from "react";
import "./TableMui.css";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloseIcon from "@mui/icons-material/Close";

const Table = ({
  tableHead,
  data,
  Pages,
  currentPage,
  setCurrentPage,
  rowPagesPlay,
  editId,
  setEditId,
  update,
}) => {
  const [editItem, setEditItem] = useState({});

  useMemo(() => {
    setEditItem(data.find((item) => item.id === editId) || {});
  }, [editId]);

  const nextPage = () => {
    if (currentPage < Pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleEditItem = (e) => {
    const { name, value } = e.target;
    setEditItem({ ...editItem, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    update(editItem);
  };

  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            {tableHead
              ? tableHead?.map((item, index) => (
                  <th key={index} style={{ width: item.width }}>
                    {item.name}
                  </th>
                ))
              : "Nodata Header"}
          </tr>
        </thead>
        <tbody>
          {data ? (
            data?.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {tableHead?.map((item, i) => (
                  <td key={i}>
                    {item.render ? (
                      <>
                        {editId === rowData.id ? (
                          <div className="table_btn">
                            <DriveFolderUploadIcon
                              sx={{ color: "green", fontSize: "26px" }}
                              className="update"
                              type="submit"
                              onClick={handleSubmit}
                            />
                            <CloseIcon
                              sx={{ color: "blue", fontSize: "23px" }}
                              onClick={() => setEditId(null)}
                            />
                          </div>
                        ) : (
                          item.render(rowData)
                        )}
                      </>
                    ) : (
                      <>
                        {editId === rowData.id ? (
                          <>
                            {}
                            <input
                              id="tableInput"
                              value={editItem[item.label]}
                              name={item.label}
                              onChange={handleEditItem}
                            />
                            {}
                          </>
                        ) : (
                          rowData[item.label]
                        )}
                      </>
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <p>No data body</p>
          )}
        </tbody>
      </table>
      {rowPagesPlay && (
        <>
          <div className="row-page">
            <div>
              <label>Rows per page: 5 </label>
              <label> pages: {Pages} </label>
              <label className="crount_page">
                <button onClick={previousPage}> &#9664;</button>
                {currentPage ? currentPage : "0"}
                <button onClick={nextPage}> &#9654;</button>
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;
