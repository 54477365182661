import { useEffect, useState, useCallback, useRef } from "react";
import "./EmployeeManagement.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
// React Data Grid Component
import { AgGridReact } from "ag-grid-react";
import { FaDownload } from "react-icons/fa6";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "react-bootstrap/Form";
import ExcelReader from "../../components/xlsx/Index";
import { ToastContainer, toast } from "react-toastify";
// Components
import AttendanceList from "./Lists/AttendanceList";
import BankList from "./Lists/BankList";
import SalaryList from "./Lists/SalaryList";
import LeaveBalancePolicyList from "./Lists/LeaveBalancePolicyList";
import PenaltyOvertime from "./Lists/PenaltyOvertime";
import Dropdown from "react-bootstrap/Dropdown";
// files
import Salarybox_Bulk_Add_Employee_Template from "../../assets/files/mutiple_staff_data.xlsx";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  commonSaveData,
  commonGetData,
  commonGetDataThird,
  commonGetDataTwo,
  initState,
} from "../../redux/slices/commonSlice";
import {
  fetchBranchData,
  initBranchState,
} from "../../redux/slices/branchSlice";

const EmployeeManagement = () => {
  return <EmployeeManagementTab />;
};

const ManagementTab = [
  "Staff Details",
  "Attendance Details",
  "Bank Details",
  "Salary Details",
  "Leave Balances & Policy",
  "Penalty & Overtime ",
];
const tabStyle = { borderBottom: "4px solid #566573", color: "#566573" };

const EmployeeManagementTab = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const branchState = useSelector((state) => state.branch);
  const [open, setOpen] = useState(false);
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const [highlightTap, setHighlightTap] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [branchList, setBranchList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [multiStaff, setMultiStaff] = useState({
    branch: null,
    userId: user.user_id,
    staffDetails: [],
  });
  const [userRecord, setUserRecord] = useState({});

  const getBranchList = useCallback(() => {
    dispatch(fetchBranchData(user.user_id));
    dispatch(
      commonGetDataThird(`admin/checkUserRecords?userId=${user.user_id}`)
    );
  }, []);

  useEffect(() => {
    getBranchList();
  }, []);

  useEffect(() => {
    if (branchState.brnGetSuccess) {
      setBranchList(branchState.brnGetData);
      dispatch(initBranchState());
    }
    if (state.commonGetThirdSuccess) {
      setUserRecord(state.commonGetThirdData);
      dispatch(initState());
    }
  }, [branchState.brnGetSuccess, state.commonGetThirdSuccess]);

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = Salarybox_Bulk_Add_Employee_Template; // Replace with your file path
    link.download = "mutiple_staff_data.xlsx"; // The name for the downloaded file
    link.click();
  };

  const handleBranch = (e) => {
    const { value } = e.target;
    setMultiStaff({
      ...multiStaff,
      branch: parseInt(value),
    });
  };

  const handleXlsxRead = (data) => {
    setMultiStaff({
      ...multiStaff,
      staffDetails: data,
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let d = {
        path: "multipleStaff",
        data: multiStaff,
      };

      dispatch(commonSaveData(d));
    }
  };

  useEffect(() => {
    if (state.commonSuccess) {
      toast.success(state.commonMessage);
      setMultiStaff({
        ...multiStaff,
        branch: "",
        staffDetails: [],
      });
      handleClose();
      dispatch(
        commonGetDataTwo(`admin/checkUserRecords?userId=${user.user_id}`)
      );
    }
    if (state.commonError) {
      toast.error(state.commonMessage);
      handleClose();
    }
  }, [state.commonSuccess, state.commonError]);

  useEffect(() => {
    if (state.commonGetTwoSuccess) {
      setUserRecord(state.commonGetTwoData);
      dispatch(initState());
    }
  }, [branchState.brnGetSuccess, state.commonGetTwoSuccess]);

  const handleUserRecord = (type) => {
    if (
      userRecord.isBranchRecord &&
      userRecord.isDepartmentRecord &&
      userRecord.isEmployerId &&
      userRecord.isShift
    ) {
      if (type == "home") {
        handleClickOpen();
      } else {
        document.getElementById(type).click();
      }
    } else {
      toast.error(
        `Please add ${
          (userRecord.isBranchRecord ? "" : "Branch",
          userRecord.isDepartmentRecord ? "" : "Department",
          userRecord.isEmployerId ? "" : "EmployerId",
          userRecord.isShift ? "" : "Shift")
        } data first`
      );
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          className="pb-2 mb-4"
          style={{
            borderBottom: "1px solid lightgray",
            fontSize: "20px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#d6eaf8",
          }}
        >
          Add Multiple Staff
        </DialogTitle>

        <DialogContent>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div
              className="mb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p>Step 1. Select Branch to add staff</p>
              <Form.Select
                style={{ width: "45%" }}
                required
                onChange={handleBranch}
              >
                <option value="">Select branch</option>
                {branchList?.map((r, i) => {
                  return (
                    <option value={r.branchId} key={i}>
                      {r.branch_name}
                    </option>
                  );
                })}
              </Form.Select>
            </div>

            <div
              className="mb-3"
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p>Step 2. Download staff details template</p>
              <Form.Control
                placeholder="Download Template"
                readOnly
                onClick={downloadFile}
                style={{ width: "45%" }}
              />
              {multiStaff.branch && (
                <FaDownload
                  onClick={downloadFile}
                  style={{
                    position: "absolute",
                    right: "10px",
                    color: "lime",
                    fontWeight: "bold",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>

            <div
              className="mb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p>
                Step 3. Edit Downloaded file and add staff details template
                <br />
                <span style={{ fontSize: "12px" }}>
                  Please refer to instructions sheet in download template for
                  formatting guide
                </span>
              </p>
            </div>

            <div
              className="mb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p>Step 4. Upload your staff list</p>
              <div style={{ width: "45%" }}>
                <ExcelReader onChange={handleXlsxRead} />
              </div>
            </div>

            <DialogActions
              className="pt-3"
              style={{ borderTop: "1px solid lightgray" }}
            >
              <Button variant="contained" color="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="info" type="submit">
                Save
              </Button>
            </DialogActions>
          </Form>
        </DialogContent>
      </Dialog>

      <div className="EmployeeManagement">
        <div className="EmployeeManagement-card-two">
          <div className="EmployeeManagementTab">
            {ManagementTab.map((item, i) => (
              <p
                style={highlightTap === i ? tabStyle : null}
                onClick={() => setHighlightTap(i)}
                key={i}
              >
                {item}
              </p>
            ))}
          </div>

          <Link to={"add-staff"} id="goToAddStaff" style={{ display: "none" }}>
            Add Staff
          </Link>

          <Dropdown>
            <Dropdown.Toggle
              style={{
                backgroundColor: "#fff",
                color: "#000",
                border: "none",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              id="dropdown-basic"
            >
              Add Staff
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="#"
                onClick={() => {
                  handleUserRecord("goToAddStaff");
                }}
              >
                Add Staff
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() => {
                  handleUserRecord("home");
                }}
              >
                Add Multiple staff
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="EmployeeManagement-card-three">
          {highlightTap == 0 && (
            <Categories dispatch={dispatch} user={user} state={state} />
          )}
          {highlightTap == 1 && (
            <AttendanceList dispatch={dispatch} user={user} state={state} />
          )}
          {highlightTap == 2 && (
            <BankList dispatch={dispatch} user={user} state={state} />
          )}
          {highlightTap == 3 && (
            <SalaryList dispatch={dispatch} user={user} state={state} />
          )}
          {highlightTap == 4 && (
            <LeaveBalancePolicyList
              dispatch={dispatch}
              user={user}
              state={state}
            />
          )}
          {highlightTap == 5 && (
            <PenaltyOvertime dispatch={dispatch} user={user} state={state} />
          )}
        </div>
        <div className="EmployeeManagement-card-fore"></div>
      </div>
    </>
  );
};

const Categories = ({ dispatch, user, state }) => {
  const path = `admin/getEmployeeStaffList?userId=${user.user_id}`;

  const [usersList, setUsersList] = useState([]);

  const getEmployeeList = useCallback(() => {
    dispatch(commonGetData(path));
  }, []);

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    if (state.commonGetSuccess) {
      if (state.commonGetData == undefined || state.commonGetData == "") {
        setUsersList([]);
        dispatch(initState());
      } else {
        setUsersList(state.commonGetData);
        dispatch(initState());
      }
    }
  }, [state.commonGetSuccess]);

  const columns = [
    {
      headerName: "Name",
      field: "full_name",
      pinned: true,
      cellRenderer: (params) => {
        return (
          <Link to={`user-info/${params.data.staffId}`} className="table-link">
            {params.value}
            <ArrowForwardIcon style={{ fontSize: "16px" }} />
          </Link>
        );
      },
    },
    {
      headerName: "Job Title",
      field: "job_title",
    },
    {
      headerName: "Employee Id",
      field: "employee_id",
    },
    {
      headerName: "Employee Role",
      field: "emp_role",
    },
    {
      headerName: "Date of Join",
      field: "date_of_join",
    },
    {
      headerName: "Date of Leaving",
      field: "date_of_leaving",
    },
    {
      headerName: "Date of Birth",
      field: "dob",
    },
    {
      headerName: "Mobile Number",
      field: "mobile_number",
    },
    {
      headerName: "Personal Email",
      field: "personal_email",
    },
    {
      headerName: "Official Email",
      field: "official_email",
    },
    {
      headerName: "Marital Status",
      field: "marital_status",
    },
    {
      headerName: "Gender",
      field: "gender",
    },
    {
      headerName: "Blood Group",
      field: "blood_group",
    },
    {
      headerName: "Present Address",
      field: "present_address",
    },
    {
      headerName: "Permanent Address",
      field: "permanent_address",
    },
    {
      headerName: "Aadhaar Number",
      field: "aadhaar_number",
    },
    {
      headerName: "PAN Number",
      field: "pan_number",
    },
    {
      headerName: "UAN Number",
      field: "uan_number",
    },
    {
      headerName: "PF Number",
      field: "pf_number",
    },
    {
      headerName: "ESI Number",
      field: "esi_number",
    },
    {
      headerName: "Driving License",
      field: "driving_license",
    },
    {
      headerName: "Relationship",
      field: "relationship",
    },
    {
      headerName: "Emergency Contact Name",
      field: "emergency_contact_name",
    },
    {
      headerName: "Emergency Contact Mobile",
      field: "emergency_contact_mobile",
    },
    {
      headerName: "Emergency Contact Address",
      field: "emergency_contact_address",
    },
  ];
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <div className="categories-table">
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: "500px", width: "100%" }}
      >
        <AgGridReact
          rowData={usersList.length > 0 ? usersList : []}
          columnDefs={columns}
          omLayout="autoHeight"
          pagination={true}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
        />
      </div>
    </div>
  );
};

export default EmployeeManagement;
