import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
// const userId = user?.user_id;
const accountType = user?.account_type;
const baseUrl = process.env.REACT_APP_BASE_URI;

let initialState = {
  depLoading: false,
  depSuccess: false,
  depError: false,
  depMessage: null,

  depGetLoading: false,
  depGetSuccess: false,
  depGetError: false,
  depGetMessage: null,
  depGetData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

export const fetchDepartmentData = createAsyncThunk("fetchDepartmentData", async (userId) => {
  return await axios
    .get(`${baseUrl}admin/getDepartmentList?userId=${userId}`, {}, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
        }
         return data
      } else {
        return error
      }
    });
});

export const createDepartment = createAsyncThunk("createDepartment", async (data) => {
  const result = {
    data: {
      message: "Sorry, you can only view the data, not create it.",
      data: [],
    },
  };
  return parseInt(accountType) === 2
    ? result
    : await axios
    .post(`${baseUrl}admin/createDepartment`, data, config)
    .then((response) => {
      console.log(response)
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
        }
         return data
      } else {
        return error
      }
    });
});

const departmentSlice = createSlice({
  name: "department",
  initialState,

  reducers: {
    initState : (state) => {
      state.depLoading = false;
      state.depSuccess = false;
      state.depError = false;
      state.depMessage = null;
    
      state.depGetLoading = false;
      state.depGetSuccess = false;
      state.depGetError = false;
      state.depGetMessage = null;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDepartmentData.pending, (state, action) => {
      state.depGetLoading = true;
      state.depGetSuccess = false;
      state.depGetError = false;
      state.depGetMessage = null;
      state.depGetData = null;
    });
    builder.addCase(fetchDepartmentData.fulfilled, (state, action) => {
      state.depGetLoading = false;
      state.depGetSuccess = true;
      state.depGetError = false;
      state.depGetMessage = action.payload.data.message;
      state.depGetData = action.payload.data.data;
    });
    builder.addCase(fetchDepartmentData.rejected, (state, action) => {
      state.depGetLoading = false;
      state.depGetSuccess = false;
      state.depGetError = true;
      state.depGetMessage = action.payload.data.message;
      state.depGetData = null;
    });
    // To create department
    builder.addCase(createDepartment.pending, (state, action) => {
      state.depLoading = true;
      state.depSuccess = false;
      state.depError = false;
      state.depMessage = null;
    });
    builder.addCase(createDepartment.fulfilled, (state, action) => {
      state.depLoading = false;
      state.depSuccess = accountType == 2 ? false : true;
      state.depError = accountType == 2 ? true : false;
      state.depMessage = action.payload.data.message;
    });
    builder.addCase(createDepartment.rejected, (state, action) => {
      state.depLoading = false;
      state.depSuccess = false;
      state.depError = true;
      state.depMessage = action.payload.data.message;
    });
  },
});

export const { initState } = departmentSlice.actions
export default departmentSlice.reducer;
