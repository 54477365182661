import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URI;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const initialState = {
  authLoading: false,
  authSuccess: false,
  authError: false,
  authMessage: null,
  authData: null,
};

export const loginUser = createAsyncThunk("loginUser", async (data) => {
  return await axios
    .post(`${baseUrl}userLogin`, data, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
          data: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
        };
        return data;
      } else {
        return error;
      }
    });
});

const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.authLoading = true;
      state.authSuccess = false;
      state.authError = false;
      state.authMessage = null;
      state.authData = null;
    });

    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.status == 200) {
        state.authLoading = false;
        state.authSuccess = true;
        state.authError = false;
        state.authMessage = action.payload.data.message;
        state.authData = action.payload.data.data;
      } else {
        state.authLoading = false;
        state.authSuccess = false;
        state.authError = true;
        state.authMessage = action.payload.response.data.message;
        state.authData = null;
      }
    });

    builder.addCase(loginUser.rejected, (state, action) => {
      if (action.payload.status == 200) {
        state.authLoading = false;
        state.authSuccess = true;
        state.authError = false;
        state.authMessage = action.payload.data.message;
        state.authData = null;
      } else {
        state.authLoading = false;
        state.authSuccess = true;
        state.authError = false;
        state.authMessage = action.payload.response.data.message;
        state.authData = null;
      }
    });
  },
});

export default loginSlice.reducer;
