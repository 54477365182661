import "./style.css";
import { useState, useEffect, useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Avatar from "../../assets/Images/user-avatar.jpg";
import ButtonCom from "../../components/button/button";
import { Link, useNavigate } from "react-router-dom";
import { HiArrowLongRight } from "react-icons/hi2";
import { TiDeleteOutline } from "react-icons/ti";
import { GoVideo } from "react-icons/go";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

import {
  fetchBranchData,
  initBranchState,
} from "../../redux/slices/branchSlice";
import {
  fetchDepartmentData,
  initState,
} from "../../redux/slices/departmentSlice";
import { fetchData, initShiftState } from "../../redux/slices/shiftsSlice";

import { useDispatch, useSelector } from "react-redux";

const Payroll = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [user] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : null
  );

  const branchState = useSelector((state) => state.branch);
  const departState = useSelector((state) => state.department);
  const shiftState = useSelector((state) => state.shift);

  const getData = useCallback(() => {
    dispatch(fetchBranchData(user.user_id));
    dispatch(fetchDepartmentData(user.user_id));
    dispatch(fetchData(user.user_id));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const [branch, setBranch] = useState([]);
  const [depart, setDepart] = useState([]);
  const [shift, setShift] = useState([]);

  useEffect(() => {
    if (branchState.brnGetSuccess) {
      setBranch(branchState.brnGetData);
      dispatch(initBranchState());
    }

    if (departState.depGetSuccess) {
      setDepart(departState.depGetData);
      dispatch(initState());
    }

    if (shiftState.shiftGetSuccess) {
      setShift(shiftState.shiftGetData);
      dispatch(initShiftState());
    }
  }, [
    branchState.brnGetSuccess,
    departState.depGetSuccess,
    shiftState.shiftGetSuccess,
  ]);

  // const [usersList, setUsersList] = useState([
  //   {
  //     name: "Ravi Ojha",
  //     firstIn: "10:00 AM",
  //     lastOut: "07:30 PM",
  //     hoursWorked: "09:27",
  //     status: "Present",
  //   },
  //   {
  //     name: "Shantanu Sahu",
  //     firstIn: "10:00 AM",
  //     lastOut: "07:30 PM",
  //     hoursWorked: "09:27",
  //     status: "Week Off",
  //   },
  //   {
  //     name: "Kaustub Mehra",
  //     firstIn: "10:00 AM",
  //     lastOut: "07:30 PM",
  //     hoursWorked: "09:27",
  //     status: "Absent",
  //   },
  // ]);

  const goEmployeeAttendance = (data) => {
    navigate("employee-attendance");
  };

  const heading = [
    {
      name: "Staff",
      label: "name",
      render: (rowData) => {
        return (
          <>
            <div
              style={{ display: "flex", gap: "10px", cursor: "pointer" }}
              onClick={() => goEmployeeAttendance(rowData)}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={Avatar}
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              </div>
              <div>
                <h5>{rowData.name}</h5>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "First In",
      label: "firstIn",
    },
    {
      name: "Last Out",
      label: "lastOut",
    },
    {
      name: "Hours Worked",
      lable: "hoursWorked",
    },

    {
      name: "Status",
      label: "status",
      width: "200px",
      render: (rowData) => {
        return (
          <>
            <div
              style={{
                textAlign: "center",
                width: "110px",
                borderRadius: "7px",
                padding: "7px",
                color:
                  rowData.status == "Present"
                    ? "#82e0aa"
                    : rowData.status == "Week Off"
                    ? "gray"
                    : "#ec7063",
                backgroundColor:
                  rowData.status == "Present"
                    ? "#eafaf1"
                    : rowData.status == "Week Off"
                    ? "#f8f9f9"
                    : "#fadbd8",
              }}
            >
              {rowData.status}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="">
      <div
        className="page-top-bar-1"
        style={{ marginTop: "-2px", borderRadius: "none" }}
      >
        2024 September Payroll Summary
      </div>
      <div className="page-body">
        <Row>
          <Col
            sm="2"
            style={{
              borderRight: "1px solid lightgray",
              height: "75vh",
              position: "sticky",
              top: "76px",
            }}
          >
            <Form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  <Link style={{ textDecoration: "none" }}>Reset</Link>
                </div>
                <Form.Group>
                  <Form.Label>Payroll Month</Form.Label>
                  <Form.Control type="month" />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Branch</Form.Label>
                  <Form.Select>
                    <option value="">All Branches</option>
                    {
                      branch.map((row, idx) => {
                        return <option value={row.branchId} key={idx}>{row.branch_name}</option>
                      })
                    }
                  </Form.Select>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Select Department</Form.Label>
                  <Form.Select>
                    <option value="">All Departments</option>
                    {
                      depart.map((row, idx) => {
                        return <option value={row.departmentId} key={idx}>{row.department_name}</option>
                      })
                    }
                  </Form.Select>
                </Form.Group>
              </div>
            </Form>
          </Col>
          {/* Right Content--------------------------------- */}
          <Col sm="8">
            <Card>
              <Card.Header className="card-header">
                <h1>CTC not added fro 37 staff</h1>
                <ButtonCom
                  type={"button"}
                  class_name={"p_btn"}
                  text="Add CTC"
                  url_or_onclick={() => {}}
                  icon={null}
                  width={""}
                />
              </Card.Header>
              <Card.Body>
                <Card.Text className="card-text">
                  <ul>
                    <li>
                      <div className="left">Total Payroll</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,709.31</span>
                      </div>
                    </li>
                    <li>
                      <div className="left">Total Payroll</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204</span>
                      </div>
                    </li>
                    <li>
                      <div className="left">Total Payroll</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,709.31</span>
                      </div>
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mt-4">
              <Card.Header className="card-header">
                <h1>Earning Details</h1>
              </Card.Header>
              <Card.Body>
                <Card.Text className="card-text">
                  <ul>
                    <li>
                      <div className="left">Work Basis Payment</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,709.31</span>
                      </div>
                    </li>
                    <li>
                      <div className="left">Incentives</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,709.31</span>
                      </div>
                    </li>
                    <li>
                      <div className="left">Reimbursement</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,709.31</span>
                      </div>
                    </li>
                    <li>
                      <div className="left">Bonus</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,709.31</span>
                      </div>
                    </li>
                    <li>
                      <div className="left">Overtime Pay</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,709.31</span>
                      </div>
                    </li>
                    <li>
                      <div className="left">Other Earning</div>
                      <div className="right">
                        <span>12 Staff</span>
                        <span>204,70</span>
                      </div>
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>

            {/* <Card className="mt-4">
              <Card.Header className="card-header">
                <h1>Statutory Compliance Details</h1>
              </Card.Header>
              <Card.Body>
                <Card.Text className="card-text"> */}
            <div className="statutory-container">
              <div className="head">
                <h1>Statutory Compliance Details</h1>
              </div>
              <div className="body">
                <Accordion defaultActiveKey="0">
                  {/* ---------------------------------- */}
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="acc-header">
                        <div className="left">Total Provident Fund</div>
                        <div className="right">
                          <span>12 Staff</span>
                          <span>204,709.31</span>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-body mb-2">
                        {" "}
                        <span>Employer Contribution</span> <span>18579.62</span>{" "}
                      </div>

                      <div className="acc-body mb-2">
                        {" "}
                        <span>Employee Contribution</span> <span>18579.62</span>{" "}
                      </div>

                      <div className="acc-body mb-2">
                        {" "}
                        <span>EDLI & Admin Charges</span> <span>18579.62</span>{" "}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* ---------------------------- */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="acc-header">
                        <div className="left">
                          Total Employees State Insurance
                        </div>
                        <div className="right">
                          <span>12 Staff</span>
                          <span>204,709.31</span>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-body mb-2">
                        {" "}
                        <span>Employer Contribution</span> <span>18579.62</span>{" "}
                      </div>

                      <div className="acc-body mb-2">
                        {" "}
                        <span>Employee Contribution</span> <span>18579.62</span>{" "}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* ---------------------------- */}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="acc-header">
                        <div className="left">
                          Total Labour Welfare Fund tax
                        </div>
                        <div className="right">
                          <span>12 Staff</span>
                          <span>204,709.31</span>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-body mb-2">
                        {" "}
                        <span>Employer Contribution</span> <span>18579.62</span>{" "}
                      </div>

                      <div className="acc-body mb-2">
                        {" "}
                        <span>Employee Contribution</span> <span>18579.62</span>{" "}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <ul className="mt-3 p-4">
                  <li>
                    <div className="left">Total Professional Tax</div>
                    <div className="right">
                      <span>12 Staff</span>
                      <span>204,709.31</span>
                    </div>
                  </li>
                  <li>
                    <div className="left">Total Tax Deducted at Source</div>
                    <div className="right">
                      <span>12 Staff</span>
                      <span>204,709.31</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* </Card.Text>
              </Card.Body>
            </Card> */}
          </Col>
          {/* ---------------- */}
          <Col
            sm="2"
            style={{
              borderLeft: "1px solid lightgray",
              height: "75vh",
              position: "sticky",
              top: "76px",
            }}
          >
            <div className="pending-action">
              <div className="mini-head">
                <h1>Pending Actions</h1>
              </div>

              <ul>
                <li>
                  <Link to="#">
                    Finalize Payroll (44) <HiArrowLongRight />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    Add Salary Details (44) <HiArrowLongRight />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    Verify Bank Accounts (44) <HiArrowLongRight />
                  </Link>
                </li>
              </ul>

              <div className="approve-leaves">Approve Leaves (0)</div>

              <div className="getting-started">
                <div className="header">
                  <h1>Getting Started</h1> <TiDeleteOutline className="icon" />
                </div>
                <div className="body">
                  <ul>
                    <li>
                      <Link to="#">
                        <GoVideo /> How to use Salary Box Payroll?
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Payroll;
