import authReducer from "./auth";
import layoutReducer from "./layout";
import paginationReducer from "./pagination";
import snackBar from "./snackbar";
import loader from "./loader";
import userReducer from "./userSlice";
import employeeReducer from "./employeeSlice";
import branchReducer from "./branchSlice";
import departmentReducer from "./departmentSlice"
import designationReducer from "./designationSlice";
import roleReducer from "./roleSlice";
import holidayReducer from "./holidaySlice";
import shiftsReducer from "./shiftsSlice";
import commonReducer from "./commonSlice";
import loginReducer from "./login"
import NoAuthReducer from "./noAuthSlice"
import urlExpiredSlice from "./expiredUrlSlice"
import moreSettingSlice from "./moreSettings"


export const reducer = {
  layout: layoutReducer,
  auth: authReducer,
  pagination: paginationReducer,
  snackBar: snackBar,
  loader: loader,
  user: userReducer,
  employee: employeeReducer,
  branch: branchReducer,
  department: departmentReducer,
  designation: designationReducer,
  roles: roleReducer,
  holiday: holidayReducer,
  shift: shiftsReducer,
  common: commonReducer,
  login: loginReducer,
  noAuth: NoAuthReducer,
  expiredUrl : urlExpiredSlice,
  moreSettings: moreSettingSlice
};
