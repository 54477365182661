import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
const userId = user?.user_id;
const accountType = user?.account_type;
const baseUrl = process.env.REACT_APP_BASE_URI;

let initialState = {
  roleLoading: false,
  roleSuccess: false,
  roleError: false,
  roleMessage: null,

  roleGetLoading: false,
  roleGetSuccess: false,
  roleGetError: false,
  roleGetMessage: null,
  roleGetData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

export const fetchData = createAsyncThunk("fetchData", async () => {
  return await axios
    .get(`${baseUrl}admin/getRolesList?userId=${userId}`, {}, config)
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
          data: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
        };
        return data;
      } else {
        let data = {
          response : {
            data: {
              message: error.message
            }
          }
        }
        return data;
      }
    });
});

export const saveRole = createAsyncThunk("saveRole", async (data) => {
  const result = {
    data: {
      message: "Sorry, you can only view the data, not create it.",
      data: [],
    },
  };
  return parseInt(accountType) === 2
    ? result
    : await axios
        .post(`${baseUrl}admin/createRoles`, data, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
            let data = {
              response: {
                data: [],
                message: "No Internet Connection Found",
                success: false,
              },
            };
            return data;
          } else {
            return error;
          }
        });
});

const roleSlice = createSlice({
  name: "roles",
  initialState,

  reducers: {
    initState: (state) => {
      state.roleLoading = false;
      state.roleSuccess = false;
      state.roleError = false;
      state.roleMessage = null;

      state.roleGetLoading = false;
      state.roleGetSuccess = false;
      state.roleGetError = false;
      state.roleGetMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state, action) => {
      state.roleGetLoading = true;
      state.roleGetSuccess = false;
      state.roleGetError = false;
      state.roleGetMessage = null;
      state.roleGetData = null;
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      console.log(action)
      state.roleGetLoading = false;
      state.roleGetSuccess = true;
      state.roleGetError = false;
      state.roleGetMessage = action.payload.status == 200 ? action.payload.data.message : action.payload.response.data.message;
      state.roleGetData = action.payload.status == 200 ? action.payload.data.data : [];
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.roleGetLoading = false;
      state.roleGetSuccess = false;
      state.roleGetError = true;
      state.roleGetMessage = action.payload.data.message;
      state.roleGetData = null;
    });
    // To create branch
    builder.addCase(saveRole.pending, (state, action) => {
      state.roleLoading = true;
      state.roleSuccess = false;
      state.roleError = false;
      state.roleMessage = null;
    });
    builder.addCase(saveRole.fulfilled, (state, action) => {
      state.roleLoading = false;
      state.roleSuccess = accountType == 2 ? false : true;
      state.roleError = accountType == 2 ? true : false;
      state.roleMessage = action.payload.data.message;
    });
    builder.addCase(saveRole.rejected, (state, action) => {
      state.roleLoading = false;
      state.roleSuccess = false;
      state.roleError = true;
      state.roleMessage = action.payload.data.message;
    });
  },
});

export const { initState } = roleSlice.actions;
export default roleSlice.reducer;
