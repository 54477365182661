import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
// const userId = user?.user_id;
const accountType = user?.account_type;
const baseUrl = process.env.REACT_APP_BASE_URI;

const initialState = {
  uIsLoading: false,
  uSuccess: false,
  uError: false,
  uMessage: null,
  uData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

export const saveUser = createAsyncThunk("saveUser", async (data) => {
  const result = {
    data: {
      message: "Sorry, you can only view the data, not create it.",
      data: [],
    },
  };
  return parseInt(accountType) === 2
    ? result
    : await axios
        .post(`${baseUrl}superior/saveNewAdminUser`, data, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
            let data = {
              response: {
                data: [],
                message: "No Internet Connection Found",
                success: false,
              },
              data: {
                data: [],
                message: "No Internet Connection Found",
                success: false,
              },
            };
            return data;
          } else {
            return error;
          }
        });
});
// https://seekhrms.sktexus.com/
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveUser.pending, (state, action) => {
      state.uIsLoading = true;
      state.uSuccess = false;
      state.uError = false;
      state.uMessage = null;
      state.uData = null;
    });
    builder.addCase(saveUser.fulfilled, (state, action) => {
      state.uIsLoading = false;
      state.uSuccess = accountType == 2 ? false : true;
      state.uError = accountType == 2 ? true : false;
      state.uMessage = action.payload;
      state.uData = action.payload;
    });
    builder.addCase(saveUser.rejected, (state, action) => {
      state.uIsLoading = false;
      state.uSuccess = true;
      state.uError = false;
      state.uMessage = null;
      state.uData = null;
    });
  },
});

// export const { saveUser } = userSlice.actions;

export default userSlice.reducer;
