import { lazy, useState } from "react";
import ButtonCom from "../../components/button/button";
import { Form, Col, Row } from "react-bootstrap";
// icons
import { IoIosAdd } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import { CgRemoveR } from "react-icons/cg";
import { RiDeleteBin5Line } from "react-icons/ri";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// -------------------------------------------------------
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Label } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
// Redux --------------------------------------------------------
import { useSelector, useDispatch } from "react-redux";
import {
  commonGetData,
  commonSaveData,
  initCommonState,
} from "../../redux/slices/commonSlice";
import { Link } from "react-router-dom";

const Earning = lazy(() => import("./Details/Earning"));
const Compliance = lazy(() => import("./Details/Compliance"));
const Deduction = lazy(() => import("./Details/Deduction"));

const animatedComponents = makeAnimated();
const SalaryDetails = () => {
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);

  const [validated, setValidated] = useState(false);
  const [saveData, setSAveData] = useState({
    designationName: "",
    contributes: [],
  });
  const handleChange = (e) => {
    setSAveData({
      ...saveData,
      contributes: e,
    });
  };
  const [earnings, setEarnings] = useState([
    {
      id: "basic1",
      head: "Basic",
      calculation: "basic",
    },

    {
      id: "hra1",
      head: "HRA",
      calculation: "hra",
    },

    {
      id: "dearness1",
      head: "Dearness Allowance",
      calculation: "dearness",
    },
  ]);
  const addEarningField = (e) => {
    const { value, label } = e;
    setEarnings([
      ...earnings,
      {
        id: value,
        head: label,
        calculation: "",
      },
    ]);

    setTimeout(() => {
      console.log(earnings);
    }, 800);
  };
  const removeEarningFields = (e, index) => {
    e.preventDefault();
    const rows = [...earnings];
    rows.splice(index, 1);
    setEarnings(rows);
  };
  const handleEarningChanges = (index, e) => {
    const { name, value } = e.target;
    const list = [...earnings];
    list[index][name] = value;
    setEarnings(list);
  };
  // End of earning functions

  // Compliances Start here
  const [saveCompliances, setSaveCompliances] = useState({
    designationName: "",
    contributes: [],
  });

  const handleComplianceChange = (e) => {
    setSaveCompliances({
      ...saveCompliances,
      compliances: e,
    });
  };
  const compliancesData = {
    userId: 10,
    allowanceList: [{ allowance_name: "DRA", allowance_calc: "On Attendance" }],
    deductionList: [
      { deduction_name: "Insurance", deduction_calc: "Flat Rate" },
    ],
    employer_pf_contrib: "2300",
    employer_esi_contrib: "3.25",
    employer_lwf: "yes",
    employee_pf_contrib: "1800",
    employee_esi_contrib: "0.75",
  };

  // Compliances Ends here

  const [deductions, setDeductions] = useState([]);
  const addDeductionsField = (e) => {
    const { value, label } = e;
    setDeductions([
      ...deductions,
      {
        id: value,
        head: label,
        calculation: "",
      },
    ]);
  };
  const removeDeductionsFields = (e, index) => {
    e.preventDefault();
    const rows = [...deductions];
    rows.splice(index, 1);
    setDeductions(rows);
  };
  const handleDeductionsChanges = (index, e) => {
    const { name, value } = e.target;
    const list = [...deductions];
    list[index][name] = value;
    setDeductions(list);
  };

  const preSubmit = () => {
    document.getElementById("submitForm").click();
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
    }
  };

  // ----------------------------------
  return (
    <div className="body-container">
      {/* <div className="SettingBody-head">
        
        <p>Company Departments</p>
      </div> */}
      <div className="page-top-bar">
        <div style={{display: "flex", alignItem: "center", justifyContent: "start", gap: "10px"}}>
          <Link to={"/setting/"}>
            <ArrowBackIcon sx={{ scale: 1 }} style={{color: "gray"}} />
          </Link>
          <h1 style={{fontSize: "20px", fontWeight: "400"}}>Salary Details Import Settings</h1>
        </div>

        <ButtonCom
          type={"button"}
          class_name={"p_btn"}
          text="Save Details"
          url_or_onclick={() => {}}
          icon={null}
          width={""}
        />
      </div>
      <div className="page-body p-5">
        <Form
          noValidate
          validated={validated}
          onSubmit={preSubmit}
          id="submitForm"
        >
          <Earning
            earnings={earnings}
            addField={addEarningField}
            removeFields={removeEarningFields}
            handleChanges={handleEarningChanges}
          />
          <Compliance onChange={handleComplianceChange} />
          <Deduction
            deductions={deductions}
            addField={addDeductionsField}
            removeFields={removeDeductionsFields}
            handleChanges={handleDeductionsChanges}
          />
        </Form>
      </div>
    </div>
  );
};

export default SalaryDetails;
