import "./Branches.css";
import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AgGridReact } from "ag-grid-react";
import AddIcon from "@mui/icons-material/Add";
import { MdAddLocationAlt } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
// mui components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Button from "@mui/material/Button";
import Button from "react-bootstrap/Button";
import { Form, Row, Col } from "react-bootstrap";
import ButtonCom from "../../../components/button/button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
// map components
// import Map from "../../../components/Map/Index";
import Map from "./Map";

import { useDispatch, useSelector } from "react-redux";
import {
  initState,
  saveBranch,
  fetchBranchData,
} from "../../../redux/slices/branchSlice";
import {
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import axios from "axios";

const Branches = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];
  const REACT_APP_OLA_MAP_KEY = process.env.REACT_APP_OLA_MAP_KEY;
  const dispatch = useDispatch();
  const state = useSelector((state) => state.branch);

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [branchList, setBranchList] = useState([]);

  // Handle modal with functions
  const handleClose = () => {
    setCenter({
      ...center,
      lng: null,
      lat: null,
    });
    setAddBranch({
      ...addBranch,
      branchId: null,
      branchName: "",
      branchAddress: "",
      radius: null,
      latitude: null,
      longitude: null,
    });
    setMarkers([]);
    setRadius(0);
    setInputValue("");
    setIsEdit(false);
    setValidated(false);
    setOpen(false);
  };

  const [scroll] = useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // get data with apis
  const getBranchList = useCallback(() => {
    dispatch(fetchBranchData());
  }, []);

  useEffect(() => {
    getBranchList();
  }, []);

  useEffect(() => {
    if (state.brnGetSuccess) {
      setBranchList(state.brnGetData);
    }
    if (state.brnGetError) {
    }
  }, [state.brnGetSuccess, state.brnGetError]);

  const [radius, setRadius] = useState(0);

  const [addBranch, setAddBranch] = useState({
    branchId: null,
    userId: user.user_id,
    branchName: "",
    branchAddress: "",
    radius: null,
    latitude: null,
    longitude: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddBranch({
      ...addBranch,
      [name]: value,
    });
  };

  const handleChangeRadius = (e) => {
    const { value } = e.target;
    if (isNaN(value)) {
      setAddBranch({
        ...addBranch,
        radius: "",
      });
    } else {
      if (value > 1000) {
        return;
      } else {
        setRadius(parseInt(value));
        setAddBranch({
          ...addBranch,
          radius: value,
        });
      }
    }
  };

  const handleBranchName = (e) => {
    const { value } = e.target;
    setAddBranch({
      ...addBranch,
      branchName: value,
    });
  };

  // map variables and functions
  const [center, setCenter] = useState({
    lng: null,
    lat: null,
  });

  const [markers, setMarkers] = useState([]);

  const getCurrentLocation = (lat, lng) => {
    const newCenter = { lng: parseFloat(lng), lat: parseFloat(lat) };
    const newMarkers = [
      {
        id: 1,
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        label: "",
      },
    ];

    setAddBranch((prev) => ({
      ...prev,
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
    }));
    setCenter(newCenter); // New reference
    setMarkers(newMarkers); // New reference

    fetchAddress(parseFloat(lat), parseFloat(lng));
  };

  const handleGetCurrentLocation = () => {
    const lat = localStorage.getItem("Lat");
    const lng = localStorage.getItem("Lng");

    if (lat && lng) {
      getCurrentLocation(lat, lng);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(success, error, {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      });
    } else {
      console.log("Geolocation not supported");
    }
  }, []);

  const fetchAddress = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY; // Replace with your API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].formatted_address;
        setAddBranch({
          ...addBranch,
          branchAddress: location,
        });
        setInputValue(location);
      } else {
        console.error("Geocode error: ", data.status);
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    // const accuracy = position.coords.accuracy;
    localStorage.setItem("Lat", latitude);
    localStorage.setItem("Lng", longitude);
    setAddBranch({
      ...addBranch,
      latitude: latitude,
      longitude: longitude,
    });
    setCenter({
      ...center,
      lng: longitude,
      lat: latitude,
    });
    setMarkers([
      {
        id: 1,
        position: {
          lat: latitude,
          lng: longitude,
        },
        label: "",
      },
    ]);

    fetchAddress(parseFloat(latitude), parseFloat(longitude));
  };

  const error = () => {
    console.log("Unable to retrieve your location");
  };

  const handleLatLngWithMarkerDrag = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    const fetchAddress = async () => {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
          // Extracting the formatted address from the response
          const location = data.results[0].formatted_address;
          setAddBranch({
            ...addBranch,
            branchAddress: location,
            latitude: lat,
            longitude: lng,
          });
          setInputValue(location);
          setCenter({
            ...center,
            lng: lng,
            lat: lat,
          });
        } else {
          console.error("Geocode error: ", data.status);
        }
      } catch (error) {
        console.error("Error fetching geocode:", error);
      }
    };

    fetchAddress();
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(saveBranch(addBranch));
    }
  };

  useEffect(() => {
    if (state.brnSuccess) {
      toast.success(state.brnMessage);
      getBranchList();
      dispatch(initState());
      handleClose();
      setCenter({
        ...center,
        lng: null,
        lat: null,
      });
      setAddBranch({
        ...addBranch,
        branchId: null,
        branchName: "",
        branchAddress: "",
        radius: null,
        latitude: null,
        longitude: null,
      });
      setMarkers([]);
      setRadius(0);
      setInputValue("");
      setIsEdit(false);
    }
    if (state.brnError) {
      toast.error(state.brnMessage);
      dispatch(initState());
      handleClose();
      setCenter({
        ...center,
        lng: null,
        lat: null,
      });
      setAddBranch({
        ...addBranch,
        branchId: null,
        branchName: "",
        branchAddress: "",
        radius: null,
        latitude: null,
        longitude: null,
      });
      setMarkers([]);
      setRadius(0);
      setInputValue("");
      setIsEdit(false);
    }
  }, [state.brnSuccess, state.brnError]);

  // --------------------------------------------------------------
  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  // Functions for edit records ------------------------------------
  const handelEdit = (row) => {
    setCenter({
      ...center,
      lng: null,
      lat: null,
    });
    setAddBranch({
      ...addBranch,
      branchId: null,
      branchName: "",
      branchAddress: "",
      radius: null,
      latitude: null,
      longitude: null,
    });
    setMarkers([]);
    setRadius(0);
    setInputValue("");
    setIsEdit(false);
    // ===========================================
    setAddBranch({
      ...addBranch,
      branchId: parseInt(row.data.branchId),
      branchName: row.data.branch_name,
      branchAddress: row.data.branch_address,
      radius: parseInt(row.data.radius),
      latitude: parseFloat(row.data.latitude),
      longitude: parseFloat(row.data.longitude),
    });
    setRadius(parseInt(row.data.radius));
    setInputValue(row.data.branch_address);
    setMarkers([
      {
        id: 1,
        position: {
          lat: parseFloat(row.data.latitude),
          lng: parseFloat(row.data.longitude),
        },
        label: "",
      },
    ]);
    setIsEdit(true);
    setCenter({
      ...center,
      lng: parseFloat(row.data.longitude),
      lat: parseFloat(row.data.latitude),
    });
    handleClickOpen();
  };

  const commonState = useSelector((state) => state.common);
  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateBranch",
      data: addBranch,
    };
    dispatch(commonSaveData(d));
  };

  useEffect(() => {
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      setOpen(false);
      getBranchList();
      setBrnId(null);
      dispatch(initCommonState());
      setIsEdit(false);
      setCenter({
        ...center,
        lng: null,
        lat: null,
      });
      setAddBranch({
        ...addBranch,
        branchId: null,
        branchName: "",
        branchAddress: "",
        radius: null,
        latitude: null,
        longitude: null,
      });
      setMarkers([]);
      setRadius(0);
      setInputValue("");
      setIsEdit(false);
    }
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      getBranchList();
      setBrnId(null);
      dispatch(initCommonState());
      setIsEdit(false);
      setCenter({
        ...center,
        lng: null,
        lat: null,
      });
      setAddBranch({
        ...addBranch,
        branchId: null,
        branchName: "",
        branchAddress: "",
        radius: null,
        latitude: null,
        longitude: null,
      });
      setMarkers([]);
      setRadius(0);
      setInputValue("");
      setIsEdit(false);
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  // functions for delete records
  const [brnId, setBrnId] = useState(null);
  const handleDelete = (row) => {
    setBrnId(row.data.branchId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteBranch",
      data: {
        userId: user.user_id,
        branchId: brnId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };
  // delete function ends here --------------------------------
  const heading = [
    {
      headerName: "Branch Name",
      field: "branch_name",
      flex: 1,
    },

    {
      headerName: "Radius",
      field: "radius",
    },

    {
      headerName: "Address",
      field: "branch_address",
      flex: 1,
    },

    {
      headerName: "Action",
      field: "id",
      width: 200,
      cellRenderer: (rowData) => {
        return (
          <>
            <div className="Branches-table-button">
              <CiEdit
                style={{ cursor: "pointer", fontSize: 30, color: "blue" }}
                onClick={() => {
                  handelEdit(rowData);
                }}
              />
              <MdOutlineDeleteSweep
                style={{ cursor: "pointer", fontSize: 30, color: "red" }}
                onClick={() => handleDelete(rowData)}
              />
            </div>
          </>
        );
      },
    },
  ];

  const [inputValue, setInputValue] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Sample options for dropdown
  const [options, seOptions] = useState([]);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const fetchData = (query) => {
    axios
      .get(
        `https://api.olamaps.io/places/v1/autocomplete?input=${query}&api_key=dIgrC5vkcE8r4qaAoGEbwkGyZ2MGfdsu0BFR73kQ`
      )
      .then((res) => {
        const newArr = res.data.predictions?.map((row) => {
          let lng = row.geometry.location.lng;
          let lat = row.geometry.location.lat;
          return {
            place: row.description,
            center: { lng, lat },
          };
        });
        seOptions(newArr);
      })
      .catch((err) => {
        console.log(err);
      });
    // seOptions()
  };

  // Debounced version of fetchData
  const debouncedFetchData = debounce(fetchData, 2000); // 500ms delay

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setAddBranch({
      ...addBranch,
      branchAddress: "",
    });
    if (e.target.value === "") {
      setDropdownVisible(false);
    } else {
      debouncedFetchData(e.target.value);
      setDropdownVisible(true);
    }
  };

  // Handle option selection
  const handleOptionSelect = (option) => {
    setInputValue(option.place);
    setCenter({
      ...center,
      lat: option.center.lat,
      lng: option.center.lng,
    });
    setAddBranch({
      ...addBranch,
      branchAddress: option.place,
      latitude: option.center.lat,
      longitude: option.center.lng,
    });
    setMarkers([
      {
        id: 1,
        position: { lat: option.center.lat, lng: option.center.lng },
        label: "",
      },
    ]);
    setDropdownVisible(false); // Hide dropdown after selection
  };

  //===============================
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody">
        <div className="SettingBody-head">
          <Link to={"/setting/"}>
            <ArrowBackIcon sx={{ scale: 1 }} />
          </Link>
          <p>Company Branches</p>
        </div>

        <div className="SettingBody-card mt-3">
          <div className="Branches-body" style={{ borderRadius: "7px" }}>
            <div className="Branches-btn">
              <ButtonCom
                type="button"
                class_name="p_btn"
                text={"Add New Branch"}
                url_or_onclick={handleClickOpen}
                icon={<AddIcon />}
                width={""}
              />
            </div>
            <div
              className="ag-theme-quartz" // applying the Data Grid theme
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                rowData={branchList}
                columnDefs={heading}
                omLayout="autoHeight"
                pagination={true}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
              />
            </div>
          </div>
        </div>

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          scroll={scroll}
          open={open}
          onClose={handleClose}
          disableScrollLock={false}
        >
          <DialogTitle style={{ fontSize: "16px" }}>Add New Branch</DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              style={{ position: "relative" }}
            >
              <Form
                noValidate
                validated={validated}
                onSubmit={isEdit ? handleEditSubmit : handleSubmit}
              >
                <Row>
                  <Col sm="6">
                    <Form.Group className="mb-2">
                      <Form.Label>Branch Name </Form.Label>
                      <Form.Control
                        type="text"
                        name="branchName"
                        value={addBranch.branchName}
                        placeholder="Enter Branch Name"
                        onChange={handleBranchName}
                        required
                      />
                      <Form.Control.Feedback type={"invalid"}>
                        Enter branch name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* ---------------------------- */}
                  <Col sm="6">
                    <Form.Group>
                      <Form.Label>Radius</Form.Label>
                      <Form.Control
                        type="text"
                        name="radius"
                        value={addBranch.radius}
                        placeholder="Enter Radius"
                        onChange={handleChangeRadius}
                        required
                      />
                      <Form.Control.Feedback type={"invalid"}>
                        Enter radius value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col sm="6">
                    <Form.Group
                      className="mb-2"
                      style={{ position: "relative" }}
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name={"branchAddress"}
                        value={addBranch.branchAddress}
                        onChange={handleChange}
                        required
                        readOnly
                        placeholder="Enter your address or click add location icon"
                      />
                      <Tooltip title="Get current location" placement="top">
                        <IconButton
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "45%",
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "skyblue",
                            zIndex: 999,
                          }}
                        >
                          <MdAddLocationAlt
                            style={{
                              cursor: "pointer",
                              color: "skyblue",
                            }}
                            onClick={handleGetCurrentLocation}
                            className="pop-up-tooltip"
                          />
                        </IconButton>
                      </Tooltip>

                      <Form.Control.Feedback type="invalid">
                        Enter Address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col sm="6" style={{ position: "relative" }}>
                    <Form.Label>Search Location</Form.Label>
                    <Form.Control
                      name="search"
                      placeholder="Search location"
                      value={inputValue}
                      onChange={handleInputChange}
                    />

                    {dropdownVisible && options.length > 0 && (
                      <div
                        style={{ backgroundColor: "red", position: "relative" }}
                      >
                        <ul
                          className="search-dropdown"
                          style={{
                            listStyle: "none",
                            padding: 0,
                            margin: 0,
                            position: "absolute",
                            top: "1px",
                            left: 0,
                            right: 0,
                            border: "1px solid #ccc",
                            borderRadius: "7px",
                            backgroundColor: "white",
                            zIndex: 1000,
                          }}
                        >
                          {options.map((option, index) => (
                            <li
                              key={index}
                              onClick={() => handleOptionSelect(option)}
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                borderBottom:
                                  options.length - 1 === index
                                    ? "none"
                                    : "1px solid #ccc",
                              }}
                            >
                              {option.place}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" style={{ width: "100%", height: "280px" }}>
                    {center.lat != null && (
                      <Map
                        center={center}
                        markers={markers}
                        zoom={14}
                        width={"100%"}
                        height={"280px"}
                        radius={radius}
                        handleMarkerDrag={handleLatLngWithMarkerDrag}
                        handleGetCurrentLocation={handleGetCurrentLocation}
                        draggable={true}
                      />
                    )}
                    { center.lat == null && <center><h4 style={{ position: "relative", top: "100px" }}>No data for map loading</h4></center> }
                  </Col>
                </Row>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="gray"
                    onClick={handleClose}
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default React.memo(Branches);
