import "./SuperAdminNavBar.css";
import logo from "../../../assets/Images/Logo.png";
import SettingsIcon from "@mui/icons-material/Settings";
import { NavLink } from "react-router-dom";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PersonIcon from "@mui/icons-material/Person";
import TimerIcon from "@mui/icons-material/Timer";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RoomIcon from "@mui/icons-material/Room";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import InventoryIcon from "@mui/icons-material/Inventory";

const slider = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: <SpaceDashboardIcon />,
  },
  {
    path: "user-management",
    name: "User Management ",
    icon: <PersonIcon />,
  },
  {
    path: "package",
    name: "Package",
    icon: <InventoryIcon />,
  },
  {
    path: "attendance",
    name: "Roles & Permission ",
    icon: <TimerIcon />,
  },
  { path: "set-salary", name: "System Configuration", icon: <ExitToAppIcon /> },
  { path: "set-salary", name: "Audit Trail", icon: <RoomIcon /> },

  {
    path: "performance",
    name: "Data backup & Restore",
    icon: <MonitorHeartIcon />,
  },

  {
    path: "raining-list",
    name: "Security Management",
    icon: <CastForEducationIcon />,
  },
  {
    path: "/setting/company-reports",
    name: "Integration Management",
    icon: <BarChartIcon />,
  },
  {
    path: "/setting/company-reports",
    name: "Module Configuration",
    icon: <BarChartIcon />,
  },
  {
    path: "/setting/company-reports",
    name: "Announcement & Notification",
    icon: <BarChartIcon />,
  },
  {
    path: "/setting/company-reports",
    name: "Access Control",
    icon: <BarChartIcon />,
  },
  {
    path: "/setting/company-reports",
    name: "Report Generation",
    icon: <BarChartIcon />,
  },
  {
    path: "/setting/company-reports",
    name: "Troubleshooting",
    icon: <BarChartIcon />,
  },
  {
    path: "setting",
    name: "Setting",
    icon: <SettingsIcon />,
  },
];
const SuperAdminNavBar = ({ setSlider, Slide }) => {
  return (
    <nav className="superAdminNavBare">
      <div className="superAdminNavBare-left">
        <img src={logo} alt="logo" onClick={() => setSlider(!Slide)} />
      </div>

      <div className="superAdminNavBare-Right">
        <ul>
          {slider.map((item, i) => (
            <NavLink to={item.path} key={i} style={{ textDecoration: "none" }}>
              {({ isActive }) => (
                <label
                  id="superAdmin_nav_link"
                  style={{
                    backgroundColor: isActive ? "#fff" : "",
                    color: isActive ? "black" : "",
                  }}
                >
                  {item.icon}
                  {item.name}
                </label>
              )}
            </NavLink>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default SuperAdminNavBar;
