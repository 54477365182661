import "./UserPermission.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ButtonCom from "../../../components/button/button";
import { Row, Col } from "react-bootstrap";

const UserPermission = () => {
  const handlePopUp = () => {};

  return (
    <div className="body-container">
      <div className="page-top-bar">
        <h1>User Permission</h1>
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="User Details"
          url_or_onclick={handlePopUp}
          icon={null}
          width={""}
        />
      </div>
      <div className="page-body">
        <Row style={{display: "flex", justifyContent: "center", width: "100%"}}>
          <Col sm="6">
            <FormControl className="FormControl">
              <FormLabel id="demo-radio-buttons-group-label">
                *Select Permission
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Employee"
                name="radio-buttons-group"
              >
                <div className="radio">
                  <FormControlLabel
                    value="Employee"
                    control={<Radio />}
                    label="Employee"
                  />
                </div>

                <div className="radio">
                  <FormControlLabel
                    value="Branch Admin"
                    control={<Radio />}
                    label="Branch Admin"
                  />
                </div>

                <div className="radio">
                  <FormControlLabel
                    value="Attendance Manager"
                    control={<Radio />}
                    label="Attendance Manager"
                  />
                </div>

                <div className="radio">
                  <FormControlLabel
                    value="Advance Attendance Manager"
                    control={<Radio />}
                    label="Advance Attendance Manager"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserPermission;
