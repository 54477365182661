const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_PATH = "/api";
const BASE_PATH = `${BASE_URL}${API_PATH}`;

export const authEndPoints = {
  login: `${BASE_PATH}/auth/login`,
  logout: `${BASE_PATH}/logout`,
  refreshToken: `${BASE_PATH}/auth/refresh-token`,
};

export const billEndPoints = { getBillById: `${BASE_PATH}/bill` };

export const medicalCampPoints = {
  getMedicalCampList: `${BASE_PATH}/medical-camp`,
  createMedicalList: `${BASE_PATH}/medical-camp`,
};
