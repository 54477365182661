import "./LadingPageCardTw0.css";
import planet from "../../../assets/icons/planet-earth.png";
import bulding from "../../../assets/icons/bulding.png";
import group from "../../../assets/icons/group.png";
import flowchart from "../../../assets/Images/flowcahrt.png";

const LadingPageCardTwo = () => {
  return (
    <div className="LadingPageCardTwo">
      <div className="LadingPageCardTwo-container">
        <div className="LadingPageCardTwo-container-left">
          <h4>Get the Advantage in your Digital HR Transformation Journey</h4>
          <p>
            Digital HR transformation is necessary for organisational success.
            Our team at Adrenalin, with over 20 years of HR domain expertise,
            delivers innovative solutions that focus on customer satisfaction
            and empowering talent. We help HR and employees succeed.
          </p>
        </div>

        <div className="LadingPageCardTwo-container-center">
          <div className="card-one">
            <img src={planet} alt="icon" />
            <p>65+</p>
            <label>Countries</label>
          </div>
          <div className="cards">
            <div>
              <img src={bulding} alt="icon" />
              <p>1500+</p>
              <label>Countries</label>
            </div>
            <div>
              <img src={group} alt="icon" />
              <p>2.5M+</p>
              <label>Countries</label>
            </div>
          </div>
        </div>

        <div className="LadingPageCardTwo-container-right">
          <h4>Hire to Retire Employee Experience</h4>
          <p>
            Our end-to-end robust applications for each process in the employee
            lifecycle are designed to ensure that you hire the right talent for
            the job and offer the best employee experience. Leverage our
            solutions to deliver measurable ROI.
          </p>
        </div>
      </div>
      <div className="flowchart-img">
        <img src={flowchart} alt="flowchart" />
      </div>
    </div>
  );
};

export default LadingPageCardTwo;
