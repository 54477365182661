import { useEffect, useState } from "react";
import "./LeaveBalanceDetails.css";
import { Form } from "react-bootstrap";
import ButtonCom from "../../../components/button/button";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { commonSaveData, initState } from "../../../redux/slices/commonSlice";

const LeaveBalanceDetails = ({ user, userDetails, commonState }) => {
  const items = [
    {
      name: "Leave Policy",
      tapItem: (
        <LeavePolicy
          user={user}
          userDetails={userDetails}
          commonState={commonState}
        />
      ),
    },
    {
      name: "Leave Balance",
      tapItem: (
        <LeaveBalance
          user={user}
          userDetails={userDetails}
          commonState={commonState}
        />
      ),
    },
    {
      name: "Leave Requests",
      tapItem: (
        <LeaveRequests
          user={user}
          userDetails={userDetails}
          commonState={commonState}
        />
      ),
    },
  ];

  const [highlightTap, setHighlightTap] = useState(0);
  return (
    <div className="LeaveBalanceDetails">
      <div className="LeaveBalanceDetails-body">
        <p>Leave Balance Details</p>
        <div className="LeaveBalanceDetails-card">
          <div className="LeaveBalanceDetails-card-items">
            <div className="LeaveBalanceDetails-tap">
              <div className="LeaveBalanceDetails-tap-item">
                {items.map((list, i) => (
                  <p
                    style={{
                      borderBottom: highlightTap === i ? "2px solid gray" : "",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={() => setHighlightTap(i)}
                  >
                    {list.name}
                  </p>
                ))}
              </div>
            </div>

            <div className="LeaveBalanceDetails-tab-item">
              {items.map((item, i) => (
                <div key={i}>
                  {highlightTap === i ? <>{item.tapItem}</> : <></>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LeavePolicy = ({ user, userDetails, commonState }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  // Confirm Dialog box for change attendance type
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => setConfirm(true);
  const handleCloseConfirm = () => setConfirm(false);
  const [preLeaveType, setPreLeaveType] = useState("");

  const [leaves, setLeaves] = useState({
    allowed_leave0: "",
    carryforward_leave0: "",
    allowed_leave1: "",
    carryforward_leave1: "",
    allowed_leave2: "",
    carryforward_leave2: "",
  });
  // Variable for actual data to submit

  const handleChangeInput = (e) => {
    const {
      target: { name, value },
    } = e;
    setLeaves({
      ...leaves,
      [name]: value,
    });
  };

  // Handle selection of leave type =====================================
  const handleOptionChange = (e) => {
    if (selectedOption == "") {
      setSelectedOption(e.target.value);
    } else {
      setPreLeaveType(e.target.value);
      handleConfirm();
    }
  };
  const handleMonthlyChange = () => {
    setSelectedOption("Monthly");
    handleCloseConfirm();
    setLeaves({
      ...leaves,
      allowed_leave0: "",
      carryforward_leave0: "",
      allowed_leave1: "",
      carryforward_leave1: "",
      allowed_leave2: "",
      carryforward_leave2: "",
    });
  };
  const handleYearlyChange = () => {
    setSelectedOption("Yearly");
    handleCloseConfirm();
    setLeaves({
      ...leaves,
      allowed_leave0: "",
      carryforward_leave0: "",
      allowed_leave1: "",
      carryforward_leave1: "",
      allowed_leave2: "",
      carryforward_leave2: "",
    });
  };
  const cancelChange = () => {
    if (preLeaveType == "Monthly") {
      setSelectedOption("Yearly");
    } else {
      setSelectedOption("Monthly");
    }
    handleCloseConfirm();
  };
  //Handel Submit the Data =============================================
  const handleSubmit = () => {
    let d = {
      path: "admin/empLeavePolicy",
      data: {
        userId: user.user_id,
        staffId: userDetails[0].staffId,
        leaveCycle: selectedOption == "Monthly" ? 1 : 2,
        leaves: [
          {
            leave_name: "Privilege leave",
            allowed_leave: leaves.allowed_leave0,
            carryforward_leave: leaves.carryforward_leave0,
          },
          {
            leave_name: "Casual leave",
            allowed_leave: leaves.allowed_leave2,
            carryforward_leave: leaves.carryforward_leave2,
          },
          {
            leave_name: "Sick leave",
            allowed_leave: leaves.allowed_leave1,
            carryforward_leave: leaves.carryforward_leave1,
          },
        ],
      },
    };
    dispatch(commonSaveData(d));
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initState());
    }
  }, [commonState.commonSuccess]);

  return (
    <>
      <Dialog
        fullWidth={true}
        open={confirm}
        onClose={handleCloseConfirm}
        style={{ width: "30%", margin: "0 auto" }}
      >
        <DialogContent
          style={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          You will loss your previous data if you change attendance type.
        </DialogContent>
        <DialogActions style={{ borderTop: "1px solid lightgray" }}>
          <Button
            variant="contained"
            color="gray"
            onClick={cancelChange}
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              height: "38px",
              backgroundColor: "gray",
              color: "white",
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="info"
            onClick={
              preLeaveType == "Yearly"
                ? handleYearlyChange
                : handleMonthlyChange
            }
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              height: "38px",
              // backgroundColor: "gray",
              color: "white",
            }}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="LeavePolicy">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <ButtonCom
            type="button"
            class_name={"p_btn"}
            text="Update Details"
            url_or_onclick={handleSubmit}
            icon="null"
            width={""}
          />
        </div>

        <div className="LeavePolicy-card">
          <div
            className="LeavePolicy-radio-btn"
            style={{ alignItems: "center" }}
          >
            <p>Leave Policy</p>
            <label
              style={{
                fontSize: "16px",
              }}
            >
              <input
                id="radio-lP"
                type="radio"
                name="role"
                value="Monthly"
                checked={selectedOption === "Monthly"}
                onChange={handleOptionChange}
              />
              <samp>Monthly</samp>
            </label>
            <label
              style={{
                fontSize: "16px",
              }}
            >
              <input
                type="radio"
                name="role"
                value="Yearly"
                checked={selectedOption === "Yearly"}
                onChange={handleOptionChange}
              />
              <samp>Yearly</samp>
            </label>
          </div>
        </div>

        {selectedOption === "" && (
          <p className="" style={{ fontSize: "16px", marginTop: "50px" }}>
            Select Leave cycle to set Leave Policy{" "}
          </p>
        )}

        {selectedOption !== "" && (
          <Form>
            <div
              className="body-content"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="privileged-leave monthly-content-body">
                <h4>Privileged Leave</h4>

                <Form.Group className="form-group">
                  <Form.Label>Allowed Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="allowed_leave0"
                      value={leaves.allowed_leave0}
                      data-leavename="Privileged Leave"
                      onChange={handleChangeInput}
                    />
                    <Form.Label>
                      leaves per{" "}
                      {selectedOption === "Monthly" ? "month" : "yearly"}
                    </Form.Label>
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Carry Forward Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="carryforward_leave0"
                      value={leaves.carryforward_leave0}
                      onChange={handleChangeInput}
                    />
                    <Form.Label>
                      leaves on{" "}
                      {selectedOption === "Monthly" ? "month" : "yearly"} end
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>
              {/* ========================== */}
              <div className="sick-leave monthly-content-body">
                <h4>Sick Leave</h4>
                <Form.Group className="form-group">
                  <Form.Label>Allowed Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="allowed_leave1"
                      value={leaves.allowed_leave1}
                      onChange={handleChangeInput}
                    />
                    <Form.Label>
                      leaves per{" "}
                      {selectedOption === "Monthly" ? "month" : "yearly"}
                    </Form.Label>
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Carry Forward Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="carryforward_leave1"
                      value={leaves.carryforward_leave1}
                      onChange={handleChangeInput}
                    />
                    <Form.Label>
                      leaves on{" "}
                      {selectedOption === "Monthly" ? "month" : "yearly"} end
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>
              {/* ========================== */}
              <div className="casual-leave monthly-content-body">
                <h4>Casual leave</h4>
                <Form.Group className="form-group">
                  <Form.Label>Allowed Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="allowed_leave2"
                      value={leaves.allowed_leave2}
                      onChange={handleChangeInput}
                    />
                    <Form.Label>
                      leaves per{" "}
                      {selectedOption === "Monthly" ? "month" : "yearly"}
                    </Form.Label>
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Carry Forward Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="carryforward_leave2"
                      value={leaves.carryforward_leave2}
                      onChange={handleChangeInput}
                    />
                    <Form.Label>
                      leaves on{" "}
                      {selectedOption === "Monthly" ? "month" : "yearly"} end
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Form>
        )}
        {/* {selectedOption === "Yearly" && (
          <Form>
            <div className="body-content">
              <div className="privileged-leave monthly-content-body">
                <h4>Privileged Leave</h4>

                <Form.Group className="form-group">
                  <Form.Label>Allowed Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="allowedLeaves"
                      value=""
                      onChange={() => {}}
                    />
                    <Form.Label>leaves per yearly</Form.Label>
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Carry Forward Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="carriedLeaves"
                      value=""
                      onChange={() => {}}
                    />
                    <Form.Label>leaves on yearly end</Form.Label>
                  </div>
                </Form.Group>
              </div>
              <div className="sick-leave monthly-content-body">
                <h4>Sick Leave</h4>
                <Form.Group className="form-group">
                  <Form.Label>Allowed Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="allowedLeaves"
                      value=""
                      onChange={() => {}}
                    />
                    <Form.Label>leaves per yearly</Form.Label>
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Carry Forward Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="carriedLeaves"
                      value=""
                      onChange={() => {}}
                    />
                    <Form.Label>leaves on yearly end</Form.Label>
                  </div>
                </Form.Group>
              </div>
              <div className="casual-leave monthly-content-body">
                <h4>Casual leave</h4>
                <Form.Group className="form-group">
                  <Form.Label>Allowed Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="allowedLeaves"
                      value=""
                      onChange={() => {}}
                    />
                    <Form.Label>leaves per yearly</Form.Label>
                  </div>
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Carry Forward Leaves</Form.Label>
                  <div className="input-label">
                    <Form.Control
                      name="carriedLeaves"
                      value=""
                      onChange={() => {}}
                    />
                    <Form.Label>leaves on yearly end</Form.Label>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Form>
        )} */}
      </div>
    </>
  );
};

const LeaveBalance = ({ user, userDetails, commonState }) => {
  const dispatch = useDispatch();
  const [leaves, setLeaves] = useState({
    leave_balance0: "",
    leave_balance1: "",
    leave_balance2: "",
  });
  // Variable for actual data to submit

  const handleChangeInput = (e) => {
    const {
      target: { name, value },
    } = e;
    setLeaves({
      ...leaves,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const lb = [];
    if (leaves.leave_balance0) {
      lb.push({
        leave_name: "Privilege leave",
        leave_balance: leaves.leave_balance0,
      });
    }
    if (leaves.leave_balance1) {
      lb.push({
        leave_name: "Sick leave",
        leave_balance: leaves.leave_balance1,
      });
    }
    if (leaves.leave_balance2) {
      lb.push({
        leave_name: "Casual leave",
        leave_balance: leaves.leave_balance2,
      });
    }
    const d = {
      path: "admin/empLeaveBalance",
      data: {
        userId: user.user_id,
        staffId: userDetails[0].staffId,
        leaves: lb,
      },
    };

    dispatch(commonSaveData(d));
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initState());
    }
  }, [commonState.commonSuccess]);

  return (
    <div className="LeaveBalance">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <ButtonCom
          type="button"
          class_name={"p_btn"}
          text="Update Details"
          url_or_onclick={handleSubmit}
          icon="null"
          width={""}
        />
      </div>

      <div className="LeaveBalance-card">
        <div className="body-content">
          <p>Remaining Balance</p>
        </div>
      </div>

      <Form style={{ marginTop: "-40px" }}>
        <div
          className="body-content"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="privileged-leave monthly-content-body">
            <Form.Group className="form-group">
              <Form.Label>Privileged Leave</Form.Label>
              <div className="input-label">
                <Form.Control
                  name="leave_balance0"
                  value={leaves.leave_balance0}
                  onChange={handleChangeInput}
                />
                <Form.Label>leaves</Form.Label>
              </div>
            </Form.Group>
          </div>
          <div className="sick-leave monthly-content-body">
            <Form.Group className="form-group">
              <Form.Label>Sick Leave</Form.Label>
              <div className="input-label">
                <Form.Control
                  name="leave_balance1"
                  value={leaves.leave_balance1}
                  onChange={handleChangeInput}
                />
                <Form.Label>leaves</Form.Label>
              </div>
            </Form.Group>
          </div>
          <div className="casual-leave monthly-content-body">
            <Form.Group className="form-group">
              <Form.Label>Casual leave</Form.Label>
              <div className="input-label">
                <Form.Control
                  name="leave_balance2"
                  value={leaves.leave_balance2}
                  onChange={handleChangeInput}
                />
                <Form.Label>leaves </Form.Label>
              </div>
            </Form.Group>
          </div>
        </div>
      </Form>
    </div>
  );
};

const LeaveRequests = () => {
  return (
    <div className="LeaveRequests">
      <div
        className="LeaveRequests-btn"
        style={{ gap: "10px", padding: "0 10px" }}
      >
        <ButtonCom
          type="button"
          class_name={"p_btn"}
          text="Pending"
          url_or_onclick={() => {}}
          icon={null}
          width={""}
        />
        <ButtonCom
          type="button"
          class_name={"p_btn"}
          text="History"
          url_or_onclick={() => {}}
          icon={null}
          width={""}
        />
      </div>

      <div className="LeaveRequests-input">
        <input
          placeholder="search staff bu name"
          id="LeaveRequests-input"
          style={{ borderRadius: "7px" }}
        />

        <select
          id="LeaveRequests-input"
          style={{ borderRadius: "7px", backgroundColor: "#ffffff" }}
        >
          <option value="Monthly">Branch</option>
        </select>

        <select
          id="LeaveRequests-input"
          style={{ borderRadius: "7px", backgroundColor: "#ffffff" }}
        >
          <option value="Monthly">Department</option>
        </select>

        <input
          id="LeaveRequests-input"
          type="date"
          style={{ borderRadius: "7px" }}
        />
      </div>
    </div>
  );
};

export default LeaveBalanceDetails;
