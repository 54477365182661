import React from 'react'
import LadingNaveBar from '../../components/Landing-page-components/ladingNaveBar/LadingNaveBar';
import LandingPage from '../LandingPage/LandingPage';
import { Outlet } from 'react-router-dom';

const LandingPageLayOut = () => {
  return (
    <div className="LandingPage">
      <LadingNaveBar />
      <div className="LandingPage-body">
        {/* <LandingPage /> */}
        <Outlet/>
      </div>
    </div>
  );
}

export default LandingPageLayOut