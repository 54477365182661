import "./Cards.css";
import Avatar from "@mui/material/Avatar";

export const Cards = ({ avatarSrc, title, count, supCount }) => {
  return (
    <div className="cards-body">
      <Avatar alt="Remy Sharp" src={avatarSrc} sx={{ width: 60, height: 60 }} />
      <p>{title}</p>
      <h2>
        {count} {supCount != "" ? "/" : null} <samp>{supCount}</samp>{" "}
      </h2>
    </div>
  );
};

export const WishesCards = ({ children, title }) => {
  return (
    <div className="WishesCards-body">
      <p>{title}</p>
      {children}
    </div>
  );
};

export const WishesCardsUserInfo = ({ avatarSrc, userName }) => {
  return (
    <>
      <div className="WishesCards-UserInfo">
        <div className="WishesCards-userName">
          <Avatar
            alt="Remy Sharp"
            src={avatarSrc}
            sx={{ width: 40, height: 40 }}
          />
          <p>
            {userName}
            <label>CEO & Founder</label>
          </p>
        </div>
        <button>Wish</button>
      </div>
    </>
  );
};
