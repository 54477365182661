import "./LadingPageCardThree.css";
import Avatar from "@mui/material/Avatar";
import icon from "../../../assets/icons/git.png";

const LadingPageCardThree = () => {
  return (
    <div className="LadingPageCard-three">
      <div className="LadingPageCard-three-heading">
        <h2>HCM Suite</h2>
        <p>
          Each application in the suite is designed to streamline and simplify
          your HR processes. Leverage Max applications to deliver a superlative
          EX.
        </p>
      </div>
      <div className="LadingPageCard-three-body">
        <div className="three-body-left">
          <div className="cards-LadingPageCard">
            <div className="box-card left-top">
              <p>Seek's HRMS</p>
              <label>
                Automate everyday HR tasks and collect employee’s data in a
                centralized platform. Leverage the same data to improve employee
                experience and make strategic decisions.
              </label>
            </div>
            <div className="box-card right-top">
              <p>Seek's ESS</p>
              <label>
                Help the employees by letting them help themselves. Make your
                employees less reliant on HR and let them take the reins for
                some specific functions with Employee Self Service Portal.
              </label>
            </div>
            <div className="box-card left-bottom">
              <p>Seek's Payroll</p>
              <label>
                Process payroll in just 3 simple clicks with 100% accuracy.
                Reduce the time spent calculating working hours, wages, and tax
                with holdings. With our powerful payroll solution, keep track of
                employee expenses of all sorts including travel, accommodation,
                and much more.
              </label>
            </div>
            <div className="box-card right-bottom">
              <p>Seek's Recruit</p>
              <label>
                Increase your hiring efficiency by 2X. Distribute jobs, source
                and screen talents, schedule interviews, share offer letters,
                and onboard new employees. Strengthen your new hire retention by
                82% and productivity by over 70%.
              </label>
            </div>
            <Avatar
              sx={{ scale: 1.7 }}
              alt="Remy Sharp"
              id="icon-a"
              src={icon}
            />
          </div>
        </div>
        <div className="three-body-right">
          <form>
            <div className="form_heading">
              <h3>See Seek's HRMS in Action</h3>
              <p>Get free HR consultation for your organization!</p>
            </div>
            <div className="input-tag">
              <input id="name" placeholder="Name*" />
              <input id="companyName" placeholder="Company Name*" />
              <input id="empoly" placeholder="Number of Employees*" />
              <input id="businessName" placeholder="Business Name*" />
              <input id="india" placeholder="india" />
              <input id="phoneNumber" placeholder="+91" />
            </div>
            <p>By Submitting the form, you agree to Seek's Privacy Policy</p>
            <div className="form-btn">
              <button>Schedule Demo</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LadingPageCardThree;
