import React, { useState } from "react";
// icons
import MarkerIcon from "../../../assets/icons/location-position.png";
import { TbCurrentLocation } from "react-icons/tb";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Map = ({
  center,
  markers = [],
  zoom,
  width = "100%",
  height = "100%",
  radius = 1000,
  handleMarkerDrag,
  handleGetCurrentLocation,
  draggable = true,
}) => {
  const [isBtnView, setIsBtnView] = useState(false);

  const containerStyle = {
    width: width,
    height: height,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    setIsBtnView(true);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
    setIsBtnView(false);
  }, []);

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      <>
        <Circle
          center={center}
          radius={radius}
          options={{
            strokeColor: "#4B0082",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#E6E6FA",
            fillOpacity: 0.35,
          }}
        />

        {markers.map((marker) => (
          <Marker
            key={marker.id}
            label={marker.label}
            position={marker.position}
            icon={{
              url: MarkerIcon,
              width: "24px",
              height: "24px",
            }}
            draggable={draggable}
            onDragEnd={(event) => {
              handleMarkerDrag(event);
            }}
          />
        ))}
      </>

      {isBtnView && (
        <span
          onClick={handleGetCurrentLocation}
          style={{
            position: "absolute",
            bottom: "165px",
            right: "10px",
            padding: "3px",
            cursor: "pointer",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <TbCurrentLocation style={{ fontSize: "20px" }} />
        </span>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(Map);



// useEffect(() => {
//   if (center) {
//     mapRef.current.setCenter(center); // Assuming mapRef points to the map instance
//   }
// }, [center]);

// useEffect(() => {
//   if (markers && mapRef.current) {
//     markers.forEach((marker) => {
//       new window.google.maps.Marker({
//         position: marker.position,
//         map: mapRef.current,
//         label: marker.label,
//       });
//     });
//   }
// }, [markers]);

