import { PropaneSharp } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URI;
// mSet = more settings eg. mSetLoading = more settings loading
let initialState = {
  mSetLoading: false,
  mSetSuccess: false,
  mSetError: false,
  mSetMessage: null,

  mSetGetLoading: false,
  mSetGetSuccess: false,
  mSetGetError: false,
  mSetGetMessage: null,
  mSetGetData: null,

  mSetGetAttLoading: false,
  mSetGetAttSuccess: false,
  mSetGetAttError: false,
  mSetGetAttMessage: null,
  mSetGetAttData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
const userId = user?.user_id;
const accountType = user?.account_type;

export const getAttendOptions = createAsyncThunk(
  "getAttendOptions",
  async (path) => {
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
          let data = {
            response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
          };
          return data;
        } else {
          return error;
        }
      });
  }
);

export const getMoreSettings = createAsyncThunk(
  "getMoreSettings",
  async (path) => {
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
          let data = {
            response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
          };
          return data;
        } else {
          return error;
        }
      });
  }
);

export const saveMoreSettings = createAsyncThunk(
  "saveMoreSettings",
  async (body) => {
    const { path, data } = body;
    const url = baseUrl + path;

    const result = {
      data: {
        status: 200,
        message: "Sorry, you can only view the data, not create it.",
        data: [],
      },
    };
    return parseInt(accountType) === 2
      ? result
      : await axios
          .post(url, data, config)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (!navigator.onLine) {
              let data = {
                response: {
                  data: [],
                  message: "No Internet Connection Found",
                  success: false,
                },
              };
              return data;
            } else {
              return error;
            }
          });
  }
);

const moreSettingSlice = createSlice({
  name: "branch",
  initialState,

  reducers: {
    initMoreSettState: (state) => {
      state.mSetLoading = false;
      state.mSetSuccess = false;
      state.mSetError = false;
      state.mSetMessage = null;

      state.mSetGetLoading = false;
      state.mSetGetSuccess = false;
      state.mSetGetError = false;
      state.mSetGetMessage = null;

      state.mSetGetAttLoading = false;
      state.mSetGetAttSuccess = false;
      state.mSetGetAttError = false;
      state.mSetGetAttMessage = null;
      state.mSetGetAttData = null;
    },
  },

  extraReducers: (builder) => {
    // Get Attend options
    builder.addCase(getAttendOptions.pending, (state, action) => {
      state.mSetGetAttLoading = true;
      state.mSetGetAttSuccess = false;
      state.mSetGetAttError = false;
      state.mSetGetAttMessage = null;
      state.mSetGetAttData = null;
    });
    builder.addCase(getAttendOptions.fulfilled, (state, action) => {
      state.mSetGetAttLoading = false;
      state.mSetGetAttSuccess = true;
      state.mSetGetAttError = false;
      state.mSetGetAttMessage = action.payload.data.message;
      state.mSetGetAttData = action.payload.data.data;
    });
    builder.addCase(getAttendOptions.rejected, (state, action) => {
      state.mSetGetAttLoading = false;
      state.mSetGetAttSuccess = false;
      state.mSetGetAttError = true;
      state.mSetGetAttMessage = action.payload.data.message;
      state.mSetGetAttData = null;
    });
    // Get more settings
    builder.addCase(getMoreSettings.pending, (state, action) => {
      state.mSetGetLoading = true;
      state.mSetGetSuccess = false;
      state.mSetGetError = false;
      state.mSetGetMessage = null;
      state.mSetGetData = null;
    });
    builder.addCase(getMoreSettings.fulfilled, (state, action) => {
      state.mSetGetLoading = false;
      state.mSetGetSuccess = true;
      state.mSetGetError = false;
      // state.mSetGetMessage = action.payload.data.message;
      // state.mSetGetData = action.payload.data.data;
    });
    builder.addCase(getMoreSettings.rejected, (state, action) => {
      state.mSetGetLoading = false;
      state.mSetGetSuccess = false;
      state.mSetGetError = true;
      // state.mSetGetMessage = action.payload.data.message;
      state.mSetGetData = null;
    });
    // To create branch
    builder.addCase(saveMoreSettings.pending, (state, action) => {
      state.mSetLoading = true;
      state.mSetSuccess = false;
      state.mSetError = false;
      state.mSetMessage = null;
    });
    builder.addCase(saveMoreSettings.fulfilled, (state, action) => {
      state.mSetLoading = false;
      state.mSetSuccess = action.payload.status == 200 ? true : false;
      state.mSetError = action.payload.status == 200 ? false : true;
      state.mSetMessage =
        action.payload.status == 200
          ? action.payload.data.message
          : action.payload.response.data.message;
    });
    builder.addCase(saveMoreSettings.rejected, (state, action) => {
      state.mSetLoading = false;
      state.mSetSuccess = false;
      state.mSetError = true;
      state.mSetMessage = action.payload.data.message;
    });
  },
});

export const { initMoreSettState } = moreSettingSlice.actions;

export default moreSettingSlice.reducer;
