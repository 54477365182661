import "./LadingPageCardOne.css";
import magnifying from "../../../assets/Images/magnifying glass.png";
import { Link } from "react-router-dom";

const LadingPageCardOne = ({ scrollToCardThree }) => {
  return (
    <main className="LadingPageCardOne">
      <div className="LadingPageCardOne-left">
        <div className="LadingPageCardOne-left-container">
          <p>DESIGN A COMPLETE</p>
          <h1>EMPLOYEE EXPERIENCE</h1>
          <p className="label">
            Adrenalin MAX HCM suite is a Contextual, Contemporary, and
            Composable application that digitizes employee.
          </p>
          <div className="LadingPageCardOne-buttons">
            <button onClick={scrollToCardThree}>BOOK A DEMO</button>
            <Link to={"login"}>
              <button>Login</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="LadingPageCardOne-right">
        <img src={magnifying} alt="logo" />
      </div>
    </main>
  );
};

export default LadingPageCardOne;
