import { useCallback, useEffect, useState } from "react";
import "./AddStaff.css";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { createEmployee } from "../../../redux/slices/employeeSlice";
import { fetchBranchData, initBranchState } from "../../../redux/slices/branchSlice";
import { fetchDepartmentData, initState } from "../../../redux/slices/departmentSlice";
import {
  commonGetData,
  initCommonState,
} from "../../../redux/slices/commonSlice";

const animatedComponents = makeAnimated();

const AddStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeState = useSelector((state) => state.employee);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const geDataLists = useCallback(() => {
    dispatch(fetchBranchData(user.user_id));
    dispatch(fetchDepartmentData(user.user_id));
    dispatch(commonGetData(`admin/getEmployerIdList?userId=${user.user_id}`));
  }, []);

  const branchState = useSelector((state) => state.branch);
  const departmentState = useSelector((state) => state.department);
  const commonState = useSelector((state) => state.common);

  useEffect(() => {
    geDataLists();
  }, []);

  const [branchList, setBranchList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [employerList, setEmployerList] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (departmentState.depGetSuccess) {
      const newArr = departmentState.depGetData.map((v) => {
        return { label: v.department_name, value: v.departmentId };
      });
      setDepartmentList(newArr);
      dispatch(initCommonState())
    }
    if (branchState.brnGetSuccess) {
      const newArr = branchState.brnGetData.map((v) => {
        return { label: v.branch_name, value: v.branchId };
      });
      setBranchList(newArr);
      dispatch(initBranchState())
    }
    if (commonState.commonGetSuccess) {
      if (commonState.commonGetData.length < 1) {
        handleShow();
        return;
      }
      setEmployerList(commonState.commonGetData);
      dispatch(initState())
    }
  }, [
    departmentState.depGetSuccess,
    branchState.brnGetSuccess,
    commonState.commonGetSuccess,
  ]);

  // Function if there is no employer id is available
  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteSubmit = () => {
    navigate("/setting/my-employee-id");
    handleModalClose();
  };
  // -------------------------------

  const inputsOne = [
    {
      type: "text",
      name: "name",
      label: "Name",
      placeholder: "Enter Name",
      required: true,
    },
    {
      type: "text",
      name: "jobTitle",
      label: " Job Title",
      placeholder: "Enter  Job Title",
    },
    {
      type: "text",
      name: "branch",
      label: "Branch",
      placeholder: "Enter Branch",
      required: true,
      isMulti: true,
      chi: branchList,
    },
    {
      type: "text",
      name: "department",
      label: "Department",
      placeholder: "Enter  Department",
      isMulti: false,
      chi: departmentList,
    },
  ];

  const inputsTwo = [
    // {
    //   type: "select",
    //   name: "gender",
    //   label: "Gender",
    //   chi: ["Select gender", "Male", "Female"],
    // },
    {
      type: "email",
      name: "officeEmailId",
      required: true,
      label: "Office Email Id",
      placeholder: "Enter Office Email Id",
    },
    {
      type: "email",
      name: "personalEmailId",
      required: true,
      label: "Personal Email Id",
      placeholder: "Enter Personal Email Id",
    },
    // {
    //   name: "dateOfJoining",
    //   label: "Date Of Joining",
    //   placeholder: "Enter Date Of Joining",
    //   type: "date",
    // },

    {
      type: "text",
      name: "empId",
      required: true,
      label: "Employer Id",
      placeholder: "Enter Employer Id",
      chi: employerList,
    },
  ];

  const [saveBranch, setSaveBranch] = useState({
    modules: [],
  });

  const [addStaff, setAddStaff] = useState({
    userId: user?.user_id,
    name: "",
    jobTitle: "",
    branch: "",
    department: "",
    code: "+91",
    number: "",
    loginOtp: "",
    empId: "",
    gender: "",
    officeEmailId: "",
    dateOfJoining: "",
    address: "",
  });
  const [validated, setValidated] = useState(false);

  const handleBranchChange = (e) => {
    setSaveBranch({
      ...saveBranch,
      modules: e,
    });
    setModuleError(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setAddStaff((pv) => ({ ...pv, [name]: value }));
  };

  const [moduleError, setModuleError] = useState(false);

  const handelSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      if (saveBranch.modules.length < 1) {
        setModuleError(true);
      }
    } else {
      let data = {
        userId: user?.user_id,
        cmp_name: user?.company_name,
        fullname: addStaff.name,
        title: addStaff.jobTitle,
        branch: saveBranch.modules.map((v) => v.value).toString(),
        department: addStaff.department,
        emp_mobile: addStaff.number,
        pwd: addStaff.loginOtp,
        empId: addStaff.empId,
        gender: addStaff.gender,
        office_email: addStaff.officeEmailId,
        dateof_join: addStaff.dateOfJoining,
        address: addStaff.address,
      };
      dispatch(createEmployee(data));
    }
  };

  useEffect(() => {
    if (employeeState.empSuccess) {
      toast.success(employeeState.empMessage);
      setAddStaff({
        name: "",
        jobTitle: "",
        branch: "",
        department: "",
        code: "+91",
        number: "",
        loginOtp: "",
        empId: "",
        gender: "",
        officeEmailId: "",
        dateOfJoining: "",
        address: "",
      });
      setSaveBranch({
        ...saveBranch,
        modules: [],
      });
    }
    if (employeeState.empError) {
      toast.error(employeeState.empMessage);
    }
  }, [employeeState.empSuccess, employeeState.empError]);

  const checkLength = (e) => {
    if (e.target.value.length === e.target.maxLength) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    return true;
  };

  const checkLengthHandle = (e) => {
    const { name, value } = e.target;
    // Allow only numbers and enforce maxLength
    if (value === "" || (/^[0-9\b]+$/.test(value) && value.length <= 8)) {
      setAddStaff({ ...addStaff, [name]: value });
    }
  };

  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if (e.key == "Backspace") {
      setAddStaff({ ...addStaff, [name]: value });
    } else if (!/[0-9]/.test(e.key)) {
      e.preventDefault(); // Prevent any non-numeric input
    }
  };

  return (
    <>
      <Modal show={show}>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            Please Create an Employer id first to add staff here.
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="info"
            style={{ color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            GO
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="back">
        <Link to={"/empmanagement"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Add Staff </p>
      </div>
      <div className="AddStaff ">
        <Form noValidate validated={validated} onSubmit={handelSubmit}>
          <Row>
            {inputsOne.map((item, i) => (
              <Col sm="6" className="mt-3" key={i}>
                <Form.Group>
                  <Form.Label>
                    {item.label}
                    <span style={{ color: "red" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </Form.Label>

                  {item.isMulti && item.chi ? (
                    <>
                      <FormControl
                        style={{
                          width: "100%",
                          border: moduleError && "1px solid red",
                          borderRadius: "7px",
                        }}
                      >
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          value={saveBranch.modules}
                          name={item.name}
                          isMulti
                          placeholder="Select branch"
                          options={item.chi}
                          onChange={handleBranchChange}
                        />
                      </FormControl>
                      {moduleError && (
                        <label style={{ color: "red", fontSize: "14px" }}>
                          Please select a valid branch
                        </label>
                      )}
                    </>
                  ) : !item.isMulti && item.chi ? (
                    <Form.Select
                      style={{ width: "100%", color: "gray" }}
                      name={item.name}
                      onChange={handelChange}
                      value={addStaff[item.name]}
                    >
                      <option value="">Select Department</option>
                      {item.chi.map((v, id) => {
                        return (
                          <option value={v.value} key={id}>
                            {v.label}
                          </option>
                        );
                      })}
                      {item.chi.length < 1 && (
                        <option value="">
                          <Link to=""> Add Department </Link>
                        </option>
                      )}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name={item.name}
                      type={item.type || ""}
                      placeholder={item.placeholder}
                      value={addStaff[item.name]}
                      onChange={handelChange}
                      required={item.required ? true : false}
                      style={{ marginBottom: "10px" }}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid {item.label}.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}
            <Col sm="6" className="mt-3">
              <Form.Group>
                <Form.Label>
                  Mobile Number <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="number"
                    pattern="\d*"
                    maxlength="10"
                    // onKeyDown={checkLength}
                    placeholder={"Enter Mobile Number hai"}
                    aria-label="number"
                    aria-describedby="basic-addon1"
                    required
                    value={addStaff.number}
                    onChange={handelChange}
                    onKeyDown={() => {
                      checkLengthHandle, handleKeyPress;
                    }}
                    style={{
                      borderTopRightRadius: "7px",
                      borderBottomRightRadius: "7px",
                      zIndex: 0,
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid mobile number.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            {inputsTwo.map((item, i) =>
              item.chi ? (
                <Col sm="6" className="mt-3" key={i}>
                  <Form.Group>
                    <Form.Label>
                      {item.label}
                      <span style={{ color: "red" }}>
                        {item.required ? "*" : ""}
                      </span>
                    </Form.Label>
                    <Form.Select
                      required
                      name={item.name}
                      onChange={handelChange}
                      value={addStaff[item.name]}
                      style={{ color: "gray" }}
                    >
                      <option value="">Select Employer ID</option>
                      {item.chi.map((dv, i) => (
                        <option key={i} value={dv.employerId}>
                          {dv.employerIdName}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a valid option.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              ) : (
                <Col
                  sm={item.type == "date" ? "12" : "6"}
                  key={i}
                  className="mt-3"
                >
                  <Form.Group>
                    <Form.Label>
                      {item.label}
                      <span style={{ color: "red" }}>
                        {item.required ? "*" : ""}
                      </span>
                    </Form.Label>

                    <Form.Control
                      name={item.name}
                      type={item.type || ""}
                      value={addStaff[item.name]}
                      placeholder={item.placeholder}
                      onChange={handelChange}
                      required={item.required ? true : false}
                    />

                    <Form.Control.Feedback type="invalid">
                      Please provide a valid {item.label}.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              )
            )}
          </Row>

          <div className="submit-btn">
            <button type="submit">save</button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddStaff;
