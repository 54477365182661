import React from 'react'
import { Outlet } from 'react-router-dom'

const SettingLayOut = ({children}) => {
  return (
    <>
      {children}
    </>
    // <div><Outlet/></div>
  )
}

export default SettingLayOut