import { Outlet } from 'react-router-dom'
import './AdminPanelLayOut.css'
import AdminNavBare from '../../components/Admin-page-componets/Admin-navBare/AdminNavBare'
// import AdminSlideBar from '../../components/Admin-page-componets/AdminSlideBar/AdminSlideBar';
import { useState } from 'react';


const AdminPanelLayOut = () => {
  
  
  return (
    <div className="AdminPanelLayOut">
      <AdminNavBare  />
      <div className="AdminPanelLayOut-body">
        <div className="AdminPanelLayOut-body-right">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminPanelLayOut