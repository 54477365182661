import { PropaneSharp } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URI;

let initialState = {
  brnLoading: false,
  brnSuccess: false,
  brnError: false,
  brnMessage: null,

  brnGetLoading: false,
  brnGetSuccess: false,
  brnGetError: false,
  brnGetMessage: null,
  brnGetData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
const userId = user?.user_id;
const accountType = user?.account_type;

export const fetchBranchData = createAsyncThunk("fetchBranchData", async () => {
  return await axios
    .get(`${baseUrl}admin/getBranchList?userId=${userId}`, {}, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
        }
         return data
      } else {
        return error
      }
      
    });
});

export const saveBranch = createAsyncThunk("saveBranch", async (data) => {
  const result = {
    data: {
      message: "Sorry, you can only view the data, not create it.",
      data: [],
    },
  };

 
  return parseInt(accountType) === 2
    ? result
    : await axios
        .post(`${baseUrl}admin/createBranch`, data, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
        alert('You are offline. Please check your internet connection.');
      } else {
        alert('An error occurred. Please try again later.');
      }
      let data = {
        response: {
          data : [],
          message: "No Internet Connection Found",
          success: false
        },
      }
       return data
        });
});

const branchSlice = createSlice({
  name: "branch",
  initialState,

  reducers: {
    initState: (state) => {
      state.brnLoading = false;
      state.brnSuccess = false;
      state.brnError = false;
      state.brnMessage = null;

      state.brnGetLoading = false;
      state.brnGetSuccess = false;
      state.brnGetError = false;
      state.brnGetMessage = null;
    },
    initBranchState: (state) => {
      state.brnLoading = false;
      state.brnSuccess = false;
      state.brnError = false;
      state.brnMessage = null;

      state.brnGetLoading = false;
      state.brnGetSuccess = false;
      state.brnGetError = false;
      state.brnGetMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchBranchData.pending, (state, action) => {
      state.brnGetLoading = true;
      state.brnGetSuccess = false;
      state.brnGetError = false;
      state.brnGetMessage = null;
      state.brnGetData = null;
    });
    builder.addCase(fetchBranchData.fulfilled, (state, action) => {
      state.brnGetLoading = false;
      state.brnGetSuccess = true;
      state.brnGetError = false;
      state.brnGetMessage = action.payload.data.message;
      state.brnGetData = action.payload.data.data;
    });
    builder.addCase(fetchBranchData.rejected, (state, action) => {
      state.brnGetLoading = false;
      state.brnGetSuccess = false;
      state.brnGetError = true;
      state.brnGetMessage = action.payload.data.message;
      state.brnGetData = null;
    });
    // To create branch
    builder.addCase(saveBranch.pending, (state, action) => {
      state.brnLoading = true;
      state.brnSuccess = false;
      state.brnError = false;
      state.brnMessage = null;
    });
    builder.addCase(saveBranch.fulfilled, (state, action) => {
      console.log(action)
      state.brnLoading = false;
      state.brnSuccess = accountType == 2 ? false : true;
      state.brnError = accountType == 2 ? true : false;
      state.brnMessage = action.payload.data.message;
    });
    builder.addCase(saveBranch.rejected, (state, action) => {
      state.brnLoading = false;
      state.brnSuccess = false;
      state.brnError = true;
      state.brnMessage = action.payload.data.message;
    });
  },
});

export const { initState, initBranchState } = branchSlice.actions;

export default branchSlice.reducer;
