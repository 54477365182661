import { EmbedPDF } from "@simplepdf/react-embed-pdf";

const PDFreader = ({ file }) => {
  return (
    <EmbedPDF
      mode="inline"
      style={{ width: "100%", height: 800 }}
      documentURL="https://internaltestinghrms.sktexus.com/assets/emp_documents/company_gst_6761311200_20241017030556.pdf"
    />
  );
};

export default PDFreader;
