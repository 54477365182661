import { useState, useEffect, useCallback } from "react";
import "./DocumentType.css";
import ArticleIcon from "@mui/icons-material/Article";
import TableMui from "../../../components/table/TableMui";
import ButtonCom from "../../../components/button/button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form } from "react-bootstrap";
// Redux -----------------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux";
import {
  commonGetData,
  commonSaveData,
  commonGetDataThird,
  initState,
} from "../../../redux/slices/commonSlice";
import { toast, ToastContainer } from "react-toastify";

const DocumentType = ({ user, userDetails, commonState }) => {
  const dispatch = useDispatch();
  // const commonState = useSelector((state) => state.common);
  const [documentType, setDocumentType] = useState([]);

  const [docList, setDocList] = useState([]);
  const getDocumentList = useCallback(() => {
    dispatch(commonGetDataThird(`admin/getStaffDocumentList?staffId=${userDetails[0].staffId}`));
    dispatch(commonGetData("/admin/getDocumentsList"))
  }, []);

  useEffect(() => {
    getDocumentList();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      setDocumentType(commonState.commonGetData);
      dispatch(initState());
    }
    if (commonState.commonGetThirdSuccess) {
      setDocList(commonState.commonGetThirdData);
      dispatch(initState());
    }
  }, [commonState.commonGetSuccess, commonState.commonGetThirdSuccess]);

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [documentData, setDocumentData] = useState({
    userId: user.user_id,
    staffId: userDetails[0].staffId,
    doc_type: "",
    fileBase64: "",
  });
  
  
  const heading = [
    {
      name: "Document Type",
      label: "doc_type",
    },
    {
      name: "File Name",
      label: "file_name",
    },
    {
      name: "Actions",
      label: "actions",
    },
  ];

  const handleClose = () => {
    setValidated(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const fileHandle = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const base64 = event.target.result;
      setDocumentData({
        ...documentData,
        fileBase64: base64,
      });
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocumentData({
      ...documentData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let d = {
        path: "/admin/AddEmployeeDocuments",
        data: documentData,
      };
      dispatch(commonSaveData(d));
    }
  };
  
  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      handleClose();
      dispatch(initState());
      getDocumentList();
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      handleClose();
      dispatch(initState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  return (
    <div className="body-container p-2">
    
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="DocumentType ">
        <div className="DocumentType-Head">
          <p>Document Type</p>
          <ButtonCom
            type="button"
            class_name="p_btn"
            text="Add Document"
            url_or_onclick={handleClickOpen}
            icon={<ArticleIcon />}
            width={""}
          />
        </div>
        <div className="DocumentTyp-table">
          <TableMui
            tableHead={heading}
            data={docList}
            rowPagesPlay={true}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            Pages={10}
            editId={false}
          />
        </div>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle
            style={{
              borderBottom: "1px solid lightgray",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Upload Document
          </DialogTitle>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <DialogContent>
              <Form.Group>
                <Form.Label>Document Type</Form.Label>
                <Form.Select
                  name="doc_type"
                  onChange={handleChange}
                  aria-label="Default select example"
                  required
                >
                  <option value={""}>Select document type</option>
                  {documentType?.map((row, idx) => {
                    return (
                      <option value={row.docId} key={idx}>
                        {row.doc_name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a valid document type.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Select Document</Form.Label>
                <Form.Control
                  type="file"
                  name="fileBase64"
                  onChange={fileHandle}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select a valid file.
                </Form.Control.Feedback>
              </Form.Group>
            </DialogContent>
            <DialogActions style={{ borderTop: "1px solid lightgray" }}>
              <Button
                variant="contained"
                color="gray"
                onClick={handleClose}
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  height: "38px",
                  backgroundColor: "gray",
                  color: "white",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="info"
                type="submit"
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  height: "38px",
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      </div>
    </div>
  );
};

export default DocumentType;
