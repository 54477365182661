import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Form } from "react-bootstrap";

function ExcelReader({ onChange }) {
  const [excelData, setExcelData] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0]; // Get the first sheet name
      const worksheet = workbook.Sheets[sheetName];

      // Get raw data including header and all rows
      const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Set the second row (index 1) as the header
      const headers = rawData[1];

      // Extract the rest of the data (from row 3 onwards)
      const rows = rawData.slice(2);

      // Map rows to an array of objects using the second row as headers
      // Map rows to an array of objects using the second row as headers
      const formattedData = rows
        .filter((row) =>
          row.some((cell) => cell !== undefined && cell !== null && cell !== "")
        ) // Remove blank rows
        .map((row) => {
          const rowData = {};
          headers.forEach((header, index) => {
            rowData[header] = row[index] == undefined ? "" : row[index];
          });
          return rowData;
        });
      setExcelData(formattedData);
      onChange(formattedData);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <Form>
        <Form.Control
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          style={{ width: "100%" }}
          required
        />
      </Form>
      {/* <table>
        <thead>
          <tr>
            {excelData.length > 0 &&
              Object.keys(excelData[0]).map((key) => <th key={key}>{key}</th>)}
          </tr>
        </thead>
        <tbody>
          {excelData.map((row, index) => (
            <tr key={index}>
              {Object.values(row).map((cell, i) => (
                <td key={i}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
}

export default ExcelReader;
