export const formValidation = (item) => {
  const valueTrim = {};
  const errorItem = {};

  Object.entries(item).map(([key, value]) => {
    // valueTrim[key] = value.trim();

    if (typeof value === "string") {
      valueTrim[key] = value.trim();
    } else {
      valueTrim[key] = value;
    }
  });

  Object.entries(item).map(([key, value]) => {
    errorItem[key] = value ? false : true;
  });

  const value = Object.values(item).every((value) => value !== "");

  return { valueTrim, errorItem, value };
};

export const validateISSN = (item) => {
  const isnTest = /^\d{4}-\d{4}$/;
  return isnTest.test(item);
};

export const emailValidator = (em) => {
  const email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email.test(em);
};
