import "./Breaks.css";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import ButtonCom from "../../../components/button/button";

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";

import { useDispatch, useSelector } from "react-redux";
import {
  commonSaveData,
  commonGetData,
  initState,
  initCommonState,
} from "../../../redux/slices/commonSlice";

const Breaks = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const [currentPage, setCurrentPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;

  const [breakList, setBreakList] = useState([]);
  const [breakData, setBreakData] = useState({
    userId: user?.user_id,
    breakId: null,
    breakName: "",
    breakType: "",
    break_hour: "",
    break_minutes: "",
  });

  const getBreakList = useCallback(() => {
    dispatch(commonGetData(`admin/getMyBreaksList?userId=${user?.user_id}`));
  }, []);

  useEffect(() => {
    getBreakList();
  }, []);

  useEffect(() => {
    if (state.commonGetSuccess) {
      setBreakList(state.commonGetData);
      dispatch(initState());
    }
  }, [state.commonGetSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setBreakData({
      ...breakData,
      breakName: "",
      breakType: "",
      break_hour: "",
      break_minutes: "",
    });
    setValidated(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBreakData({
      ...breakData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const d = {
        path: "admin/createMyBreaks",
        data: breakData,
      };
      dispatch(commonSaveData(d));
    }
  };

  useEffect(() => {
    if (state.commonSuccess) {
      handleClose();
      dispatch(initState());
      setBreakData({
        ...breakData,
        breakName: "",
        breakType: "",
        break_hour: "",
        break_minutes: "",
      });
      getBreakList();
    }
  }, [state.commonSuccess]);

  // Edit functions
  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = (row) => {
    setIsEdit(true);
    setBreakData({
      ...breakData,
      breakId: row.breakId,
      breakName: row.break_name,
      breakType: row.break_type,
      break_hour: row.break_duration_hour,
      break_minutes: row.break_duration_minutes,
    });
    handleClickOpen();
  };
 
  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateBreaks",
      data: breakData,
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.breakId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteBreaks",
      data: {
        userId: user?.user_id,
        breakId: delId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (state.commonSuccess) {
      toast.success(state.commonMessage);
      setOpen(false);
      getBreakList();
      setIsEdit(false);
      dispatch(initCommonState());
    }
    if (state.commonError) {
      toast.error(state.commonMessage);
      setOpen(false);
      getBreakList();
      dispatch(initCommonState());
    }
  }, [state.commonSuccess, state.commonError]);

  const columns = [
    {
      name: "Break Name",
      selector: (row) => row.break_name,
    },
    {
      name: "Break Type",
      selector: (row) => row.break_type,
    },

    {
      name: "Duration Hour",
      selector: (row) => row.break_duration_hour + " h",
    },
    {
      name: "Duration Minutes",
      selector: (row) => row.break_duration_minutes + " m",
    },

    {
      name: "Status",
      selector: (rowData) => {
        return rowData.status == 1 ? "Active" : "InActive";
      },
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div>
            <CiEdit
              style={{
                cursor: "pointer",
                fontSize: 30,
                color: "blue",
                marginRight: "10px",
              }}
              onClick={() => {
                handleEdit(row);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(row)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px", // Set a width for the action buttons
    },
  ];

  const customStyles = {
    table: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex", // Border around the entire table
      },
    },
    rows: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    cells: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    header: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
      },
    },
    headCells: {
      style: {
        textAlign: "center", // Center-align header text
        justifyContent: "center", // Additional property to center content horizontally
        display: "flex",
        borderRight: "1px solid lightgray",
      },
    },
  };

  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Break</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Breaks</p>
      </div>

      <div className="SettingBody-card mt-4" style={{ borderRadius: "7px" }}>
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="Branches-btn">
            <ButtonCom
              type={"button"}
              class_name={"p_btn"}
              text={"Add Break"}
              ext
              url_or_onclick={handleClickOpen}
              icon={<AddIcon />}
              width={""}
            />
          </div>
          <div className="Branches-table" style={{ height: "100vh" }}>
            <DataTable
              columns={columns}
              data={breakList}
              pagination
              subHeader
              customStyles={customStyles}
            />
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle style={{ fontSize: "18px", fontWeight: "bold" }}>
              Add New Break
            </DialogTitle>
            <div style={{ overflow: "auto" }}>
              <Form
                noValidate
                validated={validated}
                onSubmit={isEdit ? handleEditSubmit : handleSubmit}
              >
                <DialogContent>
                  <Row>
                    <Col sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          Break Name <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="breakName"
                          value={breakData.breakName}
                          placeholder="Enter Break Name"
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid break name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* --------------------------------------------------- */}
                    <Col sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          Break Type
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="breakType"
                          value={breakData.breakType}
                          placeholder="Enter break type"
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid break type.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* ----------------------------------------------------- */}
                  </Row>

                  <Row>
                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Hour</Form.Label>
                        <Form.Control
                          type="text"
                          name="break_hour"
                          value={breakData.break_hour}
                          placeholder="Enter hours"
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid hours.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col sm="6">
                      <Form.Group>
                        <Form.Label>Minuts</Form.Label>
                        <Form.Control
                          type="text"
                          name="break_minutes"
                          value={breakData.break_minutes}
                          placeholder="Enter minutes"
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid minutes.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="gray"
                    onClick={handleClose}
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Breaks;
