import { useState, useEffect, useRef, useCallback } from "react";
import "./EmploymentDetails.css";
import ButtonCom from "../../../components/button/button";

import { AgGridReact } from "ag-grid-react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText } from "@mui/material";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useDispatch, useSelector } from "react-redux";
import {
  commonGetData,
  commonSaveData,
  initState,
} from "../../../redux/slices/commonSlice";
import {
  fetchBranchData,
  initBranchState,
} from "../../../redux/slices/branchSlice";
import { fetchDepartmentData } from "../../../redux/slices/departmentSlice";
import { fetchDesignationList } from "../../../redux/slices/designationSlice";

const animatedComponents = makeAnimated();

const EmploymentDetails = ({ user, userDetails, commonState }) => {
  return (
    <div className="body-container">
      <div className="page-top-bar">
        <h1>Employment Details</h1>
      </div>
      <div className="page-body">
        {userDetails.length && (
          <CurrentEmployment
            user={user}
            userDetails={userDetails[0]}
            commonState={commonState}
          />
        )}
      </div>

      <div className="page-body mt-2">
        {userDetails.length && (
          <PastEmployment
            user={user}
            userDetails={userDetails[0]}
            commonState={commonState}
          />
        )}
      </div>
    </div>
  );
};
const CurrentEmployment = ({ user, userDetails, commonState }) => {
  //empAttendanceDetails
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const branchState = useSelector((state) => state.branch);
  const departmentState = useSelector((state) => state.department);

  const [empTypeList, setEmpTypeList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const getEmpList = useCallback(() => {
    dispatch(commonGetData(`admin/getEmployeeTypeList`));
    dispatch(fetchBranchData(user.user_id));
    dispatch(fetchDepartmentData(user.user_id));
  }, []);

  useEffect(() => {
    getEmpList();
  }, []);

  useEffect(() => {
    if (state.commonGetSuccess) {
      const newArr = state.commonGetData?.map((v) => {
        return { label: v.type_name, value: v.employeeTypeId };
      });
      setEmpTypeList(newArr);
    }

    if (departmentState.depGetSuccess) {
      const newArr = departmentState.depGetData?.map((v) => {
        return { label: v.department_name, value: v.departmentId };
      });
      setDepartmentList(newArr);
    }
  }, [state.commonGetSuccess, departmentState.depGetSuccess]);

  useEffect(() => {
    if (branchState.brnGetSuccess) {
      if (
        branchState.brnGetData &&
        branchState.brnGetData.length > 0 &&
        Array.isArray(branchState.brnGetData)
      ) {
        const newArr = branchState.brnGetData.map((v) => {
          return { label: v.branch_name, value: v.branchId };
        });
        const sortArr = newArr.sort((a, b) => (a.value < b.value ? -1 : 1));
        setBranchList(sortArr);
      }
      dispatch(initBranchState());
    }
  }, [branchState.brnGetSuccess]);

  const empoyeeDetails =
    userDetails.empoyeeDetails !== undefined &&
    userDetails.empoyeeDetails.staff_id !== undefined
      ? userDetails.empoyeeDetails
      : null;

  const [updateEmployeeDetails, setUpdateEmployeeDetails] = useState({
    staffId: userDetails !== undefined ? userDetails.staffId : "",
    department: userDetails.department,
    employee_type: userDetails.emp_role,
    date_of_joining: userDetails.date_of_join,
    employee_id: userDetails.personalDetails.empId,
    job_title: userDetails.job_title,
    official_email: userDetails.official_email,
    esi_number: userDetails.esi_number,
    pf_number: userDetails.pf_number,

    employmentDetails: [
      {
        employementNumber: userDetails.employementNumber,
        companyName: userDetails.companyName,
        designation: userDetails.designation,
        fromDate: userDetails.fromDate,
        toDate: userDetails.toDate,
        companyAddress: userDetails.companyAddress,
      },
    ],
  });

  const handelChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setUpdateEmployeeDetails((pv) => ({ ...pv, [name]: value }));
  };

  const [saveData, setSaveData] = useState({
    modules: [],
  });
  const [modulesError, setModulesError] = useState(false);
  const handleBranchChange = (e) => {
    setSaveData({
      ...saveData,
      modules: e,
    });
    setModulesError(false);
  };

  const [validated, setValidated] = useState(false);

  const handelSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      if (
        saveData.modules.length > 0 ||
        (userDetails.branch != "" &&
          userDetails.branch != null &&
          userDetails.branch != undefined)
      ) {
      } else {
        setModulesError(true);
      }
    } else {
      const data = {
        path: "admin/updateEmployementDetails",
        data: {
          userId: user.user_id,
          branch: saveData.modules.length > 0
            ? saveData.modules.map((v) => v.value).toString()
            : userDetails.branch,
          staffId: updateEmployeeDetails.staffId,
          department: updateEmployeeDetails.department,
          employee_type: updateEmployeeDetails.employee_type,
          date_of_joining: updateEmployeeDetails.date_of_joining,
          date_of_leaving: "13/10/2024",
          employ: updateEmployeeDetails.employ,
          job_title: updateEmployeeDetails.job_title,
          official_email: updateEmployeeDetails.official_email,
          esi_number: updateEmployeeDetails.esi_number,
          pf_number: updateEmployeeDetails.pf_number,
        },
      };

      dispatch(commonSaveData(data));
    }
  };

  useEffect(() => {
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      dispatch(initState());
    }
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      dispatch(initState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const GovernmentIdsInputsOnes = [
    {
      name: "branch",
      label: "Branches",
      placeholder: "Enter Branches",
      isMulti: true,
      chi: branchList,
    },
    {
      name: "department",
      label: "Departments",
      placeholder: "Enter Departments",
      chi: departmentList,
    },
    {
      name: "employee_type",
      label: "Employee Type",
      placeholder: "Enter Employee Type",
      chi: empTypeList,
    },
    { name: "date_of_joining", label: "Date of joining", type: "date" },
    {
      name: "employee_id",
      label: "Employee Id",
      placeholder: "Enter Employee Id",
    },

    { name: "job_title", label: "Job Title", placeholder: "Enter Job Title" },
    {
      name: "official_email",
      label: "Office Email Id",
      placeholder: "Enter Office Email Id",
    },
  ];

  const preSubmit = () => {
    document.getElementById("submit-form-1").click();
  };

  const savedBranch = userDetails.branch.split(",");

  const brnIndex =
    savedBranch !== undefined
      ? savedBranch.map((v) => {
          return branchList.findIndex((obj) => {
            return parseInt(obj.value) === parseInt(v);
          });
        })
      : [];

  return (
    <div className="BasicDetails">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div
        className="BasicDetails_heading"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid lightgray",
        }}
      >
        <p>Current Employment</p>
        <ButtonCom
          type="button"
          class_name="p_btn"
          text={"Update Details"}
          url_or_onclick={preSubmit}
          width={""}
        />
      </div>
      <div className="BasicDetails-form">
        <Row>
          <Col sm="6">
            <Form noValidate validated={validated} onSubmit={handelSubmit}>
              {GovernmentIdsInputsOnes.map((item, i) => (
                <Form.Group style={{ width: "100%" }} key={i} className="mt-3">
                  <Form.Label>
                    {item.label}
                    <span style={{ color: "#ec7063" }}>
                      {item.required ? "*" : ""}
                    </span>
                  </Form.Label>
                  {item.isMulti && item.chi ? (
                    <>
                      <br />
                      {branchList.length && (
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          defaultValue={brnIndex?.map((r) => branchList[r])}
                          isMulti
                          placeholder="Select branch"
                          options={branchList}
                          onChange={handleBranchChange}
                          required
                          style={{
                            border: modulesError ? "1px solid #c0392b" : null,
                          }}
                        />
                      )}
                      {modulesError && (
                        <label style={{ color: "#c0392b", fontSize: "14px" }}>
                          {" "}
                          Please provide valid {item.label}{" "}
                        </label>
                      )}
                    </>
                  ) : !item.isMulti && item.chi ? (
                    <Form.Select
                      name={item.name}
                      onChange={handelChange}
                      required
                    >
                      <option value="">Select option</option>
                      {item.chi.length > 0
                        ? item.chi.map((v, i) => {
                            return (
                              <option
                                value={v.value}
                                selected={
                                  updateEmployeeDetails[item.name] == v.value
                                    ? "selected"
                                    : null
                                }
                              >
                                {v.label}
                              </option>
                            );
                          })
                        : null}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name={item.name}
                      type={item.type}
                      value={updateEmployeeDetails[item.name]}
                      placeholder={item.placeholder}
                      onChange={handelChange}
                      required
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please provide valid {item.label}
                  </Form.Control.Feedback>
                </Form.Group>
              ))}
              <button
                type="submit"
                id="submit-form-1"
                style={{ display: "none" }}
              ></button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const PastEmployment = ({ user, userDetails, commonState }) => {
  const dispatch = useDispatch();
  const designationState = useSelector((state) => state.designation);
  const [designationList, setDesignationList] = useState([]);

  const getEmpList = useCallback(() => {
    dispatch(fetchDesignationList(user.user_id));
  });

  useEffect(() => {
    getEmpList();
  }, []);

  useEffect(() => {
    if (designationState.desigSuccess) {
      const newArr = designationState.desigData?.map((v) => {
        return { label: v.designation_name, value: v.designationId };
      });
      setDesignationList(newArr);
    }
  }, [designationState.desigSuccess]);

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const [pastEmployment, setPastEmployment] = useState({
    userId: user.user_id,
    staffId: userDetails.staffId,
    company_name: "",
    designation: "",
    joining_date: "",
    leaving_date: "",
    currency: "INR",
    salary: "",
    company_gst: "",
  });

  const handleClose = () => {
    setValidated(false);
    setOpen(false);
  };

  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPastEmployment({
      ...pastEmployment,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let d = {
        path: "admin/updatePastEmployementDetails",
        data: pastEmployment,
      };
      dispatch(commonSaveData(d));
    }
  };

  useEffect(() => {
    // toast.success(commonState.commonMessage);
    dispatch(initState());
    handleClose();
  }, [commonState.commonSuccess]);

  const employmentDetails = [
    {
      employementNumber: userDetails.employmentDetails.employementNumber,
      companyName: userDetails.employmentDetails.companyName,
      designation: userDetails.employmentDetails.designation,
      fromDate: userDetails.employmentDetails.fromDate,
      toDate: userDetails.employmentDetails.toDate,
      companyAddress: userDetails.employmentDetails.companyAddress,
    },
  ];

  const columns = [
    {
      headerName: "Employement Number",
      field: "employementNumber",
    },
    {
      headerName: "Company Name",
      field: "companyName",
    },
    {
      headerName: "Designation",
      field: "designation",
    },
    {
      headerName: "From Date",
      field: "fromDate",
    },
    {
      headerName: "To Date",
      field: "toDate",
    },
    {
      headerName: "Company Address",
      field: "companyAddress",
    },
  ];
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <div className="BasicDetails">
      <div
        className="BasicDetails_heading"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid lightgray",
        }}
      >
        <p>Past Employment</p>
        <ButtonCom
          type="button"
          class_name="p_btn"
          text="Add"
          url_or_onclick={handleClickOpen("paper")}
          width={""}
        />
      </div>
      {/* <div className="PastEmployment-body">
         <p>No Past Employment date</p>
       </div> */}
      <div className="page-body">
        <div
          className="ag-theme-quartz" // applying the Data Grid theme
          style={{ height: "200px", width: "100%" }}
        >
          <AgGridReact
            rowData={employmentDetails.length > 0 ? employmentDetails : []}
            columnDefs={columns}
            omLayout="autoHeight"
            pagination={true}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
          />
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle
            id="scroll-dialog-title"
            style={{
              borderBottom: "1px solid lightgray",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Past Employment
          </DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              style={{ position: "relative" }}
            >
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>
                    Company Name <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="company_name"
                    value={pastEmployment.company_name}
                    placeholder="Enter company name"
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid company name.
                  </Form.Control.Feedback>
                </Form.Group>
                {/* ============================= */}
                <Form.Group className="mt-3">
                  <Form.Label>
                    Designation <span className="error">*</span>
                  </Form.Label>
                  <Form.Select
                    required
                    type="text"
                    name="designation"
                    value={pastEmployment.designation}
                    placeholder="Enter designation"
                    style={{ color: "gray" }}
                    onChange={handleChange}
                  >
                    <option value="">Select Designation</option>
                    {designationList?.map((row, idx) => {
                      return (
                        <option value={row.value} key={idx}>
                          {row.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid designation.
                  </Form.Control.Feedback>
                </Form.Group>
                {/* ============================= */}
                <Form.Group className="mt-3">
                  <Form.Label>
                    Joining Date <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="date"
                    name="joining_date"
                    value={pastEmployment.joining_date}
                    placeholder="Enter joining date"
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid joining date.
                  </Form.Control.Feedback>
                </Form.Group>
                {/* ============================= */}
                <Form.Group className="mt-3">
                  <Form.Label>
                    Leaving Date <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="date"
                    name="leaving_date"
                    value={pastEmployment.leaving_date}
                    placeholder="Enter leaving date"
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid leaving date.
                  </Form.Control.Feedback>
                </Form.Group>

                {/* ============================= */}
                <Row className="mt-3">
                  <Col sm="4">
                    <Form.Group>
                      <Form.Label>
                        Currency <span className="error">*</span>
                      </Form.Label>
                      <Form.Select
                        required
                        name="currency"
                        value={pastEmployment.currency}
                        onChange={handleChange}
                      >
                        <option value="">Select Currency</option>
                        <option value="inr" selected>
                          INR
                        </option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select a valid currency type.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="8">
                    <Form.Group>
                      <Form.Label>
                        Salary <span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="salary"
                        value={pastEmployment.salary}
                        placeholder="Enter salary"
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid salary.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {/* ============================= */}
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    Company GST <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="company_gst"
                    value={pastEmployment.company_gst}
                    placeholder="Enter company GST"
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid GST.
                  </Form.Control.Feedback>
                </Form.Group>

                <div
                  style={{
                    position: "sticky",
                    bottom: "0px",
                    backgroundColor: "#fff",
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "end",
                    borderTop: "2px solid lightgray",
                    borderRadius: "0px 0px  7px 7px",
                    padding: "15px 0px 0px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="gray"
                    onClick={handleClose}
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      height: "38px",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      height: "38px",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default EmploymentDetails;
