import "./Admins.css";
import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableMui from "../../../components/table/TableMui";
import AddIcon from "@mui/icons-material/Add";
import { Form, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ButtonCom from "../../../components/button/button";

import { useDispatch, useSelector } from "react-redux";

import {
  commonGetData,
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import { customStyles } from "../../../services/commonServices";

const Admins = () => {
  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);

  const [open, setOpen] = useState(false);

  const [validated, setValidated] = useState(false);

  const [adminList, setAdminList] = useState([]);

  const [admin, setAdmin] = useState({
    userId: user?.user_id,
    name: "",
    mobile: "",
    email: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAdmin("");
    setValidated(false);
    setOpen(false);
  };

  const getAdminList = useCallback(() => {
    dispatch(commonGetData(`admin/getSubAdminList?userId=${user.user_id}`));
  }, []);

  useEffect(() => {
    getAdminList();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      setAdminList(commonState.commonGetData);
    }
    if (commonState.commonGetError) {
    }
  }, [commonState.commonGetSuccess, commonState.commonGetError]);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setAdmin({
      ...admin,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let d = {
        path: "admin/createSubAdmin",
        data: admin,
      };
      dispatch(commonSaveData(d));
    }
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      handleClose();
      setAdmin({
        ...admin,
        name: "",
        mobile: "",
        email: "",
      });
      dispatch(initCommonState());
      getAdminList();
    }
    if (commonState.commonError) {
      handleClose();
      setAdmin({
        ...admin,
        name: "",
        mobile: "",
        email: "",
      });
      dispatch(initCommonState());
      getAdminList();
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  // Edit functions

  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const [parentUserId, setParentUserId] = useState("");

  const handleEdit = (row) => {
    setIsEdit(true);
    setParentUserId(parseInt(row.parentUserId));
    setAdmin({
      ...admin,
      name: row.name,
      mobile: row.mobile,
      email: row.email,
    });
    handleClickOpen();
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateSubAdmin",
      data: {
        userId: user?.user_id,
        parentUserId,
        name: admin.name,
        mobile: admin.mobile,
        email: admin.email,
      },
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const handleDelete = (row) => {
    setParentUserId(parseInt(row.parentUserId));
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteSubAdmin",
      data: {
        userId: user?.user_id,
        parentUserId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      getAdminList();
      setIsEdit(false);
      setParentUserId("")
      dispatch(initCommonState());
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      setOpen(false);
      getAdminList();
      setParentUserId("")
      dispatch(initCommonState());
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => {
        return row.status == 1 ? "Active" : "InActive";
      },
    },
    {
      name: "Actions",
      cell: (row) => {
        return row.parentUserId == null ? null : (
          <div>
            <CiEdit
              style={{
                cursor: "pointer",
                fontSize: 30,
                color: "blue",
                marginRight: "10px",
              }}
              onClick={() => {
                handleEdit(row);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(row)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px", // Set a width for the action buttons
    },
  ];
  
  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />

      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company SubAdmin</p>
      </div>

      <div className="SettingBody-card mt-4">
        <div className="Branches-body">
          <div className="Branches-table">
            <div className="Departments-btn mb-3">
              <h1>SubAdmins </h1>
              <ButtonCom
                type={"button"}
                class_name={"p_btn"}
                text={"Add SubAdmin"}
                url_or_onclick={handleClickOpen}
                icon={<AddIcon />}
                width={""}
              />
            </div>
            <DataTable
              columns={columns}
              data={adminList}
              pagination
              subHeader
              customStyles={customStyles}
            />
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle style={{ fontSize: "18px", fontWeight: "bold" }}>
              Add New Sub Admin
            </DialogTitle>
            <Form
              noValidate
              validated={validated}
              onSubmit={isEdit ? handleEditSubmit : handleSubmit}
            >
              <DialogContent>
                <Row>
                  <Col sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>Admin Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={admin.name}
                        placeholder="Enter Admin Name"
                        onChange={handelChange}
                        required
                      />
                      <Form.Control.Feedback type={"invalid"}>
                        Please provide valid admin name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={admin.email}
                        placeholder="Enter Email"
                        onChange={handelChange}
                        required
                      />
                      <Form.Control.Feedback type={"invalid"}>
                        Please provide valid email id.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="12">
                    <Form.Group className="mb-2">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="mobile"
                        value={admin.mobile}
                        placeholder="Enter Mobile Number"
                        onChange={handelChange}
                        required
                      />
                      <Form.Control.Feedback type={"invalid"}>
                        Please provide valid mobile number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="gray"
                  onClick={handleClose}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "30px",
                    backgroundColor: "gray",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  type="submit"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "30px",
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          </Dialog>

          <div className="Branches-btn"></div>
        </div>
      </div>
    </div>
  );
};

export default Admins;
