import "./LadingPageCardFive.css";
import womanBusiness from "../../../assets/Images/147846-professional-woman-business-png-file-hd.png";

const LadingPageCardFive = () => {
  return (
    <div className="LadingPageCardFive">
      <div className="LadingPageCardFive-card-top">
        <h1>Experience our HR Tech Platform Designed by HR for HR</h1>
        <p>
          Tell us who you are and we’ll suggest exactly what your business needs
          to get ahead.
        </p>
        <div className="LadingPageCardFive-input-tag">
          <input placeholder="Employee Size" />
          <input placeholder="Designation" />
          <input placeholder="state your goal" />
          <button>Let's Go</button>
        </div>
      </div>
      <div className="LadingPageCardFive-card-bottom">
        <div className="bottom-left">
          <img src={womanBusiness} />
        </div>
        <div className="bottom-Right">
          <h1>
            Take control of all your HR operations and experience the
            difference!
          </h1>
          <div className="bottom-Right-button">
            <button>schedule a Demo</button>
            <button>schedule a Demo</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LadingPageCardFive;
