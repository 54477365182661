import "./Register.css";
import { useEffect, useState, forwardRef } from "react";
import bannerImg from "../../../assets/Images/login.png";

// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { emailValidator, formValidation } from "../../../hooks/formValidation";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import MuiAlert from "@mui/material/Alert";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/slices/login";

import Spinner from "react-bootstrap/Spinner";
import { checkLength } from "../../../services/commonServices";

const Register = () => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.auth);
  const [register, setRegister] = useState(false);
  const [icon, setIcon] = useState(true);
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [errorsMsg, setErrorsMsg] = useState({});

  const handelChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((pr) => ({ ...pr, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { valueTrim, errorItem, value } = formValidation(userInfo);
    if (!value) {
      return setErrors(errorItem);
    } else if (value) {
      setErrors({});
    }
    if (!emailValidator(valueTrim.email)) {
      return setErrorsMsg({ email: "Not valid email Id" });
    }
    if (isNaN(valueTrim.phoneNumber)) {
      return setErrorsMsg({ phoneNumber: "This not a Number " });
    }
    setErrorsMsg({});
    setUserInfo({
      username: "",
      email: "",
      phoneNumber: "",
      password: "",
    });
  };

  return (
    <div className="Register">
      <div className="register-container">
        <div className="register_left">
          <img src={bannerImg} />
        </div>
        <div className="register_right">
          {register ? (
            <form onSubmit={handleSubmit}>
              <p id="heading">Forgot password</p>

              <div
                className="register-input-dev"
                style={{
                  border:
                    errors.email || errorsMsg.email ? "1px solid red" : "",
                }}
              >
                <input
                  name="email"
                  value={userInfo.email}
                  placeholder="Email"
                  onChange={handelChange}
                />

                <label id="erMsg">{errorsMsg.email}</label>
              </div>

              <div className="register-input-dev">
                <button type="submit">Register</button>
              </div>

              <label id="Register-label">
                already have account ?{" "}
                <samp onClick={() => setRegister(false)}>Login</samp>
              </label>
            </form>
          ) : (
            <Login setRegister={setRegister} />
          )}
        </div>
      </div>
    </div>
  );
};

const Login = ({ setRegister }) => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    Transition: Slide,
  });
  const { vertical, horizontal, open, Transition } = state;

  const handleClick = (newState) => {
    setState({ ...newState, open: true, Transition });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [validated, setValidated] = useState(false);

  const [loginInfo, setLoginInfo] = useState({
    user_mobile: "",
    pwd: "",
  });

  const loginHandelChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo((pr) => ({ ...pr, [name]: value }));
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(loginUser(loginInfo));
    }
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    if (loginState.authSuccess) {
      localStorage.setItem("user_data", JSON.stringify(loginState.authData));
      if (loginState.authData.user_id == 1) {
        localStorage.setItem("auth", true);
        localStorage.setItem("superadmin", true);
        return window.location.reload();
      } else {
        localStorage.setItem("auth", true);
        return window.location.reload();
      }
      // if (loginState.authData.user_id == 2) {
      //   localStorage.setItem("auth", true);
      //   return window.location.reload()
      // }
      setSeverity("success");
      setMessage(loginState.authMessage);
      handleClick({ vertical, horizontal });
    }
    if (loginState.authError) {
      setSeverity("error");
      setMessage(loginState.authMessage);
      handleClick({ vertical, horizontal });
    }
  }, [loginState.authSuccess, loginState.authError]);

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <center
          style={{ marginTop: "-50px", fontSize: "18px", fontWeight: "600" }}
          className="mb-4"
        >
          <span id="heading">Login</span>
        </center>

        <Form.Group className="mb-3">
          <Form.Label>Enter Email Id</Form.Label>
          <Form.Control
            name="user_mobile"
            type="email"
            value={loginInfo.user_mobile}
            placeholder="Enter Email"
            onChange={loginHandelChange}
            defaultValue={"new-password"}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email id.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId={`validationCustom1`}>
          <Form.Label sm="4">Enter Password</Form.Label>
          <Form.Control
            name="pwd"
            type="password"
            value={loginInfo.pwd}
            placeholder="**********"
            onChange={loginHandelChange}
            defaultValue={"new-password"}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid password.
          </Form.Control.Feedback>
        </Form.Group>

        <div className="register-input-dev">
          <button type="submit">
            Login &nbsp;
            {loginState.authLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>

        <label id="Register-label">
          <samp onClick={() => setRegister(true)}>Forgot password</samp>
        </label>
      </Form>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={Transition.name + vertical + horizontal}
        autoHideDuration={3000}
        color="success"
        variant="outlined"
        TransitionComponent={state.Transition}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Register;
